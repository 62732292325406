import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  SearchIcon,
  EditIcon,
  UserAddIcon,
  EyeIcon,
  DelIcon,
} from "../../assets";
import Modal from "../../components/Modal";
import ClaimDelete from "../../components/AdminSide/ClaimDelete";

import Loader from "../../components/Loader";
import {
  useUpdateClaimsStatusMutation,
  useFetchClaimFormDetailQuery,
} from "../../store/services/claimsService";
import { thousandAndDecimalFormat } from "../../utils/common";

const ClaimsDetails = () => {
  // const [open, setOpen] = useState(false);
  const ClaimsDetails = [
    {
      date: "01.01.2021",
      desc: "Medical treatment",
      amount: "500",
      proof: "medicalreciept.pdf",
    },
    {
      date: "01.01.2021",
      desc: "TA- DA for last month",
      amount: "520",
      proof: "TA-DA.jpeg",
    },
    {
      date: "01.01.2021",
      desc: "Overtime for last month",
      amount: "600",
      proof: "Overtime02.pdf",
    },
    {
      date: "01.01.2021",
      desc: "Medical claims",
      amount: "760",
      proof: "medical02-01-2020.pdf",
    },
  ];
  const navigate = useNavigate();
  const { uId } = useParams();
  const [formDataSet, setForm] = useState({
    status: {
      bySupervisor: "pending",
      byHr: "pending"
    },
    comments: "",
    details: []
  });
  const [sumValue, setSumVal] = useState(0);
  const isDisabled = formDataSet?.status?.byHr !== "pending";

  const { data: formDetailsData, isFetching: formDetailFetching } =
    useFetchClaimFormDetailQuery(uId);
  
  useEffect(() => {
    if (!formDetailFetching) {
      setForm(formDetailsData?.data);
      let sum = 0;
      formDetailsData?.data.details.forEach((element) => {
        sum += (isNaN(element.amount)) ? 0 : Number(element.amount);
      });
      setSumVal(sum)
    }
  }, [formDetailsData?.data]);

  const handleInput = (e) => {
    setForm((prevState) => ({
      ...prevState,
      comments: e.target.value,
    }))
  };

  const [updateStatus, response] = useUpdateClaimsStatusMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;

  const [errorMessage, setErrorMessage] = useState("");
  const updateApprovedStatus = (e) => {
    e.preventDefault();
    if (formDataSet.comments === "") {
      setErrorMessage("Please add comments");
    } else {
      updateStatus({ updatedData: {
        status: {
          ...formDataSet.status,
          byHr: "approved",
        },
        comments: formDataSet.comments
      }, uId });
    }
  };
  const updateRejectedStatus = (e) => {
    e.preventDefault();
    if (formDataSet.comments === "") {
      setErrorMessage("Please add comments");
    } else {
      updateStatus({ updatedData: {
        status: {
          ...formDataSet.status,
          byHr: "rejected",
        },
        comments: formDataSet.comments
      }, uId });
    }
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/claims-list");
    }
  }, [response?.isSuccess]);

  
  return (
    (!formDetailFetching ? 
      <div className="users-page claims-details-page flex">
        <div className="wrap wrapWidth flex flex-col">
          {errorMessage !== "" && (
            <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
              {errorMessage}
            </div>
          )}
          {errors !== null && (
            <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
              {errors}
            </div>
          )}
          <div className="page-hdr flex justify-between">
            <div className="page-tag s18 b6">Claims Details</div>
            <div className="flex aic justify-end">
              <div className="search-box flex aic">
                <input
                  type="text"
                  className="txt cleanbtn cursor-pointer"
                  placeholder="Search here"
                />
                <div className="icon flex aic jc">
                  <SearchIcon />
                </div>
              </div>
              <Link
                to="/new-user"
                className="add-user flex aic jc ml-6 cursor-pointer"
              >
                <UserAddIcon />
              </Link>
            </div>
          </div>
            <div className="table-blk flex">
              <div className="tbl flex flex-col">
                <div className="row flex aic">
                  <div className="col flex">
                    <div className="col-item">Receipt Date</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Description</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Amount</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Proof</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item"></div>
                  </div>
                </div>
                {formDataSet.details?.length ? (
                  formDataSet.details?.map((item, index) => (
                    <div className="row flex aic">
                      <div className="col flex">
                        {`${moment(item.receiptDate).format("D")}-${moment(
                          item.receiptDate
                        ).format("MM")}-${moment(item.receiptDate).format(
                          "YYYY"
                        )}`}
                      </div>
                      <div className="col flex">
                        <div className="col-item">{item.description}</div>
                      </div>
                      <div className="col flex">
                        <div className="col-item">{thousandAndDecimalFormat(item.amount, true, 2)} </div>
                      </div>
                      <div className="col flex">
                        <div className="col-item">
                        <a href={item.proof} target="_blank">
                        <EyeIcon />
                        </a>
                        </div>
                      </div>
                      <div className="col flex">
                        <div className="col-item flex aic jc">
                          {/* <Link to="/view-claim" className="icon cursor-pointer">
                            <EyeIcon />
                          </Link> */}
                          {/* <Link
                            to={`/edit-claim`}
                            className="icon cursor-pointer"
                          >
                            <EditIcon />
                          </Link> */}
                          {/* <div
                            className="icon cursor-pointer"
                            onClick={(e) => setOpen(true)}
                          >
                            <DelIcon />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex items-center justify-center h-52 text-base font-medium">
                    Data not found
                  </div>
                )}
                <div className="total-amount flex aic pt-6 pb-4">
                  <div className="col flex">
                    <div className="col-item">
                      Total Amount:{" "}
                      <span className="b6 s16 ml-2">{sumValue ? thousandAndDecimalFormat(sumValue, true, 2) : thousandAndDecimalFormat(0, true, 2)} SGD</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div className="comments-box flex flex-col">
            <div className="lbl">Comments</div>
            <textarea
              name="comments"
              value={formDataSet.comments}
              disabled={isDisabled}
              onChange={handleInput}
              className="txt cleanbtn h-[120px]"
              placeholder="Comments.."
            />
          </div>
          {
            !isDisabled  && (
              <div className="actions flex aic justify-end">
                <div className="btn button" onClick={(e) => updateRejectedStatus(e)}>
                  Reject
                </div>
                <div className="btn button" onClick={(e) => updateApprovedStatus(e)}>
                  Approve
                </div>
              </div>
            )
          }
        </div>
        {/* <Modal open={open} onClose={() => setOpen(false)}>
          <ClaimDelete setOpen={setOpen} />
        </Modal> */}
      </div>
      :
      <Loader />
      )
  );
};

export default ClaimsDetails;
