/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation, Redirect, Navigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-datepicker/dist/react-datepicker.css";

import "react-datetime/css/react-datetime.css";
import "./css/App.scss";
import "./css/UserApp.scss";
import "./css/icomoon/style.css";

//Global Pages
import Private from "./Private";
import JumpAuthorize from "./Pages/JumpAuthorize";
import Login from "./Pages/Login";
import Registration from "./Pages/Registration";
import SelectOrg from "./Pages/SelectOrg";

//Admin side Components
import Header from "./components/AdminSide/Header";
import NavBar from "./components/AdminSide/NavBar";
import Footer from "./components/Footer";
import Sidebar from "./components/AdminSide/Sidebar/Sidebar";
import UpdateDepartment from "./components/AdminSide/UpdateDepartment";
import CreateNewDepartment from "./components/AdminSide/CreateNewDepartment";
import CreateNewBankAc from "./components/AdminSide/CreateNewBankAc";
import UpdateBankAc from "./components/AdminSide/UpdateBankAc";
import UpdateUser from "./components/AdminSide/UpdateUser";
import CreateNewCpfAc from "./components/AdminSide/CreateNewCpfAc";
import UpdateCpfAc from "./components/AdminSide/UpdateCpfAc";
import CreateNewComponent from "./components/AdminSide/CreateNewComponent";
import UpdateComponent from "./components/AdminSide/UpdateComponent";
import ViewClaimsDetails from "./components/AdminSide/ViewClaimsDetails";
import EditClaim from "./components/AdminSide/EditClaim";
import Payslip from "./components/AdminSide/Payslip";
import AccountInfo from "./components/AdminSide/AccountInfo";
import UpdatePassword from "./components/AdminSide/UpdatePassword";

//Admin side Pages
import Main from "./Pages/AdminSide/Main";
import CompanyInfo from "./Pages/AdminSide/CompanyInfo";
import UpdateCompanyInfo from "./components/AdminSide/UpdateCompanyInfo";
import Departments from "./Pages/AdminSide/Departments";
import BankAccount from "./Pages/AdminSide/BankAccount";
import CpfAccount from "./Pages/AdminSide/CpfAccount";
import Components from "./Pages/AdminSide/Components";
import Users from "./Pages/AdminSide/Users";
import CreateNewUser from "./Pages/AdminSide/CreateNewUser";
import ClaimsList from "./Pages/AdminSide/ClaimsList";
import HistoryClaimList from "./Pages/AdminSide/HistoryClaimList";
import ClaimsDetails from "./Pages/AdminSide/ClaimsDetails";
import CPFReport from "./Pages/AdminSide/CPFReport";
import Payroll from "./Pages/AdminSide/Payroll";
import NewPayroll from "./Pages/AdminSide/NewPayroll";
import ActivityLog from "./Pages/AdminSide/activityLog";


//User side Components
import UserHeader from "./components/UserSide/Header";
import UserNavBar from "./components/UserSide/NavBar";

// User Side pages
import UserDashboard from "./Pages/UserSide/UserDashboard";
import UserPayrollHistory from "./Pages/UserSide/UserPayrollHistory";
import UserCpfReport from "./Pages/UserSide/UserCpfReport";
import UserInformationForm from "./Pages/UserSide/UserInformationForm";
import UserClaimsList from "./Pages/UserSide/UserClaimsList";
import NewClaimForm from "./Pages/UserSide/NewClaimForm";
import UpdateClaimForm from "./Pages/UserSide/UpdateClaimForm";
import ViewClaimForm from "./Pages/UserSide/ViewClaimForm";
import AddNewClaim from "./Pages/UserSide/AddNewClaim";
import AddNewClaimForUpdate from "./Pages/UserSide/AddNewClaimForUpdate";
import EditUserClaim from "./Pages/UserSide/EditUserClaim";
import UpdateUserClaim from "./Pages/UserSide/UpdateUserClaim";
import UserClaimsApprovalList from "./Pages/UserSide/UserClaimsApprovalList";
import ClaimDetails from "./Pages/UserSide/ClaimDetails";
import ClaimDetailsHistoryView from "./Pages/UserSide/ClaimDetailsHistoryView";
import ActivityLogUser from "./Pages/UserSide/activityLogUser";


import { useFetchUserInfoQuery } from "./store/services/generalService";

function App() {
  const { userRole, userToken, userOrgId, userId } = useSelector(
    (state) => state.authReducer
  );
  const [user, setUser] = useState("user");
  const { data, isFetching } = useFetchUserInfoQuery(userId);

  return (
    <div className="App flex">
      <BrowserRouter>
        {userRole === "hrAdmin" ? (
          <div className="admin-sides flex app-pages">
            <div className="sidebar-blk flex">
              <NavBar setUser={setUser} />
            </div>
            <div className="main-pages ml-[255px] flex h-full flex-col flex-1">
              <Header userData={data} />
              <Routes>
                <Route path="/middlepage" element={<Main />} exact />
                <Route path="/" element={<Main />} exact />
                {/* <Route path="/payslip" element={<Payslip />} exact /> */}

                <Route path="/accountInfo" element={<AccountInfo />} exact />
                <Route path="/general" element={<CompanyInfo />} exact />
                <Route
                  path="/update-general"
                  element={<UpdateCompanyInfo />}
                  exact
                />

                <Route path="/department" element={<Departments />} exact />
                <Route
                  path="/update-password"
                  element={<UpdatePassword />}
                  exact
                />

                <Route
                  path="/department-details/:uId"
                  element={<UpdateDepartment />}
                  exact
                />

                <Route
                  path="/new-department"
                  element={<CreateNewDepartment />}
                  exact
                />
                <Route path="/bank-account" element={<BankAccount />} exact />
                <Route
                  path="/new-bank-account"
                  element={<CreateNewBankAc />}
                  exact
                />
                <Route
                  path="/update-bank-account/:uId"
                  element={<UpdateBankAc />}
                  exact
                />
                <Route path="/cpf-account" element={<CpfAccount />} exact />
                <Route
                  path="/new-cpf-account"
                  element={<CreateNewCpfAc />}
                  exact
                />
                <Route
                  path="/update-cpf-account/:uId"
                  element={<UpdateCpfAc />}
                  exact
                />
                <Route path="/component" element={<Components />} exact />
                <Route
                  path="/new-component"
                  element={<CreateNewComponent />}
                  exact
                />
                <Route
                  path="/update-component/:uId"
                  element={<UpdateComponent />}
                  exact
                />
                <Route path="/users" element={<Users />} exact />
                <Route path="/new-user" element={<CreateNewUser />} exact />
                <Route
                  path="/update-user/:uId/:page"
                  element={<UpdateUser />}
                  exact
                />
                <Route path="/claims-list" element={<ClaimsList />} exact />
                <Route path="/history-claims-list" element={<HistoryClaimList />} exact />
                <Route
                  path="/claims-details/:uId"
                  element={<ClaimsDetails />}
                  exact
                />
                {/* <Route path="/claims-list" element={<ClaimsList />} exact />
                <Route
                  path="/claims-details"
                  // path="/claims-details/:uId"
                  element={<ClaimsDetails />}
                  exact
                /> */}
                <Route path="/cpf-report" element={<CPFReport />} exact />
                <Route
                  path="/view-claim"
                  element={<ViewClaimsDetails />}
                  exact
                />
                <Route path="/edit-claim" element={<EditClaim />} exact />
                <Route path="/payroll" element={<Payroll />} exact />
                <Route path="/new-payroll" element={<NewPayroll />} exact />
                <Route path="/activity-log" element={<ActivityLog />} exact />
                <Route path="/*" element={<Navigate to="/" />}/>
              </Routes>
            </div>
          </div>
        ) : userRole === "user" ? (
          <div className="admin-sides flex app-pages">
            <div className="sidebar-blk flex">
              <UserNavBar setUser={setUser} />
            </div>
            <div className="main-pages ml-[255px] flex h-full flex-col flex-1">
              <UserHeader userData={data} />
              <Routes>
                <Route path="/middlepage" element={<UserDashboard />} exact />
                <Route path="/" element={<UserDashboard />} exact />
                <Route
                  path="/userPayrollHistory"
                  element={<UserPayrollHistory />}
                  exact
                />
                <Route
                  path="/update-password"
                  element={<UpdatePassword />}
                  exact
                />
                <Route path="/new-payroll" element={<NewPayroll />} exact />
                <Route
                  path="/cpf-report-user"
                  element={<UserCpfReport />}
                  exact
                />
                <Route
                  path="/user-information/:page"
                  element={<UpdateUser />}
                  exact
                />
                <Route
                  path="/user-claims-list"
                  element={<UserClaimsList />}
                  exact
                />
                <Route
                  path="/new-claims-form"
                  element={<NewClaimForm />}
                  exact
                />
                <Route
                  path="/update-claims-form/:uId"
                  element={<UpdateClaimForm />}
                  exact
                />
                <Route
                  path="/claims-detail/:uId"
                  element={<ViewClaimForm />}
                  exact
                />
                <Route path="/add-new-claim" element={<AddNewClaim />} exact />
                <Route
                  path="/addNew-claim-forUpdate"
                  element={<AddNewClaimForUpdate />}
                  exact
                />

                <Route
                  path="/edit-user-claim/:uId"
                  element={<EditUserClaim />}
                  exact
                />
                <Route
                  path="/update-user-claim/:uId"
                  element={<UpdateUserClaim />}
                  exact
                />
                <Route
                  path="/user-claim-approval"
                  element={<UserClaimsApprovalList />}
                  exact
                />
                <Route
                  path="/user-claim-detail/:uId"
                  element={<ClaimDetails />}
                  exact
                />
                <Route
                  path="/user-claim-historyDetail/:uId"
                  element={<ClaimDetailsHistoryView />}
                  exact
                />
                <Route path="/user-activity-log" element={<ActivityLogUser />} exact />
                <Route path="/*" element={<Navigate to="/" />}/>
              </Routes>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <Routes>
              <Route path="/middlepage" element={<JumpAuthorize />} exact />
              <Route path="/" element={<Login />} exact />
              <Route path="/login" element={<Login />} exact />
              <Route path="/selectOrg" element={<SelectOrg />} exact />
              <Route path="/register" element={<Registration />} exact />
              <Route path="/*" element={<Login />} exact />
            </Routes>
          </div>
        )}
        
      </BrowserRouter>
    </div>
  );
}

export default App;
