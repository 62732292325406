import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const cpfAccountService = createApi({
  reducerPath: "cpfAccount",
  tagTypes: "cpfAc",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New CPF Account
      createCpfAc: builder.mutation({
        query: (saveCfpAccountData) => {
          return {
            url: "/api/corporate/cpf_account",
            method: "POST",
            body: saveCfpAccountData,
          };
        },
        invalidatesTags: ["cpfAc"],
      }),

      //   Delete a CPF Account
      deleteCpfAc: builder.mutation({
        query: (uId) => {
          return { url: `/api/corporate/cpf_account/${uId}`, method: "DELETE" };
        },
        invalidatesTags: ["cpfAc"],
      }),

      //   Update the CPF Account
      updateCpfAc: builder.mutation({
        query: (data) => {
          return {
            url: `/api/corporate/cpf_account/${data.uId}`,
            method: "PUT",
            body: data.createCpfAccount,
          };
        },
        invalidatesTags: ["cpfAc"],
      }),

      //   Get the List of CPF Accounts
      get: builder.query({
        query: () => {
          return {
            url: "/api/corporate/cpf_account",
            method: "GET",
          };
        },
        providesTags: ["cpfAc"],
      }),

      //   Get the Single CPF Account
      fetchCpfAc: builder.query({
        query: (uId) => {
          return {
            url: `/api/corporate/cpf_account/${uId}`,
            method: "GET",
          };
        },
        providesTags: ["cpfAc"],
      }),
    };
  },
});

export const {
  useGetQuery,
  useCreateCpfAcMutation,
  useDeleteCpfAcMutation,
  useFetchCpfAcQuery,
  useUpdateCpfAcMutation,
} = cpfAccountService;

export default cpfAccountService;
