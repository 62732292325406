import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Datetime from "react-datetime";
import { DropDownIcon, CalendarIcon, AttachmentIcon, EyeIcon } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import {
  useFetchClaimDetailDataQuery,
  useUpdateClaimsDetailMutation,
} from "../../store/services/claimsService";

const UpdateUserClaim = () => {
  const { claimFormId } = useSelector((state) => state.globalReducer);
  const [date, setDate] = useState(new Date().getTime());
  const location = useLocation();
  const navigate = useNavigate();
  const { uId } = useParams();
  const { data, isFetching, isLoading } = useFetchClaimDetailDataQuery(uId);


  const [file, setFile] = useState();

  const [createClaimForm, setCreateClaimForm] = useState({
    receiptDate: "",
    amount: 0,
    description: "",
    proof: "",
  });
  const handleInput = (e) => {
    setCreateClaimForm({ ...createClaimForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setCreateClaimForm({ ...createClaimForm, proof: file });
  }, [file]);

  useEffect(() => {
    if (!isFetching) {
      setCreateClaimForm(data?.data);
    }
  }, [data?.data]);

  const [updateClaimsDetail, response] = useUpdateClaimsDetailMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;

  const claimDetailUpdate = (e) => {
    e.preventDefault();
    updateClaimsDetail({ createClaimForm, uId });
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate(`/update-claims-form/${location?.state?._id}`);
    }
  }, [response?.isSuccess]);

  return (
    <div className="add-new-claim flex">
      <form onSubmit={claimDetailUpdate} className="add-new-claim flex">
        <div className="wrap wrapWidth flex flex-col">
          {errors !== null && (
            <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
              {errors}
            </div>
          )}
          <div className="row">
            <div className="field flex flex-col">
              <div className="lbl">Reciept Date</div>
              <div className="date-picker flex aic jc">
                <Datetime
                  closeOnSelect={true}
                  value={
                    createClaimForm.receiptDate
                      ? new Date(createClaimForm.receiptDate).getTime()
                      : new Date().getTime()
                  }
                  onChange={(value) => {
                    setDate(new Date(value).getTime());
                    setCreateClaimForm({
                      ...createClaimForm,
                      receiptDate: new Date(value).getTime(),
                    });
                  }}
                  timeFormat={false}
                  dateFormat="DD-MM-YYYY"
                  className="start-date cleanbtn"
                />
                <div className="ico">
                  <CalendarIcon />
                </div>
              </div>
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Amount</div>
              <input
                type="number"
                name="amount"
                value={createClaimForm.amount}
                onChange={handleInput}
                className="txt"
                placeholder="Amount"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Proof</div>
              <div className="txt flex aic justify-between">
                <input
                  type="file"
                  name="proof"
                  onChange={(e) => {
                    let file = e.target.files[0];
                    setFile(window.URL.createObjectURL(file));
                  }}
                  className="cleanbtn w-full cursor-pointer"
                  placeholder="Proof"
                />
                <div className="ico">
                  <AttachmentIcon />
                </div>

                {(!file && createClaimForm.proof && createClaimForm.proof !== "undefined") && (
                  <a href={(createClaimForm.proof)} target="_blank">
                    <EyeIcon />
                  </a>
                )}
              </div>
              {/* {!file && <div>{createClaimForm.proof}</div>} */}
            </div>
          </div>
          <div className="field flex flex-col pt-6">
            <div className="lbl">Description</div>
            <textarea
              type="text"
              name="description"
              value={createClaimForm.description}
              onChange={handleInput}
              className="txt h-[140px]"
              placeholder="Description"
            />
          </div>
          <div className="action flex aic justify-end">
            <Link
              to={`/update-user-claim/${claimFormId}`}
              className="btn button mr-8 invisible"
            >
              Cancel
            </Link>
            <input
              type="submit"
              value={response.isLoading ? "Loading..." : "Update"}
              className="btn button"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateUserClaim;
