import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  SearchIcon,
  EditIcon,
  UserAddIcon,
  DropDownIcon,
  DropUpIcon,
  EyeIcon,
} from "../../assets";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import {
  useUpdateClaimsStatusMutation,
  useFetchClaimFormDetailQuery,
} from "../../store/services/claimsService";
import { thousandAndDecimalFormat } from "../../utils/common";

const ClaimDetails = () => {
  const navigate = useNavigate();
  const { uId } = useParams();

  const { data: formDetailsData, isFetching: formDetailFetching } =
    useFetchClaimFormDetailQuery(uId);
  const sum = formDetailsData?.data?.details
    ?.map((item) => item.amount)
    .reduce((prev, curr) => prev + curr, 0);

  const [updateStatusApproved, setUpdateStatusApproved] = useState({
    status: {
      bySupervisor: "approved",
      byHr: formDetailsData?.data?.status?.byHr ? formDetailsData?.data?.status?.byHr : "pending",
    },
    comments: "",
  });
  const [updateStatusRejected, setUpdateStatusRejected] = useState({
    status: {
      bySupervisor: "rejected",
      byHr: formDetailsData?.data?.status?.byHr ? formDetailsData?.data?.status?.byHr : "pending",
    },
    comments: "",
  });

  useEffect(() => {
    setUpdateStatusApproved({
      status: {
        bySupervisor: "approved",
        byHr: formDetailsData?.data?.status?.byHr ? formDetailsData?.data?.status?.byHr : "pending",
      },
      comments: "",
    })

    setUpdateStatusRejected({
      status: {
        bySupervisor: "rejected",
        byHr: formDetailsData?.data?.status?.byHr ? formDetailsData?.data?.status?.byHr : "pending",
      },
      comments: "",
    })
  }, [formDetailsData])

  const handleInput = (e) => {
    setUpdateStatusApproved({
      ...updateStatusApproved,
      [e.target.name]: e.target.value,
    });
    setUpdateStatusRejected({
      ...updateStatusRejected,
      [e.target.name]: e.target.value,
    });
  };

  const [updateStatus, response] = useUpdateClaimsStatusMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;

  const [errorMessage, setErrorMessage] = useState("");
  const updateApprovedStatus = (e) => {
    e.preventDefault();
    if (updateStatusApproved.comments === "") {
      setErrorMessage("Please add comments");
    } else {
      updateStatus({ updatedData: updateStatusApproved, uId });
    }
  };
  const updateRejectedStatus = (e) => {
    e.preventDefault();
    if (updateStatusRejected.comments === "") {
      setErrorMessage("Please add comments");
    } else {
      updateStatus({ updatedData: updateStatusRejected, uId });
    }
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/user-claim-approval");
    }
  }, [response?.isSuccess]);

  return (
    <div className="claims-details-page flex">
      <div className="wrap wrapWidth flex flex-col">
        {errorMessage !== "" && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errorMessage}
          </div>
        )}
        {errors !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        <div className="claims-details-table flex flex-col">
          <div className="page-hdr flex justify-between">
            <div className="page-tag s18 b6">Claims Details</div>
            <div className="search-box flex aic">
              <input
                type="text"
                className="txt cleanbtn cursor-pointer"
                placeholder="Search here"
              />
              <div className="icon flex aic jc">
                <SearchIcon />
              </div>
            </div>
          </div>
          {!formDetailFetching ? (
            <>
              <div className="table-blk flex">
                <div className="tbl flex flex-col">
                  <div className="row flex aic">
                    <div className="col flex">
                      <div className="col-item">Receipt Date</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">Description</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">Amount</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">Proof</div>
                    </div>
                  </div>
                  {formDetailsData?.data?.details?.length ? (
                    formDetailsData?.data?.details?.map((item, index) => (
                      <div className="row flex aic">
                        <div className="col flex">
                          <div className="col-item">
                            {`${moment(item.receiptDate).format("D")}-${moment(
                              item.receiptDate
                            ).format("MM")}-${moment(item.receiptDate).format(
                              "YYYY"
                            )}`}
                          </div>
                        </div>
                        <div className="col flex">
                          <div className="col-item">{item.description}</div>
                        </div>
                        <div className="col flex">
                          <div className="col-item">{thousandAndDecimalFormat(item.amount, true, 2)}</div>
                        </div>
                        <div className="col flex">
                          <div className="col-item underline cursor-pointer">
                            {item.proof ? <a href={(item.proof)} target="_blank">
                              <EyeIcon />
                            </a> : "Null"}
                            
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center justify-center h-52 text-base font-medium">
                      Data not found
                    </div>
                  )}
                </div>
              </div>
              <div className="page-footer flex justify-between mt-10 mb-5">
                <div className="amount flex aic ">
                  <div className="lbl mr-2">Total Amount:</div>
                  <div className="val b7 s20">{sum ? thousandAndDecimalFormat(sum, true, 2) : thousandAndDecimalFormat(0, true, 2)} SGD</div>
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
        <div className="comment-sec flex flex-col">
          <div className="lbl">Comments</div>
          <textarea
            className="txt"
            name="comments"
            onChange={handleInput}
            placeholder="Comments..."
          />
          <div className="actions flex justify-end items-end">
            <div
              className="btn button"
              onClick={(e) => {
                navigate("/user-claim-approval");
              }}
            >
              Cancel
            </div>
            <div
              className="btn button mx-3"
              onClick={(e) => updateRejectedStatus(e)}
            >
              Reject
            </div>
            <div className="btn button" onClick={(e) => updateApprovedStatus(e)}>
              Approve
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimDetails;
