import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { SearchIcon, EditIcon, UserAddIcon } from "../../assets";
import Modal from "../../components/Modal";
import DataTable from 'react-data-table-component';

import Loader from "../../components/Loader";
import { useGetHistoryClaimListAdminQuery } from "../../store/services/claimsService";
import { thousandAndDecimalFormat } from "../../utils/common";

const HistoryClaimsList = () => {
  const [allData , setData] = useState([]);
  const [filteredData , setFilteredData] = useState([]);
  const { userRole, userToken, userOrgId, userId, userInfo } = useSelector(
    (state) => state.authReducer
  );
  // let loggedInfo1 = JSON?.parse(userInfo);
  let loggedInfo = userInfo;

  const { data, isLoading, isFetching } = useGetHistoryClaimListAdminQuery(
    loggedInfo?._id
  );
  
  const getSumTotal = (data) => {
    let result = 0;
    data.map((element) => {
      result += element?.amount ? element.amount : 0; 
    })
    return result
  }
  const searchFieldIs = [
    "firstName",
    "uId",
    "user_id",
    "requestDate",
    "status",
    "details"
  ];
  const searchText = (target, value) => {
    return target.toLowerCase().indexOf(value) > -1 ? true : false;
  }
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    if (event.target.value !== "") {
      let result = [];
      result = allData.filter((data) => {
        let flag = false;
        for (let i = 0; i < searchFieldIs.length; i++) {
          const targetField = searchFieldIs[i];
          if (data[`${targetField}`]) {
            if (targetField === "user_id") {
              // search first name
              flag = searchText(data[`${targetField}`].firstName, value);
              // search supervisors first name and last name
              for (let j = 0; j < data[`${targetField}`].reportTo.length; j++) {
                const element = data[`${targetField}`].reportTo[j];
                if (searchText(element.firstName, value) || searchText(element.lastName, value)) {
                  flag = true;
                }
              }
              if (flag) break;
            }else if (targetField === "status") {
              const bySupervisor = searchText(data[`${targetField}`].bySupervisor, value);
              const byhr = searchText(data[`${targetField}`].byHr, value);
              if (bySupervisor || byhr) {
                flag = true;
                if (flag) break;
              }
            }else if (targetField === "requestDate") {
              const dateTarget = new Date(data[`${targetField}`]);
              const dateString = String(`${dateTarget.getDate()}-${dateTarget.getMonth()+1}-${dateTarget.getFullYear()}`);
              flag = searchText(dateString, value);
              if (flag) break;
            }else if (targetField === "details") {
              const targetDataValue = String(getSumTotal(data[`${targetField}`]));
              flag = searchText(targetDataValue, value);
              if (flag) break;
            } else {
              flag = searchText(data[`${targetField}`], value);
              if (flag) break;
            }
          }
        }
        if (flag) return data
      });
      setFilteredData(result);
    } else {
      setFilteredData(allData);
    }
  }
  useEffect(() => {
    if (!isFetching) {
      setData(data?.data);
      setFilteredData(data?.data);
    }
  }, [data?.data]);

  
  const renderColumns = () => {
    return [
        {
            name: "Employee Name",
            sortable: true,
            selector: row => row?.user_id?.firstName,
        },
        // {
        //     name: "Employee No.",
        //     sortable: true,
        //     selector: row => row?.user_id?.uId
        // },
        {
            name: "Supervisor",
            sortable: true,
            cell: row => (
              <div>
                {row?.user_id?.reportTo?.map((item, i) => (
                <div className="" key={i}>
                  {item.firstName} {item.lastName}
                </div>
                ))
                }
              </div>

          )
        },
        {
            name: "Request Date",
            sortable: true,
            cell: row =>  (
              <div>
              {`${moment(row.requestDate).format("D")}-${moment(
                row.requestDate
              ).format("MM")}-${moment(row.requestDate).format(
                "YYYY"
              )}`}
            </div>
            )
        },
        {
            name: "Total Amount",
            sortable: true,
            cell: row => (
              
              <div>{thousandAndDecimalFormat(getSumTotal(row.details), true, 2)}</div>
              )
        },
        {
            name: "Status By Supervisor",
            sortable: true,
            cell : row => (
              
              <div>
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'approved') && <span className="status-tag success">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'pending') && <span className="status-tag secondary">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'rejected') && <span className="status-tag danger">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'claimed') && <span className="status-tag primary">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
              
              </div>
              )
        },
        {
            name: "Status By Admin/HR",
            sortable: true,
            cell : row => (
              
              <div>
                {
                  (String(row.status.byHr).toLowerCase() === 'approved') && <span className="status-tag success">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'pending') && <span className="status-tag secondary">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'rejected') && <span className="status-tag danger">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'claimed') && <span className="status-tag primary">{String(row.status.byHr).toUpperCase()}</span>
                }
              
              </div>
              )
        },
        {
            name: 'Details',
            button: true,
            cell: row => (
                <>
                  <Link
                    to={`/claims-details/${row?.uId}`}
                    className="icon cursor-pointer hover:underline"
                  >
                    <div className="underline-offset-4 cursor-pointer">
                      View
                    </div>
                  </Link>
                    
                </>
            )
        }
    ];
};

  return (
    <div className="users-page claim-list felx">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-hdr flex justify-end">
          <div className="search-box flex aic">
            <input
              type="text"
              className="txt cleanbtn cursor-pointer"
              placeholder="Search here"
              onChange={(event) =>handleSearch(event)}
            />
            <div className="icon flex aic jc">
              <SearchIcon />
            </div>
          </div>
        </div>
        {!isLoading && !isFetching ? (
          <DataTable
              columns={renderColumns()}
              data={filteredData}
              responsive={true}
              pagination={true} />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default HistoryClaimsList;
