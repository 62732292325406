import React, { useState, useEffect } from "react";
import Select from "react-select";
import { CloseIcon, SuccessIcon } from "../../assets";
import Modal from "../Modal";
// import ExecutedSuccesfully from "./ExecutedSuccesfully";

import { useGetQuery } from "../../store/services/bankAccountService";
import { usePaidPayrollCopyMutation } from "../../store/services/payrollService";
import { useGetQuery as optiobQuery } from "../../store/services/generalService";

const PaymentInformation = ({ setOpen, setOpen3, usersIdsList, targetUidDetail, payInfoIsEdit, pyDetailData }) => {
  const { data: options, isLoading: loadingBank } = optiobQuery();
  const {
    data: bankListData,
    isLoading: bankListLoading,
    isFetching: bankListFetching,
  } = useGetQuery();

  const [selection, setSelection] = useState("");
  const [userBankList, setUserBankList] = useState();
  const [bankInfo, setBankInfo] = useState({
    paymentInfo: {
      paidBy: selection,
      bank_id: "",
    },
    detail_id: usersIdsList,
  });
  const [selectedBank, setSelectedBank] = useState();

  useEffect(() => {
    if (!bankListFetching && !loadingBank) {
      setUserBankList(bankListData?.data);
    }
  }, [bankListData?.data]);

  useEffect(() => {
    setBankInfo((prevState) => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        bank_id: selectedBank?._id ? selectedBank?._id : "",
      },
    }));
  }, [selectedBank]);

  useEffect(() => {
      if (pyDetailData?.paymentInfo) {
        if (usersIdsList.length === 1 && usersIdsList.filter(x => x === undefined).length === 1) {
          setSelection(pyDetailData?.paymentInfo?.paidBy)
          setBankInfo((prevState) => ({
            ...prevState,
            detail_id: [pyDetailData?.id],
            paymentInfo: {
              paidBy: pyDetailData?.paymentInfo?.paidBy,
              bank_id: pyDetailData?.paymentInfo?.bank_id?._id ? pyDetailData?.paymentInfo?.bank_id?._id : "",
            },
          }));
          setSelectedBank(pyDetailData?.paymentInfo?.bank_id)
        }
      }
  }, [usersIdsList, pyDetailData?.paymentInfo]);

  const [updatePaidPayroll, response] = usePaidPayrollCopyMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;
  const [errorMessage, setErrorMessage] = useState(null);


  const updateUsersPaidPayroll = (e) => {
    e.preventDefault();
    if (!bankInfo?.detail_id?.length) {
      setErrorMessage("Please select any one user");
    } else if (bankInfo?.paymentInfo?.paidBy === "bank" && (bankInfo?.paymentInfo?.bank_id === "" || bankInfo?.paymentInfo?.bank_id === undefined)) {
      setErrorMessage("Please select any bank account");
    } else {
      updatePaidPayroll({
        uId: targetUidDetail,
        bankInfo,
      });
    }
  };

  const getBankName = (targetData) => {
    let result = "";
    options?.data?.bankList.forEach((element) => {
      if (element.value === targetData.bankOf) {
        result = `${element.display} - ${targetData.accountName}`
      }
    });
    return result
  }
  
  const setPayInfoForm = (targetData, typeData) => {
    if (typeData === "bankInfo") {
      setSelectedBank(targetData);
      setBankInfo((prevState) => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          paidBy: selection,
          bank_id: selectedBank?._id
        },
      }));
    } else if (typeData === "paidByinfo") {
      setSelection(targetData);
      setBankInfo((prevState) => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          paidBy: targetData
        },
      }));
    }
  }
  useEffect(() => {
    if (response?.isSuccess) {
      setOpen3(true);
      setOpen(false);
    }
  }, [response?.isSuccess]);
  return (
    <div className="payment-information flex flex-col">
      <div className="payment-info-wrap flex flex-col">
        <div className="payment-info-hdr flex items-center justify-between">
          <div className="page-tag">Payment Information</div>
          <div
            className="cross-icon cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
      {errorMessage !== null && (
        <div className="error-message mx-4 flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
          {errorMessage}
        </div>
      )}
        <div className="meta flex flex-col">
          <div className="row">
            <div className="field flex flex-col">
              <div className="lbl">Paid by</div>
              <div className="options flex aic">
                <div className="option-item flex aic mr-10">
                {
                  payInfoIsEdit === true ?
                  <button
                    onClick={(e) => {
                      setPayInfoForm("cash", "paidByinfo");
                      // setSelection(true);
                      // setSelection1(false);
                    }}
                    className={`cleanbtn radio-btn mt-[16px] rel ${
                      selection === "cash" ? "on" : ""
                    }`}
                  />

                  : 
                  <button
                    style={{cursor: "default"}}
                    className={`cleanbtn radio-btn mt-[16px] rel ${
                      selection === "cash" ? "on" : ""
                    }`}
                  />
                }
                  <div className="lbl">Cash</div>
                </div>
                <div className="option-item flex aic">
                
                {
                  payInfoIsEdit === true ?
                  <button
                    onClick={(e) => {
                      setPayInfoForm("bank", "paidByinfo");
                      // setSelection1(true);
                      // setSelection(false);
                    }}
                    className={`cleanbtn radio-btn mt-[16px] rel ${
                      selection === "bank" ? "on" : ""
                    }`}
                  />
                  :
                  <button
                    style={{cursor: "default"}}
                    className={`cleanbtn radio-btn mt-[16px] rel ${
                      selection === "bank" ? "on" : ""
                    }`}
                  />
                }
                  <div className="lbl">Bank</div>
                </div>
              </div>
            </div>
            {selection === "bank" && (
              <>
                <div className="field flex flex-col">
                  <div className="lbl">Account Name</div>
                  <input
                    name="accountName "
                    type="text"
                    value={selectedBank?.accountName}
                    disabled
                    className="txt cleanbtn"
                    placeholder="Bank Name"
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="lbl">Bank Name - Account Name</div>
                  
                {
                  payInfoIsEdit === true ?
                  <Select
                    defaultValue={selectedBank}
                    value={selectedBank}
                    onChange={(userBankList) => setPayInfoForm(userBankList, "bankInfo")}
                    getOptionLabel={(userBankList) => getBankName(userBankList)}
                    getOptionValue={(userBankList) => userBankList?.bankOf}
                    options={userBankList}
                    className="select"
                    id="bankOf"
                    name="bankOf"
                    placeholder="Select Bank"
                  />
                  :
                  <input
                    name="accountName"
                    type="text"
                    value={getBankName(selectedBank)}
                    disabled
                    className="txt cleanbtn"
                    placeholder="Account Name."
                  />

                }
                </div>
                <div className="field flex flex-col">
                  <div className="lbl">Account No.</div>
                  <input
                    name="accountNo"
                    type="text"
                    value={selectedBank?.accountNo}
                    disabled
                    className="txt cleanbtn"
                    placeholder="Account No."
                  />
                </div>
              </>
            )}
          </div>
            {
              payInfoIsEdit === true && 
              <div className="action flex aic justify-end py-8">
                <div
                  className="btn button rounded-lg"
                  onClick={(e) => {
                    updateUsersPaidPayroll(e);
                    // setOpen3(true);
                    // setOpen(false);
                  }}
                >
                  Continue
                </div>
              </div>
            }

        </div>
      </div>
    </div>
  );
};

export default PaymentInformation;
