import React from "react";

function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="87"
      height="87"
      fill="none"
      viewBox="0 0 87 87"
    >
      <path
        fill="#4E7A7D"
        fillRule="evenodd"
        d="M43.814 78.327a34.4 34.4 0 100-68.799 34.4 34.4 0 000 68.8zm15.94-39.96a4.3 4.3 0 00-6.08-6.08l-14.16 14.16-5.56-5.56a4.3 4.3 0 00-6.08 6.08l8.6 8.6a4.3 4.3 0 006.08 0l17.2-17.2z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SuccessIcon;
