import React from "react";

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      fill="none"
      viewBox="0 0 33 33"
    >
      <g fill="#fff" opacity="0.6">
        <path d="M16.428 16.943a6.667 6.667 0 100-13.334 6.667 6.667 0 000 13.334zM16.429 20.273c-6.68 0-12.12 4.48-12.12 10a.66.66 0 00.666.667h22.907a.66.66 0 00.667-.667c0-5.52-5.44-10-12.12-10z"></path>
      </g>
    </svg>
  );
}

export default UserIcon;
