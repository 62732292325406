import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modal";
import { EditIcon, DelIcon } from "../../assets";
import DataTable from 'react-data-table-component';

import DepartmentDelete from "../../components/AdminSide/DepartmentDelete";
import Loader from "../../components/Loader";
import {
  useGetDepartListQuery,
  useDeleteDepartmentMutation,
} from "../../store/services/departmentsService";

const Departments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isLoading, isFetching } = useGetDepartListQuery();
  
  const [open, setOpen] = useState(false);
  const [deleteDepartId, setDeleteDepartId] = useState("");

  const renderColumns = () => {
    return [
        {
            name: "Name",
            sortable: true,
            selector: row => row?.name,
        },
        {
            name: "Status",
            sortable: true,
            cell : row => (
              
              <div>
                {
                  (String(row.status).toLowerCase() === 'active') && <span className="status-tag success">{String(row.status).toUpperCase()}</span>
                }
                {
                  (String(row.status).toLowerCase() === 'inactive') && <span className="status-tag secondary">{String(row.status).toUpperCase()}</span>
                }
              
              </div>
              )
        },
        {
            name: "Description",
            sortable: true,
            grow: 2,
            selector: row => row?.description,
        },
        {
            name: 'Actions',
            button: true,
            cell: row => (
                <>
                    
                    <Link
                        to={`/department-details/${row.uId}`}
                        className="icon cursor-pointer"
                      >
                        <EditIcon />
                      </Link>
                      <div
                        className="icon cursor-pointer"
                        onClick={(e) => {
                          setOpen(true);
                          setDeleteDepartId({ uId: row.uId, name: row.name });
                        }}
                      >
                        <DelIcon />
                      </div>
                </>
            )
        }
    ];
  };
  return (
    <div className="department-page flex">
      <div className="wrap wrapWidth flex flex-col">
        {!isLoading && !isFetching ? (
          <div className="table-blk flex">
            <div className="tbl flex flex-col">
              <div className="row flex aic">
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item">
                    <Link to="/new-department" className="btn button">
                      New
                    </Link>
                  </div>
                </div>
              </div>
                <DataTable
                    columns={renderColumns()}
                    data={data?.data}
                    responsive={true}
                    pagination={true} />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <DepartmentDelete setOpen={setOpen} data={deleteDepartId} />
      </Modal>
    </div>
  );
};

export default Departments;
