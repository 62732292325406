import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Datetime from "react-datetime";
import { DropDownIcon, CalendarIcon, AttachmentIcon } from "../../assets";

import Loader from "../../components/Loader";
import { useCreateNewClaimDetailMutation } from "../../store/services/claimsService";

const AddNewClaim = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [file, setFile] = useState();

  const [createClaimForm, setCreateClaimForm] = useState({
    receiptDate: new Date(),
    amount: 0,
    description: "",
  });

  const handleInput = (e) => {
    setCreateClaimForm({ ...createClaimForm, [e.target.name]: e.target.value });
  };

  const [saveNewClaimForm, data] = useCreateNewClaimDetailMutation();
  const errors = data?.error?.data ? data?.error?.data.message : null;
  const createNewClaim = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("receiptDate", createClaimForm.receiptDate);
    formData.append("amount", createClaimForm.amount);
    formData.append("description", createClaimForm.description);
    if (file) {
      formData.append("proof", file);
    }
    saveNewClaimForm(formData);
  };

  const getLocalItems = () => {
    let list = localStorage.getItem("claimDetailIds");
    if (list) {
      return JSON.parse(localStorage.getItem("claimDetailIds"));
    } else {
      return [];
    }
  };

  const [myIdsList, setMyIdsList] = useState(getLocalItems());

  useEffect(() => {
    localStorage.setItem("claimDetailIds", JSON.stringify(myIdsList));
  }, [myIdsList]);

  useEffect(() => {
    if (data?.isSuccess) {
      setLoading(true);
      setMyIdsList([...myIdsList, data?.data?.data?._id]);
      setTimeout(() => {
        navigate("/new-claims-form");
        setLoading(false);
      }, 1500);
    }
  }, [data?.isSuccess]);

  return (
    <form onSubmit={createNewClaim} className="add-new-claim flex">
      <div className="wrap wrapWidth flex flex-col">
        {errors !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        {!loading || !data?.isLoading ? (
          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Reciept Date</div>
                <div className="date-picker flex aic jc">
                  <Datetime
                    closeOnSelect={true}
                    value={date ? date : new Date()}
                    onChange={(value) => {
                      setDate(new Date(value));
                      setCreateClaimForm({
                        ...createClaimForm,
                        receiptDate: new Date(value),
                      });
                    }}
                    timeFormat={false}
                    dateFormat="DD-MM-YYYY"
                    className="start-date cleanbtn"
                  />
                  {/* <Datetime
                closeOnSelect={true}
                value={date ? date : new Date()}
                onChange={(value) => {
                  setDate(new Date(value));
                }}
                timeFormat={false}
                dateFormat="DD-MM-YYYY"
                className="start-date cleanbtn"
              /> */}
                  <div className="ico">
                    <CalendarIcon />
                  </div>
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Amount</div>
                <input
                  type="number"
                  name="amount"
                  onChange={handleInput}
                  className="txt"
                  placeholder="Amount"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Proof</div>
                <div className="txt flex aic justify-between">
                  <input
                    type="file"
                    name="proof"
                    onChange={(e) => {
                      // setCreateClaimForm({
                      //   ...createClaimForm,
                      //   proof: e.target.files[0],
                      // });
                      let file = e.target.files[0];
                      // //setFile(e.target.files[0]);
                      setFile(file);
                    }}
                    className="cleanbtn w-full cursor-pointer"
                    placeholder="Proof"
                  />
                  <div className="ico">
                    <AttachmentIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="field flex flex-col pt-6">
              <div className="lbl">Description</div>
              <textarea
                type="text"
                name="description"
                onChange={handleInput}
                className="txt h-[140px]"
                placeholder="Description"
              />
            </div>
            <div className="action flex aic justify-end">
              <Link to="/new-claims-form" className="btn button mr-8">
                Cancel
              </Link>
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Create"}
                className="btn button"
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </form>
  );
};

export default AddNewClaim;
