import React from "react";

function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#fff"
        d="M19.59 4.606a1.75 1.75 0 00-2.474 0l-12.25 12.25a1.75 1.75 0 002.475 2.475l.513-.513v11.525a1.75 1.75 0 001.75 1.75h3.5a1.75 1.75 0 001.75-1.75v-3.5a1.75 1.75 0 011.75-1.75h3.5a1.75 1.75 0 011.75 1.75v3.5a1.75 1.75 0 001.75 1.75h3.5a1.75 1.75 0 001.75-1.75V18.818l.512.513a1.75 1.75 0 002.475-2.475L19.59 4.606z"
      ></path>
    </svg>
  );
}

export default HomeIcon;
