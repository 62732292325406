import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  SearchIcon,
  DelIcon,
  EditIcon,
  UserAddIcon,
  DownloadIcon,
  CalendarIcon,
  AddIcon,
  DropDownIcon,
  DropUpIcon,
} from "../../assets";
import PayrollDetail from "../../components/AdminSide/PayrollDetail";
import EditPayrollProcess from "../../components/AdminSide/EditPayrollProcess";
import DeletePayrollHistory from "../../components/AdminSide/DeletePayrollHistory";
import Modal from "../../components/Modal";

import Loader from "../../components/Loader";
import {
  setPayrollUid,
  setPayrollLabel,
  setWorkPeriod,
} from "../../store/reducers/globalReducer";
import { useGetPayrollProcessListQuery } from "../../store/services/payrollService";
import DataTable from "react-data-table-component";

const Payroll = () => {
  const dispatch = useDispatch();
  const [displayData, setDisplayData] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment().startOf('year').format('MM/DD/YYYY hh:mm')));
  const [toDate, setToDate] = useState(new Date(moment().endOf('year').format('MM/DD/YYYY hh:mm')));
  const handleFromDate = (date) => {
    setFromDate(new Date(moment(date).startOf('day').format('MM/DD/YYYY hh:mm')));
  };

  const handleToDate = (date) => {
    setToDate(new Date(moment(date).endOf('day').format('MM/DD/YYYY hh:mm')));
  };
  const [selectedData, setSelectedDate] = useState("");
  const { data, isLoading, isFetching, refetch: refetchList } = useGetPayrollProcessListQuery({
    fromDate,
    toDate,
  });
  
  useEffect(() => {
    if (!isFetching && !isLoading) {
      setDisplayData(data?.data);
    }
  }, [data?.data]);
  
  const [userDetail, setUserDetail] = useState();
  const [openDetail, setOpenDetail] = useState(false);

  const ExpandedComponent = ({ data }) => {
    
    return (<PayrollDetail data={{
      _id: data?._id,
      uId: data?.uId,
    }} />)
    // return (<pre>{JSON.stringify({
    //   _id: data?._id,
    //   uId: data?.uId,
    // }, null, 2)}</pre>)
  }
  ;

  const renderColumns = () => {
    return [
        {
            name: "Payroll Label",
            sortable: true,
            cell: row => (
              <div>
                {row.label?.year}_{row.label?.month}_{row.label?.name}
              </div>
            ),
        },
        {
            name: "Pay Date",
            sortable: true,
            cell: row => (
              <div>
              {`${moment(row?.payDate).format("D")}-${moment(
                  row?.payDate
                ).format("MM")}-${moment(row?.payDate).format("YYYY")}`}
              </div>
            ),
        },
        {
            name: "Work Period (Start / End)",
            sortable: true,
            cell: row => (
              <div>
               {`${moment(row?.workPeriod?.from).format("D")}-${moment(
                row?.workPeriod?.from
              ).format("MM")}-${moment(row?.workPeriod?.from).format("YYYY")}`}
              /<br />
              {`${moment(row?.workPeriod?.to).format("D")}-${moment(
                row?.workPeriod?.to
              ).format("MM")}-${moment(row?.workPeriod?.to).format("YYYY")}`}
              </div>
            ),
        },
        {
            name: "Draft",
            sortable: true,
            cell: row => (
              <div>
               {row?.count?.draft}
              </div>
            ),
        },
        {
            name: "Executed",
            sortable: true,
            cell: row => (
              <div>
               {row?.count?.executed}
              </div>
            ),
        },
        {
            name: "Paid",
            sortable: true,
            cell: row => (
              <div>
               {row?.count?.paid}
              </div>
            ),
        },
        {
            name: 'Actions',
            button: true,
            grow: 3,
            cell: row => (
                  <>
                  {/* <div
                    className="flex aic jc cursor-pointer"
                    onClick={(e) => {
                      setOpenDetail(!openDetail);
                      setUserDetail({
                        _id: row?._id,
                        uId: row?.uId,
                      });
                      localStorage.setItem("payroll_uId", row?.uId);
                      dispatch(setPayrollUid(row?.uId));
                      dispatch(setPayrollLabel(row?.label));
                      dispatch(setWorkPeriod(row?.workPeriod));
                    }}
                  >
                    <div className="col-item flex aic jc mr-1 s14">ViewDetails</div>
                    <div className="icon flex aic jc">
                      {openDetail ? <DropUpIcon /> : <DropDownIcon />}
                    </div>
                  </div> */}
                  <div
                    className="icon cursor-pointer mx-5"
                    onClick={(e) => {
                      setOpenEdit(true);
                      localStorage.setItem("payroll_uId", row?.uId);
                      dispatch(setPayrollUid(row?.uId));
                      setSelectedDate(row);
                    }}
                  >
                    <EditIcon />
                  </div>
                  <div
                    className="icon cursor-pointer"
                    onClick={(e) => {
                      setOpenDel(true);
                      localStorage.setItem("payroll_uId", row?.uId);
                      dispatch(setPayrollUid(row?.uId));
                    }}
                  >
                    <DelIcon />
                  </div>
                </>
            )
        }
    ];
  };
  
  return (
    <div className="payroll-page felx">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-hdr flex pb-8">
          <div className="hdr-right flex flex-1 aic">
            <div className="page-tag mr-6">Payroll History</div>
            <div className="field flex aic mr-3">
              <div className="lbl">From</div>
              <div className="date-picker flex aic jc">
                <Datetime
                  closeOnSelect={true}
                  value={fromDate ? fromDate : new Date()}
                  onChange={(value) => {
                    handleFromDate(new Date(value));
                  }}
                  timeFormat={false}
                  dateFormat="DD-MM-YYYY"
                  className="start-date cleanbtn"
                />
                <div className="ico">
                  <CalendarIcon />
                </div>
              </div>
            </div>
            <div className="field flex aic ">
              <div className="lbl">To</div>
              <div className="date-picker flex aic jc ">
                <Datetime
                  closeOnSelect={true}
                  value={toDate ? toDate : new Date()}
                  onChange={(value) => {
                    handleToDate(new Date(value));
                  }}
                  timeFormat={false}
                  dateFormat="DD-MM-YYYY"
                  className="start-date cleanbtn"
                />
                <div className="ico">
                  <CalendarIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="hdr-righ flex aic justify-end">
            <Link to="/new-payroll" className="btn-export button flex aic jc">
              Create New Payroll <AddIcon />
            </Link>
          </div>
        </div>
        {!isLoading && !isFetching ? (
          <>
            <DataTable
              columns={renderColumns()}
              data={displayData}
              expandableRows={true}
              expandableRowsComponent={ExpandedComponent}
              expandOnRowClicked={openDetail}
              expandOnRowDoubleClicked={openDetail}
              expandableRowsHideExpander={openDetail}
              pagination
            />
          </>
              
            
        ) : (
          <Loader />
        )}
      </div>
      <Modal open={openDel} onClose={() => setOpenDel(false)}>
        <DeletePayrollHistory setOpenDel={setOpenDel} refetchList={refetchList} />
      </Modal>
      <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
        <EditPayrollProcess
          setOpenEdit={setOpenEdit}
          selectedData={selectedData}
        />
      </Modal>
    </div>
  );
};

export default Payroll;
