import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const bankAccountService = createApi({
  reducerPath: "bankAccount",
  tagTypes: "bank",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create new Company Account
      createNewBank: builder.mutation({
        query: (createBank) => {
          return {
            url: "/api/corporate/bank_account",
            method: "POST",
            body: createBank,
          };
        },
        invalidatesTags: ["bank"],
      }),

      //   Delete a Bank Account
      deleteBankAccount: builder.mutation({
        query: (uId) => {
          return {
            url: `/api/corporate/bank_account/${uId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["bank"],
      }),

      //   Update the Bank Account
      updateBankAccount: builder.mutation({
        query: (data) => {
          return {
            url: `/api/corporate/bank_account/${data.uId}`,
            method: "PUT",
            body: data.createBank,
          };
        },
        invalidatesTags: ["bank"],
      }),

      //   Get the List of Company Bank Accounts
      get: builder.query({
        query: () => {
          return {
            url: "/api/corporate/bank_account",
            method: "GET",
          };
        },
        providesTags: ["bank"],
      }),

      //   Get the Single Bank Account Data
      fetchBank: builder.query({
        query: (uId) => {
          return {
            url: `/api/corporate/bank_account/${uId}`,
            method: "GET",
          };
        },
        providesTags: ["bank"],
      }),
    };
  },
});

export const {
  useGetQuery,
  useCreateNewBankMutation,
  useDeleteBankAccountMutation,
  useFetchBankQuery,
  useUpdateBankAccountMutation,
} = bankAccountService;

export default bankAccountService;
