import { createSlice } from "@reduxjs/toolkit";

const payroll_uId = localStorage.getItem("payroll_uId");
const payroll_label = "";
const work_period = "";

const globalReducer = createSlice({
  name: "globalReducer",
  initialState: {
    success: "",
    payRollUid: payroll_uId ? payroll_uId : "",
    payrollLabel: payroll_label ? payroll_label : "",
    workPeriod: work_period ? work_period : "",
    claimFormId: "",
  },
  reducers: {
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    clearMessage: (state) => {
      state.success = "";
    },
    setPayrollUid: (state, action) => {
      state.payRollUid = action.payload;
    },
    setPayrollLabel: (state, action) => {
      state.payrollLabel = action.payload;
    },
    setWorkPeriod: (state, action) => {
      state.workPeriod = action.payload;
    },
    setClaimFormId: (state, action) => {
      state.claimFormId = action.payload;
    },
  },
});
export const {
  setSuccess,
  clearMessage,
  setPayrollUid,
  setPayrollLabel,
  setWorkPeriod,
  setClaimFormId,
} = globalReducer.actions;
export default globalReducer.reducer;
