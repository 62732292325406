import React, { useState, useEffect } from "react";
import SidebarItem from "./SidebarItem";
// import items from "./sidebar.json";
import "./index.css";
import {
  SettingIcon,
  DropDownIcon,
  DropUpIcon,
  UserIcon,
  PayrollIcon,
  ReportIcon,
  HomeIcon,
  CheckIcon,
} from "../../../assets";

export default function Sidebar() {
  const items = [
    {
      title: "Dashboard",
      icon: <HomeIcon />,
      childrens: [],
      slug: "/",
      subChid: false,
    },
    {
      title: "Setting",
      icon: <SettingIcon />,
      slug: "/general",
      subChid: true,
      childrens: [
        {
          title: "General",
          icon: "",
          slug: "/general",
        },
        {
          title: "Department",
          icon: "",
          slug: "/department",
        },
        {
          title: "Bank account",
          icon: "",
          slug: "/bank-account",
        },
        {
          title: "CPF account",
          icon: "",
          slug: "/cpf-account",
        },
        {
          title: "Component",
          icon: "",
          slug: "/component",
        },
      ],
    },
    {
      title: "Users",
      icon: <UserIcon />,
      subChid: false,
      childrens: [],
      slug: "/users",
    },
    {
      title: "Payroll",
      icon: <PayrollIcon />,
      childrens: [],
      slug: "/payroll",
      subChid: false,
    },
    {
      title: "Claims",
      icon: <CheckIcon />,
      slug: "/claims-list",
      subChid: true,
      childrens: [
        {
          title: "My Approvals",
          icon: "",
          slug: "/claims-list",
        },
        {
          title: "History Approvals",
          icon: "",
          slug: "/history-claims-list",
        },
      ],
    },
    {
      title: "Report",
      icon: <ReportIcon />,
      slug: "/cpf-report",
      subChid: true,
      childrens: [
        {
          title: "CPF Report",
          icon: "",
          slug: "/cpf-report",
        },
      ],
    },
    {
      title: "Activity Log",
      icon: <ReportIcon />,
      slug: "/activity-log",
      subChid: false,
      childrens: [],
    },
  ];
  return (
    <div className="sidebar">
      {items.map((item, index) => (
        <SidebarItem key={index} item={item} />
      ))}
    </div>
  );
}
