import React from "react";

function CalendarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        fill="#4E7A7D"
        d="M22.568 6.52H21.4V4.2c0-.309-.123-.604-.342-.821a1.17 1.17 0 00-1.65 0 1.157 1.157 0 00-.341.82v2.32H9.734V4.2c0-.308-.123-.603-.341-.82a1.17 1.17 0 00-1.65 0 1.157 1.157 0 00-.342.82v2.32H6.234A3.51 3.51 0 003.76 7.54a3.47 3.47 0 00-1.026 2.46v1.16h23.334V10a3.47 3.47 0 00-1.025-2.46 3.51 3.51 0 00-2.475-1.02zM2.734 22.761a3.47 3.47 0 001.026 2.46 3.51 3.51 0 002.474 1.02h16.334a3.51 3.51 0 002.475-1.02 3.47 3.47 0 001.025-2.46v-9.28H2.734v9.28z"
      ></path>
    </svg>
  );
}

export default CalendarIcon;
