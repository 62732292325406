import React from "react";

function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="11"
      fill="none"
      viewBox="0 0 19 11"
    >
      <path
        stroke="#0F3F62"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.375 1.25l8.278 8.278 8.278-8.278"
      ></path>
    </svg>
  );
}

export default ArrowDownIcon;
