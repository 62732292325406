import React from "react";

function DownloadIconPy(status) {
  const pathText = (`${status.status}` !== 'draft') ? "enabled" : "disabled";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        className={`${pathText}`}
        d="M12.826.828c2.93 0 4.9 2.045 4.9 5.077v7.02c0 3.04-1.97 5.085-4.9 5.085h-7.49c-2.929 0-4.89-2.044-4.89-5.086V5.905c0-3.032 1.961-5.077 4.89-5.077h7.49zM9.086 5.27a.64.64 0 00-.649.644v5.455L6.303 9.24a.665.665 0 00-.458-.19.682.682 0 00-.458.19.646.646 0 000 .91l3.24 3.24c.242.24.674.24.916 0l3.24-3.24a.646.646 0 000-.91.665.665 0 00-.924 0l-2.126 2.13V5.914a.645.645 0 00-.648-.644z"
      ></path>
    </svg>
  );
}

export default DownloadIconPy;
