import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateUserPasswordMutation } from "../../store/services/usersService";

const UpdatePassword = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({ currentPassword: "", newPassword: "" });

  const handleInputs = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const [updatePassword, response] = useUpdateUserPasswordMutation();
  
  const errors = response?.error?.data ? response.error.data.message : null;

  const adminChangePassword = (e) => {
    e.preventDefault();
    updatePassword(state);
  };

  useEffect(() => {
    if (response.isSuccess) {
      navigate("/");
    }
  }, [response.isSuccess]);

  return (
    <form
      onSubmit={adminChangePassword}
      className="login-p flex flex-col aic w-full"
    >
      <div className="wrapper wrapWidth flex flex-col items-center">
        <div className="form-block flex flex-col bg-white p-6 rounded-lg">
          <div className="title font s32 b5 c000">Update Password</div>
          {errors !== null && (
            <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-sm text-sm font-medium">
              {errors}
            </div>
          )}
          <div className="feild flex flex-col mb-2">
            <div className="lbl font s14 c68">Current Password</div>
            <input
              type="password"
              name="currentPassword"
              onChange={handleInputs}
              value={state.currentPassword}
              placeholder="Current Password"
              className="iput font s14 c000"
            />
          </div>
          <div className="feild flex flex-col mb-2">
            <div className="lbl font s14 c68">New Password</div>
            <input
              type="password"
              name="newPassword"
              onChange={handleInputs}
              value={state.newPassword}
              placeholder="New Password"
              className="iput font s14 c000"
            />
          </div>
          <div className="feild btn flex flex-col">
            <input
              type="submit"
              className="btn button"
              disabled={response.isLoading ? true : false}
              value={response.isLoading ? "Loading..." : "Update"}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdatePassword;
