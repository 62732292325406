import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

export default function SidebarItem({ item }) {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  if (item.childrens) {
    return (
      // <div className={open ? "sidebar-item open" : "sidebar-item my-item"}>
      <div className={`${open ? "sidebar-item open" : "sidebar-item my-item"}`}>
        <NavLink
          to={`${item.slug}`}
          className="sidebar-title"
          onClick={() => setOpen(!open)}
        >
          <span>
            <div className="icon">{item.icon}</div>
            {item.title}
          </span>
          {item?.subChid == true && (
            <i
              className="bi-chevron-down toggle-btn"
              onClick={() => setOpen(!open)}
            ></i>
          )}
        </NavLink>
        <div className="sidebar-content">
          {item.childrens.map((child, index) => (
            <SidebarItem key={index} item={child} />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <NavLink to={item.slug} className="sidebar-item plain">
        {item.icon && <i className={item.icon}></i>}
        {item.title}
      </NavLink>
    );
  }
}
