import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authService = createApi({
  reducerPath: "auth",
  tagTypes: "uData",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    // prepareHeaders: (headers, { getState }) => {
    //   const reducers = getState();
    //   const authorization = reducers.authReducer?.userToken;
    //   const orgid = reducers.authReducer?.userOrgId;
    //   headers.set("authorization", authorization ? authorization : "");
    //   headers.set("orgid", orgid ? orgid : "");
    //   return headers;
    // },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      // API For Login
      authLogin: builder.mutation({
        query: (LoginData) => {
          return { url: "/api/auth/login", method: "POST", body: LoginData };
        },
      }),

      verifyToken: builder.mutation({
        query: (verifyData) => {
          return {
            url: "/api/auth/verifyToken",
            method: "POST",
            body: verifyData,
          };
        },
      }),
    };
  },
});

export const { useAuthLoginMutation, useVerifyTokenMutation } = authService;

export default authService;
