import React from "react";

function DoubleArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="24"
      fill="none"
      viewBox="0 0 14 24"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5.393 20.044a.7.7 0 01-.99 0l-2.8-2.8a.7.7 0 010-.99l2.8-2.8a.7.7 0 01.99.99L3.788 16.05h8.11a.7.7 0 010 1.4h-8.11l1.605 1.605a.7.7 0 010 .99zM8.604 4.455a.7.7 0 01.99 0l2.8 2.8a.7.7 0 010 .99l-2.8 2.8a.7.7 0 01-.99-.99l1.605-1.605h-8.11a.7.7 0 010-1.4h8.11L8.604 5.445a.7.7 0 010-.99z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default DoubleArrowIcon;
