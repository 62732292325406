import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import TypeWriterEffect from "react-typewriter-effect";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Loader from "../../components/Loader";
import { DownloadIcon, CalendarIcon, SearchIcon, DownloadIconPy } from "../../assets";

import { useGetUserDashboardDataQuery } from "../../store/services/dashboardService";
import { useGetUserPayrollHistoryQuery } from "../../store/services/reportService";
import { useGetComponentListQuery } from "../../store/services/componentService";
import { useGetOptionsQuery } from "../../store/services/generalService";
import { exportOwnBulk, exportPayslip } from "../../store/services/export.service";
import { downloadFile, thousandAndDecimalFormat } from "../../utils/common";
import DataTable from "react-data-table-component";

const UserDashboard = () => {
  var sliderSetting = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    data: componentsList,
    isLoading: componentLoading,
    isFetching: componentFetching,
  } = useGetComponentListQuery();
  const { data: options, isLoading: optionsIsLoading } = useGetOptionsQuery();
  const {
    data: userDashboardData,
    isLoading: userDashboardDataLoading,
    isFetching: userDashboardDataFetching,
  } = useGetUserDashboardDataQuery();
  const [fromDate, setFromDate] = useState(new Date(moment().startOf('month').format('MM/DD/YYYY hh:mm')));
  const [toDate, setToDate] = useState(new Date(moment().endOf('month').format('MM/DD/YYYY hh:mm')));
  const {
    data: payrollHistoryList,
    isFetching: payrollListIsFetching,
    isLoading: payrollListIsLoading,
  } = useGetUserPayrollHistoryQuery({
    fromDate,
    toDate,
  });


  const handleFromDate = (date) => {
    setFromDate(new Date(moment(date).startOf('day').format('MM/DD/YYYY hh:mm')));
  };

  const handleToDate = (date) => {
    setToDate(new Date(moment(date).endOf('day').format('MM/DD/YYYY hh:mm')));
  };

  const SalaryTypeName = (value) => {
    var newArray = options?.data?.salaryType?.filter(function (el) {
      return el?.value == value;
    });
    return newArray ? newArray[0]?.display : "";
  };

  const ComponentTypeName = (value) => {
    var newArray = componentsList?.data?.filter(function (el) {
      return el.type == value;
    });
    return newArray ? newArray[0]?.type : "";
  };

  const numberList = [
    { numb: 1 },
    { numb: 2 },
    { numb: 3 },
    { numb: 4 },
    { numb: 5 },
  ];

  const handleExportBulkPayslip = () => {
    exportOwnBulk()
    .then((res) => {
      if (res) {
        const title = `My_Payslip`;
        downloadFile(title, "zip", res);
      }
    });
  }

  const handleExportPayslip = (uId, detail_id, title) => {
    exportPayslip({
      uId: uId,
      detail_id: detail_id
    })
    .then((res) => {
      if (res) {
        downloadFile(title, "pdf", res);
      }
    });
  }

  const renderColumns = () => {
    return [
        {
            name: "Pay Date",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div className="flex s10">
                {`${moment(row.payDate).format("D")}-${moment(
                  row.payDate
                ).format("MM")}-${moment(row.payDate).format(
                  "YYYY"
                )}`}
              </div>
            ),
        },
        {
            name: "Salary",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div className="flex s10">{thousandAndDecimalFormat(row?.salary, true, 2)}</div>
            )
        },
        {
            name: "Overtime",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div className="flex s10">{thousandAndDecimalFormat(row?.ovetime, true, 2)}</div>
            )
        },
        {
            name: "Claims",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div className="flex s10">{thousandAndDecimalFormat(row?.claim, true, 2)}</div>
            )
        },
        {
            name: "Additional Earning",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div className="flex s10">
                {thousandAndDecimalFormat(row.additionalEarning, true, 2)}
              </div>
            )
        },
        {
            name: "Deductions",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div>
                {row?.deductions.map((item, index) => (
                  <div key={index} className="col-item flex  flex-col">
                    <div className="flex s10">
                      {item.name}: {thousandAndDecimalFormat(item.value, true, 2)}
                    </div>
                  </div>
                ))}
              </div>
            )
        },
        {
            name: "Employee Contributions",
            sortable: true,
            width: "133px",
            cell: row => (
              <div>
                {row?.employerContribution.map(
                  (item, index) => (
                    <div key={index} className="col-item flex  flex-col">
                      <div className="flex s10">
                        {item.name}: {thousandAndDecimalFormat(item.value, true, 2)}
                      </div>
                    </div>
                  )
                )}
              </div>
            )
        },
        {
            name: "Net Pay",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div className="flex s10">{thousandAndDecimalFormat(row.netPay, true, 2)}</div>
            )
        },
        {
            name: 'Actions',
            button: true,
            cell: row => (
                  <>
                  <div className="icon cursor-pointer" onClick={() => {
                    handleExportPayslip(
                      row.uId,
                      row.detail_id,
                      `Payslip_${moment(row.payDate).format("DD-MM-YYYY")}`
                    )
                  }}>
                  <DownloadIconPy status={"enabled"}/>
                </div>
                </>
            )
        }
    ];
  };
  return (
    <div className="user-home-page flex flex-col">
      <div className="wrapWidth wrap flex flex-col">
        <div className="reports">
          <div className="item flex flex-col">
            <div className="blk flex flex-col justify-between">
              <div className="hdr flex aic">
                <div className="title">Salary Information</div>
              </div>
              <div className="report-meta">
                <div className="row">
                  <div className="lbl">Current Pay</div>
                  {!userDashboardDataFetching ? (
                    <div className="numb">
                      {userDashboardData?.data?.salaryInformation
                        ? thousandAndDecimalFormat(userDashboardData.data.salaryInformation.amount, true, 2)
                        : "-"}
                    </div>
                  ) : (
                    <TypeWriterEffect
                      textStyle={{
                        fontFamily: "inherit",
                        color: "black",
                        fontWeight: "400",
                      }}
                      startDelay={100}
                      cursorColor="black"
                      text="Loading..."
                      typeSpeed={150}
                      loop={true}
                    />
                  )}
                </div>
                <div className="row">
                  <div className="lbl">Type</div>
                  {!userDashboardDataFetching ? (
                    <div className="numb">
                      {userDashboardData?.data?.salaryInformation
                        ? SalaryTypeName(
                            userDashboardData?.data?.salaryInformation?.type
                          )
                        : "-"}
                    </div>
                  ) : (
                    <TypeWriterEffect
                      textStyle={{
                        fontFamily: "inherit",
                        color: "black",
                        fontWeight: "400",
                      }}
                      startDelay={100}
                      cursorColor="black"
                      text="Loading..."
                      typeSpeed={150}
                      loop={true}
                    />
                  )}
                </div>
                <div className="row">
                  <div className="lbl">Effective Date</div>
                  {!userDashboardDataFetching ? (
                    <div className="numb">
                      {userDashboardData?.data?.salaryInformation ? (
                        <div>
                          {`${moment(
                            userDashboardData?.data?.salaryInformation
                              ?.effectiveDate
                          ).format("D")}-${moment(
                            userDashboardData?.data?.salaryInformation
                              ?.effectiveDate
                          ).format("MM")}-${moment(
                            userDashboardData?.data?.salaryInformation
                              ?.effectiveDate
                          ).format("YYYY")}`}
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                  ) : (
                    <TypeWriterEffect
                      textStyle={{
                        fontFamily: "inherit",
                        color: "black",
                        fontWeight: "400",
                      }}
                      startDelay={100}
                      cursorColor="black"
                      text="Loading..."
                      typeSpeed={150}
                      loop={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="item flex flex-col">
            <div className="blk flex flex-col justify-between">
              <div className="hdr flex aic">
                <div className="title">Bonus Information</div>
              </div>
              <div className="slider-block flex aic overflow-hidden">
                <Slider {...sliderSetting}>
                  {userDashboardData?.data?.bonusInformation?.map(
                    (item, index) => (
                      <div key={index}>
                        <div className="report-meta">
                          <div className="row">
                            <div className="lbl">Amount</div>
                            <div className="numb">
                              {item.amount ? thousandAndDecimalFormat(item.amount, true, 2) : "-"}
                            </div>
                          </div>
                          <div className="row">
                            <div className="lbl">Type</div>
                            <div className="numb">
                              {item.type ? ComponentTypeName(item.type) : "-"}
                            </div>
                          </div>
                          <div className="row">
                            <div className="lbl">Name</div>
                            <div className="numb">
                              {item.name ? item.name : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div className="history-modification-list flex flex-col">
          <div className="page-hdr flex">
            <div className="hdr-right flex flex-1 aic">
              <div className="tbl-tag s18 b6">Payroll History List</div>
            </div>
            <div className="hdr-righ flex aic justify-end">
              <div className="flex aic">
                <div className="field flex aic mr-3">
                  <div className="lbl">From</div>
                  <div className="date-picker flex aic jc">
                    <Datetime
                      closeOnSelect={true}
                      value={fromDate ? fromDate : new Date()}
                      onChange={(value) => {
                        // setFromDate(
                        //   new Date(value).toLocaleString("DD/MM/YYYY")
                        // );
                        handleFromDate(new Date(value));
                      }}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      className="start-date cleanbtn"
                    />
                    <div className="ico">
                      <CalendarIcon />
                    </div>
                  </div>
                </div>
                <div className="field flex aic ">
                  <div className="lbl">To</div>
                  <div className="date-picker flex aic jc ">
                    <Datetime
                      closeOnSelect={true}
                      value={toDate ? toDate : new Date()}
                      onChange={(value) => {
                        handleToDate(new Date(value));
                      }}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      className="start-date cleanbtn"
                    />
                    <div className="ico">
                      <CalendarIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-export button flex aic jc" onClick={() => handleExportBulkPayslip()}>
                Generate Bulk Payslip user <DownloadIcon />
              </div>
            </div>
          </div>
          <div className="table-blk flex">
            <div className="tbl flex flex-col">
              {!payrollListIsLoading ? (
                <>
                  {payrollHistoryList?.data?.length > 0 ? (
                    <>
                    <DataTable
                      columns={renderColumns()}
                      data={payrollHistoryList?.data}
                      responsive={true}
                      pagination={true} />
                    </>
                  ) : (
                    <div className="h-80 flex aic jc">No Data found</div>
                  )}
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
