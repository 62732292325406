import React, { useState, useEffect, useInsertionEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import Datetime from "react-datetime";
import Loader from "../../components/Loader";
import TagsInput from "../../components/TagsInput";

import { CalendarIcon, AddIcon, EditIcon, DelIcon } from "../../assets";

import { useFetchUserInfoQuery } from "../../store/services/generalService";
import {
  useCreateUserMutation,
  useGetUsersListQuery,
} from "../../store/services/usersService";
import {
  useGetQuery,
  useGetCountryQuery,
  useGetStateQuery,
  useGetCityQuery,
} from "../../store/services/generalService";

import { useGetDepartListQuery } from "../../store/services/departmentsService";
import { useGetComponentListQuery } from "../../store/services/componentService";

const CreateNewUser = () => {
  const navigate = useNavigate();
  const { userRole, userToken, userOrgId, userId } = useSelector(
    (state) => state.authReducer
  );
  const { data: userInfo, isFetching } = useFetchUserInfoQuery(userId);
  

  const { data: usersList, isFetching: isFetchingUsersList } =
    useGetUsersListQuery();
  

  const [step, setStep] = useState(1);
  const nextStep = () => {
    setStep(step + 1);
    document.documentElement.scrollTop = 0;
  };
  const prevStep = () => {
    setStep(step - 1);
    document.documentElement.scrollTop = 0;
  };
  const [addBank, setAddBank] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    contactNo: "",
    nationalId: "",
    dateOfBirth: "",
    department_id: "",
    designation: "",
    reportTo: "",
    status: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
    address: [],
    roles: [],
    workdays: ["Monday", "Tuesday","Wednesday","Thursday","Friday"],
    salary: [
      {
        effectiveDate: "",
        amount: "",
        type: "",
      },
    ],
    component: [
      {
        effectiveDate: "",
        component_id: "",
      },
    ],
    imigrationStatus: "",
    identificationNumber: "",
    issueDate: "",
    expiryDate: "",
    skillCategory: "",
    nationality: "",
    race: "",
    religion: "",
    groupContribution: "",
    cpfAccountNo: "",
    applicable: {
      SDL: false,
      FWL: false,
    },
    bank: {
      bankOf: "",
      accountName: "",
      accountNo: "",
    },
  });
  const [dateBirth, setDateBirth] = useState(new Date().getTime());
  const [issueDate, setIssueDate] = useState(new Date().getTime());
  const [expDate, setExpDate] = useState(new Date().getTime());
  const [selectedCountry, setSelectedCountry] = useState({
    name: "",
    country_code: "",
  });
  const [selectedState, setSelectedState] = useState({
    name: "",
    country_code: "",
  });
  const [selectedCity, setSelectedCity] = useState({
    name: "",
    country_code: "",
  });
  const [selectedDepart, setSelectedDepart] = useState({
    name: "",
    _id: "",
  });
  const [selectedStatus, setSelectedStatus] = useState({
    display: "",
    value: "",
  });
  // const [selectedSector, setSelectedSector] = useState({
  //   display: "",
  //   value: "",
  // });
  const [selectedImmigration, setSelectedImmigration] = useState({
    display: "",
    value: "",
  });
  // const [selectedTier, setSelectedTier] = useState({
  //   display: "",
  //   value: "",
  // });
  const [selectedTierCategory, setSelectedTierCategory] = useState({
    display: "",
    value: "",
  });
  const [selectedRace, setSelectedRace] = useState({
    display: "",
    value: "",
  });
  const [selectedNationality, setSelectedNationality] = useState({
    name: "",
    country_code: "",
  });
  const [selectedReligion, setSelectedReligion] = useState({
    display: "",
    value: "",
  });
  const [selectedGroupContribution, setSelectedGroupContribution] = useState({
    display: "",
    value: "",
  });
  const [selectedBank, setSelectedBank] = useState({});
  const [reportTo, setReportTo] = useState();
  

  const {
    data: optionsList,
    isLoading: optionsListLoading,
    isFetching: optionsListFetching,
  } = useGetQuery();
  

  const {
    data: countries,
    isLoading: countriesLoading,
    isFetching: countriesFetching,
  } = useGetCountryQuery();
  

  const {
    data: states,
    isLoading: statesLoading,
    isFetching: statesFetching,
  } = useGetStateQuery(selectedCountry?.country_code);
  

  const {
    data: cites,
    isLoading: citesLoading,
    isFetching: citesFetching,
  } = useGetCityQuery({
    countryCode: selectedCountry?.country_code,
    stateCode: selectedState?.state_code,
  });
  

  const {
    data: departmentsList,
    isLoading: departmentLoading,
    isFetching: departmentFetching,
  } = useGetDepartListQuery();
  

  const [departList, setDepartList] = useState();
  useEffect(() => {
    setDepartList(
      departmentsList?.data.filter((el, i) => el.status == "active")
    );
  }, [departmentsList?.data]);

  const {
    data: componentList,
    isLoading: componentLoading,
    isFetching: componentFetching,
  } = useGetComponentListQuery();
  

  const handleInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleBankInput = (e) => {
    setUserData((prevState) => ({
      ...prevState,
      bank: {
        ...prevState.bank,
        [e.target.name]: e.target.value,
      },
    }));
    // setUserData({ ...userData, [e.target.name]: e.target.value });
  };


  //Handle Address
  const handleAddress = (index) => (e) => {
    let newArr = [...userData?.address];
    newArr[index] = e.target.value;
    setUserData({ ...userData, address: newArr });
  };

  // Handle remove Address
  let newAddressArr = [...userData?.address];
  const removeAddress = (index) => {
    newAddressArr = newAddressArr.filter((el, i) => i !== index);
    setUserData({ ...userData, address: newAddressArr });
  };

  // Handle Roles
  const [roleSuperAdmin, setRoleSuperAdmin] = useState(false);
  const [roleHrAdmin, setRoleHrAdmin] = useState(false);
  const [roleUser, setRoleUser] = useState(false);
  const rolesList = userData.roles;
  function removeTag(index) {
    let newRolesList = rolesList.filter(function (item) {
      return item !== index;
    });
    setUserData({ ...userData, roles: newRolesList });
  }

  // Handle WorksDays
  const workDaysList = userData.workdays;
  const handleWorkdays = (e) => {
    // Destructuring
    const { value, checked } = e.target;

    

    // Case 1 : The user checks the box
    if (checked) {
      workDaysList.push(value);
      setUserData({ ...userData, workdays: workDaysList });
    }

    // Case 2  : The user unchecked the box
    else {
      let newWorkDaysList = workDaysList.filter(function (item) {
        return item !== value;
      });
      setUserData({ ...userData, workdays: newWorkDaysList });
    }
  };

  // Handle Salary
  const removeSalaryRow = (index) => {
    setUserData((prev) => {
      const newUserData = {...prev};
      newUserData.salary = newUserData.salary.filter((el, i) => i !== index);
      return newUserData;
    })
  };

  // Handle Type in Salary table
  const addSalaryTypeRow = (val, index) => {
    setUserData((prev) => {
      const newUserData = {...prev};
      if (newUserData.salary) {
        newUserData.salary[index].type = val?.value;
      }
      return newUserData;
    })
  };

  // Handle Component
  const removeComponentRow = (index) => {
    setUserData((prev) => {
      const newUserData = {...prev};
      newUserData.component = newUserData.component.filter((el, i) => i !== index);
      return newUserData;
    })
  };

  // Handle Name in Component table
  const addComponentNameRow = (val, index) => {
    setUserData((prev) => {
      const newUserData = {...prev};
      if (newUserData.component) {
        newUserData.component[index].component_id = val?.value;
      }
      return newUserData;
    })
  };

  const ChooseBank = (val) => {
    setUserData({ ...ChooseBank, bank: val });
  };

  // Handle applicable
  const applicableObj = userData.applicable;
  const handleApplicable = (e) => {
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      if (value === "FWL") {
        applicableObj.FWL = checked;
        setUserData({ ...userData, applicable: applicableObj });
      }
      if (value === "SDL") {
        applicableObj.SDL = checked;
        setUserData({ ...userData, applicable: applicableObj });
      }
    }

    // Case 2  : The user unchecked the box
    else {
      if (value === "FWL") {
        applicableObj.FWL = checked;
        setUserData({ ...userData, applicable: applicableObj });
      }
      if (value === "SDL") {
        applicableObj.SDL = checked;
        setUserData({ ...userData, applicable: applicableObj });
      }
    }
  };

  useEffect(() => {
    setUserData({ ...userData, country: selectedCountry?.name });
  }, [selectedCountry]);
  useEffect(() => {
    setUserData({ ...userData, nationality: selectedNationality?.name });
  }, [selectedNationality]);
  useEffect(() => {
    setUserData({ ...userData, race: selectedRace?.value });
  }, [selectedRace]);
  useEffect(() => {
    setUserData({ ...userData, religion: selectedReligion?.value });
  }, [selectedReligion]);
  useEffect(() => {
    setUserData({ ...userData, state: selectedState?.name });
  }, [selectedState]);
  useEffect(() => {
    setUserData({ ...userData, city: selectedCity?.name });
  }, [selectedCity]);
  useEffect(() => {
    setUserData({ ...userData, department_id: selectedDepart?._id });
  }, [selectedDepart]);

  useEffect(() => {
    setUserData({ ...userData, status: selectedStatus?.value });
  }, [selectedStatus]);

  useEffect(() => {
    setUserData({ ...userData, imigrationStatus: selectedImmigration?.value });
  }, [selectedImmigration]);

  useEffect(() => {
    setUserData({
      ...userData,
      groupContribution: selectedGroupContribution?.value,
    });
  }, [selectedGroupContribution]);

  // useEffect(() => {
  // let tierName = {
  //   name: userData?.tier?.name,
  //   category: userData?.tier?.category,
  // };
  // tierName :selectedTier?.name;
  // setUserData((prevState) => ({
  //   ...prevState,
  //   tier: {
  //     ...prevState.tier,
  //     name: selectedTier?.value,
  //   },
  // }));
  // setUserData({ ...userData, tier: selectedTier?.name });
  // }, [selectedTier]);

  useEffect(() => {
    setUserData({
      ...userData,
      skillCategory: selectedTierCategory?.value,
    });
  }, [selectedTierCategory]);

  useEffect(() => {
    setUserData((prevState) => ({
      ...prevState,
      bank: {
        ...prevState.bank,
        bankOf: selectedBank?.value,
      },
    }));
  }, [selectedBank]);

  useEffect(() => {
    let result = reportTo?.length > 0 ? reportTo.map((a) => a._id) : [];
    setUserData({ ...userData, reportTo: result });
  }, [reportTo]);

  // useEffect(() => {
  //   setUserData({ ...userData, sector: selectedSector?.value });
  // }, [selectedSector]);

  const [saveUserData, data] = useCreateUserMutation();
  
  const errors = data?.error?.data ? data?.error?.data.message : null;

  const createUserAccount = (e) => {
    e.preventDefault();
    saveUserData(userData);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/users");
    }
  }, [data?.isSuccess]);

  const getSelectedComponent = (_id) => {
    return componentList?.data?.find(x => x._id === _id);
  }

  const getValueComponent = (component_id) => {
    const selectedComponent = componentList?.data?.find(x => x._id === component_id);
    return {
      label: selectedComponent?.name,
      value: selectedComponent?._id
    }
  }

  const getValueSalary = (type) => {
    const selectedSalary = optionsList?.data?.salaryType?.find(x => x.value === type);
    return {
      label: selectedSalary?.display,
      value: selectedSalary?.value
    }
  }

  return (
    <div className="create-new-user-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-hdr flex">
          <div className="steps-blk flex aic justify-between rel">
            <div className="line abs"></div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 1 ||
                step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6
                  ? "active"
                  : ""
              }`}
            >
              General Information
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 2 ||
                step === 3 ||
                step === 4 ||
                step === 5 ||
                step === 6
                  ? "active"
                  : ""
              }`}
            >
              Contact Information
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 3 || step === 4 || step === 5 || step === 6
                  ? "active"
                  : ""
              }`}
            >
              Roles & Permissions
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 4 || step === 5 || step === 6 ? "active" : ""
              }`}
            >
              Work days & Compensations
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 5 || step === 6 ? "active" : ""
              }`}
            >
              Immigration Details
            </div>
            <div
              className={`step-btn flex aic jc cursor-pointer ${
                step === 6 ? "active" : ""
              }`}
            >
              Bank Information
            </div>
          </div>
        </div>
        {errors !== null && (
          <div className="error-message mt-5 flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        <div className="content">
          {step === 1 ? (
            <div className="general-info-camp flex flex-col">
              <div className="wrap-info flex flex-col">
                <div className="page-title mb-5">General Information</div>
                <div className="row">
                  <div className="field flex flex-col">
                    <div className="lbl">First name</div>
                    <input
                      name="firstName"
                      type="text"
                      className="txt cleanbtn"
                      placeholder="First name"
                      value={userData.firstName}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Last name</div>
                    <input
                      name="lastName"
                      type="text"
                      className="txt cleanbtn"
                      placeholder="Last name"
                      value={userData.lastName}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Username</div>
                    <input
                      name="userName"
                      type="text"
                      className="txt cleanbtn"
                      placeholder="Username"
                      value={userData.userName}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Email</div>
                    <input
                      name="email"
                      type="text"
                      className="txt cleanbtn"
                      placeholder="Email"
                      value={userData.email}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Contact no</div>
                    <input
                      name="contactNo"
                      type="text"
                      className="txt cleanbtn"
                      placeholder="Contact no"
                      value={userData.contactNo}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">National id</div>
                    <input
                      name="nationalId"
                      type="text"
                      className="txt cleanbtn"
                      placeholder="National id"
                      value={userData.nationalId}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Date of birth</div>
                    <div className="date-picker flex aic jc">
                      <Datetime
                        closeOnSelect={true}
                        value={dateBirth ? dateBirth : new Date().getTime()}
                        onChange={(value) => {
                          {
                            setDateBirth(new Date(value).getTime());
                            setUserData({
                              ...userData,
                              dateOfBirth: new Date(value).getTime(),
                            });
                          }
                        }}
                        timeFormat={false}
                        dateFormat="MMMM Do, YYYY"
                        className="start-date cleanbtn"
                      />
                      <div className="ico">
                        <CalendarIcon />
                      </div>
                    </div>
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Department</div>
                    <Select
                      defaultValue={selectedDepart}
                      value={selectedDepart}
                      onChange={setSelectedDepart}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      options={departList}
                      // options={departmentsList?.data}
                      name="department_id"
                      className="select"
                      placeholder="Department"
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Designation</div>
                    <input
                      name="designation"
                      type="text"
                      className="txt cleanbtn"
                      placeholder="Designation"
                      value={userData.designation}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Report to</div>
                    <Select
                      defaultValue={reportTo}
                      value={reportTo}
                      onChange={setReportTo}
                      isMulti
                      getOptionLabel={(option) => option.firstName}
                      getOptionValue={(option) => option.firstName}
                      options={usersList?.data}
                      name="reportTo"
                      className="select"
                      placeholder="Report To"
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Status</div>
                    <Select
                      defaultValue={selectedStatus}
                      value={selectedStatus}
                      onChange={setSelectedStatus}
                      getOptionLabel={(option) => option.display}
                      getOptionValue={(option) => option.value}
                      options={optionsList?.data?.userStatuses}
                      name="status"
                      className="select"
                      placeholder="Status"
                    />
                  </div>
                  {/* <div className="field flex flex-col">
                    <div className="lbl">Sector</div>
                    <Select
                      defaultValue={selectedSector}
                      value={selectedSector}
                      onChange={setSelectedSector}
                      getOptionLabel={(option) => option.display}
                      getOptionValue={(option) => option.value}
                      options={optionsList?.data?.sectorList}
                      name="sector"
                      className="select"
                      placeholder="Sector"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          ) : step === 2 ? (
            <div className="general-info-camp flex flex-col">
              <div className="wrap-info flex flex-col">
                <div className="page-title mb-5">Contact Information</div>
                <div className="row">
                  <div className="field flex flex-col">
                    <div className="lbl">Country</div>
                    <Select
                      defaultValue={selectedCountry}
                      value={selectedCountry}
                      onChange={setSelectedCountry}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.country_code}
                      options={countries?.data}
                      className="select"
                      id="country"
                      name="country"
                      placeholder="Select Country"
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">State/Region</div>
                    <Select
                      defaultValue={selectedState}
                      value={selectedState}
                      onChange={setSelectedState}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.state_code}
                      options={states?.data}
                      className="select"
                      placeholder="State/Region"
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">City</div>
                    <Select
                      defaultValue={selectedCity}
                      value={selectedCity}
                      onChange={setSelectedCity}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.state_code}
                      options={cites?.data}
                      className="select"
                      placeholder="City"
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Postal Code</div>
                    <input
                      name="postalCode"
                      type="text"
                      className="txt cleanbtn"
                      placeholder="Postal Code"
                      value={userData.postalCode}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center py-5">
                    <div className="lbl mr-5">Add Address</div>
                    <div
                      className="ico hover:cursor-pointer"
                      onClick={(e) => {
                        setUserData({
                          ...userData,
                          address: [...userData.address, ""],
                        });
                      }}
                    >
                      <AddIcon />
                    </div>
                  </div>
                  <div className="row">
                    {userData?.address?.length > 0 ? (
                      <>
                        {userData?.address.map((item, index) => (
                          <div key={index} className="field flex flex-col">
                            <div className="lbl">Address {index + 1}</div>{" "}
                            <div className="bd rounded-md flex items-center">
                              <input
                                type="text"
                                name="address"
                                className="w-full p-2 mx-1"
                                value={item}
                                placeholder="Address"
                                onChange={handleAddress(index)}
                              />
                              <div
                                className="cross-icon cursor-pointer"
                                onClick={(e) => removeAddress(index)}
                              >
                                X
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : step === 3 ? (
            <div className="general-info-camp flex flex-col">
              <div className="wrap-info flex flex-col">
                <div className="page-title mb-5">Roles</div>
                <div className="row">
                  <div className="field flex flex-col">
                    <div className="lbl">Roles</div>
                    <div className="item flex aic mb-2">
                      <button
                        type="button"
                        onClick={(e) => {
                          // setRoleSuperAdmin(!roleSuperAdmin);
                          if (roleSuperAdmin !== true) {
                            rolesList.push("superAdmin");
                          } else {
                            removeTag("superAdmin");
                          }
                        }}
                        className={`cleanbtn cursor-not-allowed important:not-allowed checkbox-btn rel ${
                          roleSuperAdmin ? "on" : ""
                        }`}
                      />
                      <div className="check-box-lbl s14 ml-4">Super admin</div>
                    </div>
                    <div className="item flex aic mb-2">
                      <button
                        type="button"
                        onClick={(e) => {
                          setRoleHrAdmin(!roleHrAdmin);
                          if (roleHrAdmin !== true) {
                            rolesList.push("hrAdmin");
                          } else {
                            removeTag("hrAdmin");
                          }
                        }}
                        className={`cleanbtn checkbox-btn rel ${
                          roleHrAdmin ? "on" : ""
                        }`}
                      />
                      <div className="check-box-lbl s14 ml-4">HR admin</div>
                    </div>
                    <div className="item flex aic">
                      <button
                        type="button"
                        onClick={(e) => {
                          setRoleUser(!roleUser);
                          if (roleUser !== true) {
                            rolesList.push("user");
                          } else {
                            removeTag("user");
                          }
                        }}
                        className={`cleanbtn checkbox-btn rel ${
                          roleUser ? "on" : ""
                        }`}
                      />
                      <div className="check-box-lbl s14 ml-4">User</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : step === 4 ? (
            <div className="working-days-camp flex flex-col">
              <div className="wrap-info flex flex-col">
                <div className="page-title mb-5">Work Days</div>
                <div className="row">
                  <div className="field flex flex-col">
                    <div className="lbl">Work Days</div>
                    <div className="week-days flex aic mb-8">
                      {
                        ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day, index) => (
                          <div className="item flex aic mb-2 mr-7" key={index}>
                            <input
                              type="checkbox"
                              name="workdays"
                              value={`${day}`}
                              onChange={handleWorkdays}
                              checked={userData?.workdays?.filter(x => x === day).length > 0 ? true : false}
                              className="check-box mr-2 h-5 w-5 cursor-pointer"
                            />
                            <div className="check-box-lbl s16">{day}</div>
                          </div>
                        ))
                      }
                      {/* <div className="item flex aic mb-2 mr-7">
                        <input
                          type="checkbox"
                          name="workdays"
                          value="Tuesday"
                          onChange={handleWorkdays}
                          className="check-box mr-2 h-5 w-5 cursor-pointer"
                        />
                        <div className="check-box-lbl s16">Tuesday</div>
                      </div>
                      <div className="item flex aic mb-2 mr-7">
                        <input
                          type="checkbox"
                          name="workdays"
                          value="Tuesday"
                          onChange={handleWorkdays}
                          className="check-box mr-2 h-5 w-5 cursor-pointer"
                        />
                        <div className="check-box-lbl s16">Wednesday</div>
                      </div>
                      <div className="item flex aic mb-2 mr-7">
                        <input
                          type="checkbox"
                          name="workdays"
                          value="Thursday"
                          onChange={handleWorkdays}
                          className="check-box mr-2 h-5 w-5 cursor-pointer"
                        />
                        <div className="check-box-lbl s16">Thursday</div>
                      </div>
                      <div className="item flex aic mb-2 mr-7">
                        <input
                          type="checkbox"
                          name="workdays"
                          value="Friday"
                          onChange={handleWorkdays}
                          className="check-box mr-2 h-5 w-5 cursor-pointer"
                        />
                        <div className="check-box-lbl s16">Friday</div>
                      </div>
                      <div className="item flex aic mb-2 mr-7">
                        <input
                          type="checkbox"
                          name="workdays"
                          value="Saturday"
                          onChange={handleWorkdays}
                          className="check-box mr-2 h-5 w-5 cursor-pointer"
                        />
                        <div className="check-box-lbl s16">Saturday</div>
                      </div>
                      <div className="item flex aic mb-2 mr-7">
                        <input
                          type="checkbox"
                          name="workdays"
                          value="Sunday"
                          onChange={handleWorkdays}
                          className="check-box mr-2 h-5 w-5 "
                        />
                        <div className="check-box-lbl s16">Sunday</div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* Compensations */}
                <div className="flex flex-col">
                  <div className="page-title mb-5">Compensations</div>
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="item flex aic mb-2 mr-7">
                        <div className="lbl mr-2">Salary</div>
                        <div
                          className="icon flex aic jc cursor-pointer"
                          onClick={(e) => {
                            setUserData({
                              ...userData,
                              salary: [
                                ...userData.salary,
                                {
                                  effectiveDate: "",
                                  amount: null,
                                  type: "",
                                },
                              ],
                            });
                          }}
                        >
                          <AddIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-blk flex">
                    <div className="tbl flex flex-col">
                      <div className="tbl-row flex aic">
                        <div className="tbl-col flex flex-1">
                          <div className="col-item">Effective Date</div>
                        </div>
                        <div className="tbl-col flex flex-1">
                          <div className="col-item">Amount</div>
                        </div>
                        <div className="tbl-col flex flex-1">
                          <div className="col-item">Type</div>
                        </div>
                        <div className="tbl-col flex">
                          <div className="col-item"></div>
                        </div>
                      </div>
                      {userData.salary?.length &&
                        userData.salary?.map((item, index) => (
                          <div key={index} className="tbl-row flex aic">
                            <div className="tbl-col flex flex-1">
                              <div className="date-picker flex aic jc">
                                <Datetime
                                  closeOnSelect={true}
                                  // value={
                                  //   dateBirth ? dateBirth : new Date().getTime()
                                  // }
                                  onChange={(value) => {
                                    setUserData((prev) => {
                                      const newUserData = {...prev};
                                      newUserData.salary[index].effectiveDate = new Date(
                                        value
                                      ).getTime();
                                      return newUserData;
                                    })
                                  }}
                                  value={item.effectiveDate}
                                  name="effectiveDate"
                                  timeFormat={false}
                                  dateFormat="MMMM Do, YYYY"
                                  className="start-date cleanbtn"
                                />
                              </div>
                            </div>
                            <div className="tbl-col flex flex-1">
                              <input
                                type="text"
                                name="amount"
                                value={item.amount}
                                onChange={(e) =>
                                  setUserData((prev) => {
                                    const newUserData = {...prev};
                                    newUserData.salary[index].amount = e.target.value;
                                    return newUserData;
                                  })
                                }
                                className="col-item cleanbtn row-txt"
                                placeholder="Amount"
                              />
                            </div>
                            <div className="tbl-col flex flex-1">
                              <Select
                                value={getValueSalary(item.type)}
                                defaultValue={getValueSalary(item.type)}
                                onChange={(e) => addSalaryTypeRow(e, index)}
                                options={optionsList?.data?.salaryType?.map(x => (
                                  {
                                    label: x.display,
                                    value: x.value
                                  }
                                ))}
                                name="type"
                                className="select w-full"
                                placeholder="Type"
                              />
                            </div>
                            <div className="tbl-col flex">
                              <div className="col-item flex aic jc">
                                {/* <div className="icon cursor-pointer">
                                      <EditIcon />
                                    </div> */}
                                <div
                                  className="icon cursor-pointer"
                                  onClick={(e) => removeSalaryRow(index)}
                                >
                                  <DelIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                {/* Components */}
                <div className="flex flex-col mt-10">
                  <div className="page-title mb-5">Components</div>
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="item flex aic mb-2 mr-7">
                        <div className="lbl mr-2">Add</div>
                        <div
                          className="icon flex aic jc cursor-pointer"
                          onClick={(e) => {
                            setUserData({
                              ...userData,
                              component: [
                                ...userData.component,
                                {
                                  effectiveDate: "",
                                  component_id: "",
                                },
                              ],
                            });
                          }}
                        >
                          <AddIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-blk flex">
                    <div className="tbl flex flex-col">
                      <div className="tbl-row flex aic">
                        <div className="tbl-col flex flex-1">
                          <div className="col-item">Name</div>
                        </div>
                        <div className="tbl-col flex flex-1">
                          <div className="col-item">Effective Date</div>
                        </div>
                        <div className="tbl-col flex flex-1">
                          <div className="col-item">Amount</div>
                        </div>
                        <div className="tbl-col flex flex-1">
                          <div className="col-item">Type</div>
                        </div>
                        <div className="tbl-col flex">
                          <div className="col-item"></div>
                        </div>
                      </div>
                      {userData?.component?.length > 0 &&
                        userData?.component?.map((item, index) => (
                          <div key={index} className="tbl-row flex aic">
                            <div className="tbl-col flex flex-1">
                              <Select
                                value={getValueComponent(item.component_id)}
                                defaultValue={getValueComponent(item.component_id)}
                                onChange={(e) => {
                                  addComponentNameRow(e, index);
                                }}
                                options={componentList?.data?.map(x => (
                                  {
                                    value: x._id,
                                    label: x.name
                                  }
                                ))}
                                name="name"
                                className="select w-4/5"
                                placeholder="Name"
                              />
                            </div>

                            <div className="tbl-col flex flex-1">
                              <div className="date-picker flex aic jc">
                                <Datetime
                                  closeOnSelect={true}
                                  value={item.effectiveDate}
                                  onChange={(value) => {
                                    {
                                      setUserData((prev) => {
                                        const newUserData = {...prev};
                                        newUserData.component[index].effectiveDate = new Date(
                                          value
                                        ).getTime();
                                        return newUserData;
                                      })
                                    }
                                  }}
                                  name="effectiveDate"
                                  timeFormat={false}
                                  dateFormat="MMMM Do, YYYY"
                                  className="start-date cleanbtn"
                                />
                              </div>
                            </div>

                            <div className="tbl-col flex flex-1">
                              <input
                                type="text"
                                name="amount"
                                value={getSelectedComponent(item.component_id)?.amount ?? 0}
                                disabled
                                className="col-item cleanbtn row-txt"
                                placeholder="Amount"
                              />
                            </div>
                            <div className="tbl-col flex flex-1">
                              <input
                                type="text"
                                name="type"
                                value={getSelectedComponent(item.component_id)?.type ?? ""}
                                disabled
                                className="col-item cleanbtn row-txt"
                                placeholder="Type"
                              />
                            </div>
                            <div className="tbl-col flex">
                              <div className="col-item flex aic jc">
                                {/* <div className="icon cursor-pointer">
                                  <EditIcon />
                                </div> */}
                                <div
                                  className="icon cursor-pointer"
                                  onClick={(e) => removeComponentRow(index)}
                                >
                                  <DelIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : step === 5 ? (
            <div className="general-info-camp flex flex-col">
              <div className="wrap-info flex flex-col">
                <div className="page-title mb-5">Immigration Details</div>
                <div className="row">
                  <div className="field flex flex-col">
                    <div className="lbl">Immigration Status</div>
                    <Select
                      defaultValue={selectedImmigration}
                      value={selectedImmigration}
                      onChange={setSelectedImmigration}
                      getOptionLabel={(option) => option.display}
                      getOptionValue={(option) => option.value}
                      options={optionsList?.data?.imigrationStatusList}
                      className="select"
                      id="imigrationStatus"
                      name="imigrationStatus"
                      placeholder="Immigration Status"
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Identification Number</div>
                    <input
                      name="identificationNumber"
                      type="text"
                      className="txt cleanbtn"
                      placeholder="Identification Number"
                      value={userData.identificationNumber}
                      onChange={handleInput}
                    />
                  </div>
                  {selectedImmigration?.display === "S Pass" ||
                  selectedImmigration?.display === "PRC WP" ||
                  selectedImmigration?.display === "Work Permit" ? (
                    <>
                      <div className="field flex flex-col">
                        <div className="lbl">Issue Date</div>
                        <div className="date-picker flex aic jc">
                          <Datetime
                            closeOnSelect={true}
                            value={issueDate ? issueDate : new Date().getTime()}
                            onChange={(value) => {
                              {
                                setIssueDate(new Date(value).getTime());
                                setUserData({
                                  ...userData,
                                  issueDate: new Date(value).getTime(),
                                });
                              }
                            }}
                            timeFormat={false}
                            dateFormat="MMMM Do, YYYY"
                            className="start-date cleanbtn"
                          />

                          <div className="ico">
                            <CalendarIcon />
                          </div>
                        </div>
                      </div>
                      <div className="field flex flex-col">
                        <div className="lbl">Expiry Date</div>
                        <div className="date-picker flex aic jc">
                          <Datetime
                            closeOnSelect={true}
                            value={expDate ? expDate : new Date().getTime()}
                            onChange={(value) => {
                              setExpDate(new Date(value).getTime());
                              setUserData({
                                ...userData,
                                expiryDate: new Date(value).getTime(),
                              });
                            }}
                            timeFormat={false}
                            dateFormat="MMMM Do, YYYY"
                            className="start-date cleanbtn"
                          />
                          <div className="ico">
                            <CalendarIcon />
                          </div>
                        </div>
                      </div>
                      {/* <div className="field flex flex-col">
                        <div className="lbl">Tier</div>
                        <Select
                          defaultValue={selectedTier}
                          value={selectedTier}
                          onChange={setSelectedTier}
                          getOptionLabel={(option) => option.display}
                          getOptionValue={(option) => option.value}
                          options={optionsList?.data?.tierList}
                          className="select"
                          id="Tier"
                          name="Tier"
                          placeholder="Tier"
                        />
                      </div> */}
                      <div className="field flex flex-col">
                        <div className="lbl">Tier Category</div>
                        <Select
                          defaultValue={selectedTierCategory}
                          value={selectedTierCategory}
                          onChange={setSelectedTierCategory}
                          getOptionLabel={(option) => option.display}
                          getOptionValue={(option) => option.value}
                          options={optionsList?.data?.skillCategoryList}
                          className="select"
                          id="TierCategory"
                          name="TierCategory"
                          placeholder="Tier Category"
                        />
                      </div>
                    </>
                  ) : null}
                  <div className="field flex flex-col">
                    <div className="lbl">Nationality</div>
                    <Select
                      defaultValue={selectedNationality}
                      value={selectedNationality}
                      onChange={setSelectedNationality}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.country_code}
                      options={countries?.data}
                      className="select"
                      id="nationality"
                      name="nationality"
                      placeholder="Nationality"
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Race</div>
                    <Select
                      defaultValue={selectedRace}
                      value={selectedRace}
                      onChange={setSelectedRace}
                      getOptionLabel={(option) => option.display}
                      getOptionValue={(option) => option.value}
                      options={optionsList?.data?.raceList}
                      className="select"
                      id="race"
                      name="race"
                      placeholder="Race"
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">Religion</div>
                    <Select
                      defaultValue={selectedReligion}
                      value={selectedReligion}
                      onChange={setSelectedReligion}
                      getOptionLabel={(option) => option.display}
                      getOptionValue={(option) => option.value}
                      options={optionsList?.data?.religionList}
                      className="select"
                      id="religion"
                      name="religion"
                      placeholder="Religion"
                    />
                  </div>
                </div>
                <div className="page-title mb-5  mt-5">Statutory Details</div>
                <div className="row">
                  <div className="field flex flex-col">
                    <div className="lbl">Group Contribution</div>
                    <Select
                      defaultValue={selectedGroupContribution}
                      value={selectedGroupContribution}
                      onChange={setSelectedGroupContribution}
                      getOptionLabel={(option) => option.display}
                      getOptionValue={(option) => option.value}
                      options={optionsList?.data?.shgsList}
                      className="select"
                      id="groupContribution"
                      name="groupContribution"
                      placeholder="Group Contribution"
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="lbl">CPF Account Number</div>
                    <input
                      name="cpfAccountNo"
                      type="text"
                      className="txt cleanbtn"
                      placeholder="CPF Account Number"
                      value={userData.cpfAccountNo}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="field flex flex-col">
                    <div className="item flex aic mb-2">
                      <input
                        type="checkbox"
                        name="SDL"
                        value="SDL"
                        onChange={handleApplicable}
                        className="check-box cursor-pointer mr-4 h-4 w-4"
                      />
                      <div className="check-box-lbl s14">SDL Applicable </div>
                    </div>
                    <div className="item flex aic mb-2">
                      <input
                        type="checkbox"
                        name="FWL"
                        value="FWL"
                        onChange={handleApplicable}
                        className="check-box cursor-pointer mr-4 h-4 w-4"
                      />
                      <div className="check-box-lbl s14">FWL Applicable </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : step == 6 ? (
            <div className="general-info-camp flex flex-col">
              <div className="wrap-info flex flex-col">
                <div className="page-title mb-5">Bank Information</div>
                <div className="add-bank flex aic mb-5">
                  <div className="lbl mr-2">Add Bank Information</div>
                  <div
                    className="icon cursor-pointer"
                    onClick={(e) => {
                      setAddBank(true);
                      // setUserData({
                      //   ...userData,
                      //   address: [...userData.bank, {}],
                      // });
                    }}
                  >
                    <AddIcon />
                  </div>
                </div>
                {userInfo?.bank?.accountNo === "" || addBank ? (
                  <div className="row">
                    <div className="field flex flex-col">
                      <div className="lbl">Bank</div>
                      <Select
                        defaultValue={selectedBank}
                        onChange={setSelectedBank}
                        getOptionLabel={(option) => option.display}
                        getOptionValue={(option) => option.value}
                        options={optionsList?.data.bankList}
                        className="select"
                        id="bankOf"
                        name="bankOf"
                        placeholder="Select Bank"
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Account Name</div>
                      <input
                        name="accountName"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Account Name"
                        value={userData?.bank?.accountName}
                        onChange={handleBankInput}
                      />
                    </div>
                    <div className="field flex flex-col">
                      <div className="lbl">Account No.</div>
                      <input
                        name="accountNo"
                        type="text"
                        className="txt cleanbtn"
                        placeholder="Account No."
                        value={userData?.bank?.accountNo}
                        onChange={handleBankInput}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full text-2xl  font-medium uppercase">
                    Add your bank information
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
        <div className="action flex aic justify-end">
          {step > 1 && (
            <div className="btn button mr-[12px]" onClick={(e) => prevStep()}>
              Previous
            </div>
          )}
          <div
            className={`btn button  ${step === 6 ? "hidden" : ""}`}
            onClick={(e) => nextStep()}
          >
            Next
          </div>
          {step === 6 && (
            <div className="btn button" onClick={(e) => createUserAccount(e)}>
              Update
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateNewUser;
