import { createSlice } from "@reduxjs/toolkit";
const userINFO = localStorage.getItem("user-info");
const userRole = localStorage.getItem("user-role");
const token = localStorage.getItem("user-token");
const orgid = localStorage.getItem("user-orgid");
const uId = localStorage.getItem("user-uId");
const orgList = JSON.parse(localStorage.getItem("orgList"));

const authReducer = createSlice({
  name: "authReducer",
  initialState: {
    userInfo: userINFO ? userINFO : null,
    userId: uId ? uId : null,
    userRole: userRole ? userRole : null,
    userToken: token ? token : null,
    userOrgId: orgid ? orgid : null,
    orgList: orgList ? orgList : null,
  },

  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setUserOrgId: (state, action) => {
      state.userOrgId = action.payload;
    },
    setOrgList: (state, action) => {
      state.orgList = action.payload;
    },

    logout: (state) => {
      localStorage.removeItem("user-info");
      localStorage.removeItem("user-token");
      localStorage.removeItem("user-role");
      localStorage.removeItem("user-uId");
      localStorage.removeItem("user-userId");
      localStorage.removeItem("user-orgid");
      localStorage.removeItem("orgList");
      state.userInfo = null;
      state.userRole = null;
      state.userToken = null;
      state.userOrgId = null;
      state.orgList = null;
    },
  },
});

export const {
  setUserInfo,
  setUserId,
  setUserRole,
  setUserToken,
  setUserOrgId,
  setOrgList,
  logout,
} = authReducer.actions;
export default authReducer.reducer;
