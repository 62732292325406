import React from "react";

function DelIconPy(status, typePage, statusTwo) {
  let pathText = "disabled";
  if (typePage, "payroll") {
    pathText = (`${status.status}` === 'draft' || status?.status === undefined) ? "enabled" : "disabled"
  } else if (typePage, "claim") {
    if (statusTwo === 'pending' &&  status === 'pending') {
      pathText = "enabled"
    }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        className={`${pathText}`}
        d="M21.445 5.601c-1.61-.16-3.22-.28-4.84-.37v-.01l-.22-1.3c-.15-.92-.37-2.3-2.71-2.3h-2.62c-2.33 0-2.55 1.32-2.71 2.29l-.21 1.28c-.93.06-1.86.12-2.79.21l-2.04.2c-.42.04-.72.41-.68.82.04.41.4.71.82.67l2.04-.2c5.24-.52 10.52-.32 15.82.21h.08c.38 0 .71-.29.75-.68a.766.766 0 00-.69-.82zM19.607 8.511c-.24-.25-.57-.39-.91-.39H6.057c-.34 0-.68.14-.91.39-.23.25-.36.59-.34.94l.62 10.26c.11 1.52.25 3.42 3.74 3.42h6.42c3.49 0 3.63-1.89 3.74-3.42l.62-10.25c.02-.36-.11-.7-.34-.95zm-5.57 9.61h-3.33c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h3.33c.41 0 .75.34.75.75s-.34.75-.75.75zm.84-4h-5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
    </svg>
  );
}

export default DelIconPy;
