import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import Loader from "../Loader";
import { useGetQuery } from "../../store/services/generalService";
import { useCreateCpfAcMutation } from "../../store/services/cpfAccountService";

const CreateNewCpfAc = () => {
  const navigate = useNavigate();
  const { data: options, isLoading } = useGetQuery();

  const [selectedEmpType, setSelectedEmpType] = useState(null);
  const [employeeTypeList, setEmployeeTypeList] = useState([]);
  const [selectedSectorType, setSelectedSectorType] = useState(null);
  const [sectorList, setSectorList] = useState([]);
  const [createCpfAccount, setCreateCpfAccount] = useState({
    accountNo: "",
    submissionNo: "",
    organizationIdNo: "",
    employeeType: "",
    adviseCode: "",
    paymentCode: "",
    serialNo: "",
    sectorType: "",
  });
  const handleInput = (e) => {
    setCreateCpfAccount({
      ...createCpfAccount,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!isLoading) {
      setEmployeeTypeList(options?.data.userStatuses);
      setSectorList(options?.data.sectorList);
    }
  }, [options?.data]);

  useEffect(() => {
    setCreateCpfAccount({
      ...createCpfAccount,
      employeeType: selectedEmpType?.value,
    });
  }, [selectedEmpType]);

  useEffect(() => {
    setCreateCpfAccount({
      ...createCpfAccount,
      sectorType: selectedSectorType?.value,
    });
  }, [selectedSectorType]);

  const [saveCfpAccountData, data] = useCreateCpfAcMutation();

  const errors = data?.error?.data ? data?.error?.data.message : null;

  const createCpfBankAccount = (e) => {
    e.preventDefault();
    saveCfpAccountData(createCpfAccount);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/cpf-account");
    }
  }, [data?.isSuccess]);

  return (
    <form onSubmit={createCpfBankAccount} className="new-department flex">
      <div className="wrap wrapWidth flex flex-col">
        {!isLoading ? (
          <>
            {errors !== null && (
              <div className="flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
                {errors}
              </div>
            )}
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">CPF Account</div>
                <input
                  id="accountNo"
                  name="accountNo"
                  value={createCpfAccount.accountNo}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="CPF Account"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">CPF Submission No.</div>
                <input
                  id="submissionNo"
                  name="submissionNo"
                  value={createCpfAccount.submissionNo}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="CPF Submission No."
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Organization ID</div>
                <input
                  id="organizationIdNo"
                  name="organizationIdNo"
                  value={createCpfAccount.organizationIdNo}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="0000-0"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Employee Type</div>
                <Select
                  defaultValue={selectedEmpType}
                  onChange={setSelectedEmpType}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={employeeTypeList}
                  className="select"
                  id="employeeType"
                  name="employeeType"
                  placeholder="Select Type of Employee"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Advise Code</div>
                <input
                  id="adviseCode"
                  name="adviseCode"
                  value={createCpfAccount.adviseCode}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="0000-0"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Payment Code</div>
                <input
                  id="paymentCode"
                  name="paymentCode"
                  value={createCpfAccount.paymentCode}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="00000"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Serial No.</div>
                <input
                  id="serialNo"
                  name="serialNo"
                  value={createCpfAccount.serialNo}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="000000"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Sector Type</div>
                <Select
                  defaultValue={selectedSectorType}
                  onChange={setSelectedSectorType}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={sectorList}
                  className="select"
                  id="sectorType"
                  name="sectorType"
                  placeholder="Select Sector Type"
                />
              </div>
            </div>
            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Create"}
                className="btn button"
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </form>
  );
};

export default CreateNewCpfAc;
