import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const departmentsService = createApi({
  reducerPath: "departments",
  tagTypes: "department",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New Department
      create: builder.mutation({
        query: (createDepart) => {
          return { url: "/api/department", method: "POST", body: createDepart };
        },
        invalidatesTags: ["department"],
      }),

      //   Delete a Department
      deleteDepartment: builder.mutation({
        query: (uId) => {
          return { url: `/api/department/${uId}`, method: "DELETE" };
        },
        invalidatesTags: ["department"],
      }),

      //   Update the Department
      updateDepartment: builder.mutation({
        query: (data) => {
          return {
            url: `/api/department/${data.uId}`,
            method: "PUT",
            body: data.createDepart,
          };
        },
        invalidatesTags: ["department"],
      }),

      //   Get the List of Departments
      getDepartList: builder.query({
        query: () => {
          return {
            url: "/api/department",
            method: "GET",
          };
        },
        providesTags: ["department"],
      }),

      //   Get the Single Department
      fetchDepartment: builder.query({
        query: (uId) => {
          return {
            url: `/api/department/${uId}`,
            method: "GET",
          };
        },
        providesTags: ["department"],
      }),
    };
  },
});

export const {
  useCreateMutation,
  useGetDepartListQuery,
  useDeleteDepartmentMutation,
  useFetchDepartmentQuery,
  useUpdateDepartmentMutation,
} = departmentsService;

export default departmentsService;
