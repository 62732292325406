import React from "react";

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        fill="#D7D7D7"
        d="M14.003 10.732l-3.458-3.438 3.457-3.437a1.078 1.078 0 000-1.527L12.464.803a1.096 1.096 0 00-1.536 0L7.47 4.239 4.013.803a1.096 1.096 0 00-1.536 0L.942 2.33a1.078 1.078 0 000 1.527l3.457 3.437-3.457 3.438a1.078 1.078 0 000 1.527l1.536 1.526c.423.42 1.115.42 1.536 0l3.458-3.436 3.456 3.436c.423.42 1.114.42 1.539 0l1.536-1.526a1.078 1.078 0 000-1.527z"
      ></path>
    </svg>
  );
}

export default CloseIcon;
