import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../assets";
import { useDeleteMyClaimMutation } from "../../store/services/claimsService";

const DepartmentDelete = ({ setOpen, data }) => {
  const [removeMyClaim, response] = useDeleteMyClaimMutation();

  const deleteMyClaim = (uId) => {
    removeMyClaim(uId);
  };

  useEffect(() => {
    if (response?.data?.data === "success") {
      setOpen(false);
    }
  }, [response?.data?.data === "success"]);

  return (
    <div className="depart-delete flex">
      <div className="wrap flex flex-col">
        <div className="hdr flex justify-end">
          <div
            className="icon flex aic jc cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="blk flex aic  flex-col justify-around">
          <div className="desc">Are you sure to delete Claim?</div>
          <div className="actions flex aic jc">
            <div className="btn button" onClick={(e) => setOpen(false)}>
              Cancel
            </div>
            <div
              className="btn button"
              onClick={(e) => deleteMyClaim(data?.uId)}
            >
              Delete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentDelete;
