import React from "react";

function AttachmentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="34"
      fill="none"
      viewBox="0 0 35 34"
    >
      <path
        fill="#4E7A7D"
        d="M8.897 26.518a4.2 4.2 0 01-2.946-1.207l-.087-.087a4.133 4.133 0 01-1.247-2.96 4.513 4.513 0 011.334-3.213L16.284 8.83a5.78 5.78 0 018.1-.233 5.334 5.334 0 011.567 3.813 6 6 0 01-1.8 4.24l-9.287 9.2a.667.667 0 11-.914-.947l9.26-9.2a4.665 4.665 0 001.407-3.293 4.002 4.002 0 00-1.173-2.867 4.447 4.447 0 00-6.227.234l-10.3 10.22a3.186 3.186 0 00-.966 2.266 2.807 2.807 0 00.826 2l.087.087a3.093 3.093 0 004.333-.14l8.9-8.8a1.8 1.8 0 00.52-1.293 1.568 1.568 0 00-.46-1.12 1.74 1.74 0 00-2.44.086l-6.133 6.08a.667.667 0 11-.94-.946l6.16-6.1a3.08 3.08 0 014.32-.087 2.887 2.887 0 01.827 2.087 3.12 3.12 0 01-.94 2.226l-8.874 8.827a4.607 4.607 0 01-3.24 1.347z"
        opacity="0.4"
      ></path>
    </svg>
  );
}

export default AttachmentIcon;
