import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  SearchIcon,
  EditIcon,
  UserAddIcon,
  DropDownIcon,
  DropUpIcon,
} from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import {
  useGetClaimPendingApprovalsListQuery,
  useGetClaimApprovedListQuery,
} from "../../store/services/claimsService";
import { thousandAndDecimalFormat } from "../../utils/common";
import DataTable from "react-data-table-component";

const UserClaimsApprovalList = () => {
  const [allDataApprovedList , setDataApprovedList] = useState([]);
  const [filteredDataApprovedList , setFilteredDataApprovedList] = useState([]);
  const [allData , setData] = useState([]);
  const [filteredData , setFilteredData] = useState([]);
  const { userRole, userToken, userOrgId, userId, userInfo } = useSelector(
    (state) => state.authReducer
  );
  let logedInfo = typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;

  const {
    data: pendingApprovalListData,
    isLoading: pendingApprovalListLoading,
    isFetching: pendingApprovalListFetching,
  } = useGetClaimPendingApprovalsListQuery(logedInfo?._id);

  const {
    data: approvalListData,
    isLoading: approvalListLoading,
    isFetching: approvalListFetching,
  } = useGetClaimApprovedListQuery(logedInfo?._id);

  const getTotal = (data) => {
    let result = 0;
    if (data) {
      if (data.length > 0) {
        data.map((element) => {
          result += element?.amount ? element.amount : 0;
        });
      }
    }
    return result;
  }

  const [openTable, setOpenTable] = useState(true);
  const [users, setUsers] = useState([]);

  const searchFieldIs = [
    "user_id",
    "uId",
    "requestDate",
    "details",
    "status"
  ];
  const searchPendingFieldIs = [
    "user_id",
    "uId",
    "requestDate",
    "details"
  ];
  const searchText = (target, value) => {
    return target.toLowerCase().indexOf(value) > -1 ? true : false;
  }
  const handleSearch = (event, type) => {
    let value = event.target.value.toLowerCase();
    
    if (type === "pending") {
      if (event.target.value !== "") {
        let result = [];
        result = allData.filter((data) => {
          let flag = false;
          for (let i = 0; i < searchPendingFieldIs.length; i++) {
            const targetField = searchPendingFieldIs[i];
            if (data[`${targetField}`]) {
              if (targetField === "requestDate") {
                const dateTarget = new Date(data[`${targetField}`]);
                const dateString = String(`${dateTarget.getDate()}-${dateTarget.getMonth()+1}-${dateTarget.getFullYear()}`);
                flag = searchText(dateString, value);
                if (flag) break;
              }else if (targetField === "details") {
                const targetDataValue = String(getTotal(data[`${targetField}`]));
                flag = searchText(targetDataValue, value);
                if (flag) break;
              } else if (targetField === "user_id") {
                // search first name
                flag = searchText(data[`${targetField}`].firstName, value);
                if (flag) break;
              } else {
                flag = searchText(data[`${targetField}`], value);
                if (flag) break;
              }
            }
          }
          if (flag) return data
        });
        setFilteredData(result);
      } else {
        setFilteredData(allData);
      }
    } else {
      if (event.target.value !== "") {
        let result = [];
        result = allDataApprovedList.filter((data) => {
          let flag = false;
          for (let i = 0; i < searchFieldIs.length; i++) {
            const targetField = searchFieldIs[i];
            if (data[`${targetField}`]) {
              if (targetField === "requestDate") {
                const dateTarget = new Date(data[`${targetField}`]);
                const dateString = String(`${dateTarget.getDate()}-${dateTarget.getMonth()+1}-${dateTarget.getFullYear()}`);
                flag = searchText(dateString, value);
                if (flag) break;
              }else if (targetField === "details") {
                const targetDataValue = String(getTotal(data[`${targetField}`]));
                flag = searchText(targetDataValue, value);
                if (flag) break;
              } else if (targetField === "user_id") {
                // search first name
                flag = searchText(data[`${targetField}`].firstName, value);
                if (flag) break;
              }else if (targetField === "status") {
                flag = searchText(data[`${targetField}`].bySupervisor, value);
                if (flag) break;
              } else {
                flag = searchText(data[`${targetField}`], value);
                if (flag) break;
              }
            }
          }
          if (flag) return data
        });
        setFilteredDataApprovedList(result);
      } else {
        setFilteredDataApprovedList(allDataApprovedList);
      }

    }
  }
  useEffect(() => {
    if (!pendingApprovalListFetching && !approvalListFetching) {
      setData(pendingApprovalListData?.data);
      setFilteredData(pendingApprovalListData?.data);
      setDataApprovedList(approvalListData?.data);
      setFilteredDataApprovedList(approvalListData?.data);
    }
  }, [pendingApprovalListData?.data, approvalListData?.data]);


  const renderColumns = () => {
    return [
        {
            name: "Employee Name",
            sortable: true,
            selector: row => row?.user_id?.firstName,
        },
        // {
        //     name: "Employee No.",
        //     sortable: true,
        //     selector: row => row?.user_id?.uId,
        // },
        {
            name: "Request Date",
            sortable: true,
            cell: row => (
              <div>{`${moment(row?.requestDate).format(
                "D"
              )}-${moment(row?.requestDate).format(
                "MM"
              )}-${moment(row?.requestDate).format("YYYY")}`}</div>
            )
        },
        {
            name: "Total Amount",
            sortable: true,
            cell: row => (
              <div>{thousandAndDecimalFormat(getTotal(row?.details), true, 2)}</div>
            )
        },
        {
            name: "Status by Admin/HR",
            sortable: true,
            cell: row => (
              <div>
                {
                  (String(row.status.byHr).toLowerCase() === 'approved') && <span className="status-tag success">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'pending') && <span className="status-tag secondary">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'rejected') && <span className="status-tag danger">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'claimed') && <span className="status-tag primary">{String(row.status.byHr).toUpperCase()}</span>
                }
              </div>
            )
        },
        {
            name: 'Details',
            button: true,
            cell: row => (
                  <>
                  <Link
                    to={`/user-claim-detail/${row?.uId}`}
                    className="icon cursor-pointer"
                  >
                    <div className="underline  cursor-pointer">
                      View
                    </div>
                  </Link>
                </>
            )
        }
    ];
  };
  
  const renderColumnsApproved = () => {
    return [
        {
            name: "Employee Name",
            sortable: true,
            selector: row => row?.user_id?.firstName,
        },
        // {
        //     name: "Employee No.",
        //     sortable: true,
        //     selector: row => row?.user_id?.uId,
        // },
        {
            name: "Request Date",
            sortable: true,
            cell: row => (
              <div>{`${moment(row?.requestDate).format(
                "D"
              )}-${moment(row?.requestDate).format(
                "MM"
              )}-${moment(row?.requestDate).format("YYYY")}`}</div>
            )
        },
        {
            name: "Total Amount",
            sortable: true,
            cell: row => (
              <div>{thousandAndDecimalFormat(getTotal(row?.details), true, 2)}</div>
            )
        },
        {
            name: "Status by Supervisor",
            sortable: true,
            cell: row => (
              <div>
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'approved') && <span className="status-tag success">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'pending') && <span className="status-tag secondary">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'rejected') && <span className="status-tag danger">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
                {
                  (String(row.status.bySupervisor).toLowerCase() === 'claimed') && <span className="status-tag primary">{String(row.status.bySupervisor).toUpperCase()}</span>
                }
              </div>
            )
        },
        {
            name: "Status by Admin/HR",
            sortable: true,
            cell: row => (
              <div>
                {
                  (String(row.status.byHr).toLowerCase() === 'approved') && <span className="status-tag success">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'pending') && <span className="status-tag secondary">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'rejected') && <span className="status-tag danger">{String(row.status.byHr).toUpperCase()}</span>
                }
                {
                  (String(row.status.byHr).toLowerCase() === 'claimed') && <span className="status-tag primary">{String(row.status.byHr).toUpperCase()}</span>
                }
              </div>
            )
        },
        {
            name: 'Details',
            button: true,
            cell: row => (
                  <>
                  <Link
                    to={`/user-claim-historyDetail/${row?.uId}`}
                    className="icon cursor-pointer"
                  >
                    <div className="underline  cursor-pointer">
                      View
                    </div>
                  </Link>
                </>
            )
        }
    ];
  };
  return (
    <div className="approval-claims-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="approval-list flex flex-col">
          <div
            className={`table-expend flex items-center w-full justify-between`}
            onClick={(e) => setOpenTable(!openTable)}
          >
            <div className="page-tag s18 b6">My Pending Approvals</div>
            <div className="icon cursor-pointer flex items-center justify-center">
              {openTable ? <DropDownIcon /> : <DropUpIcon />}
            </div>
          </div>
          <div
            className={`table-minimize flex flex-col w-full ${
              openTable ? "" : "tblShow"
            }`}
          >
            <div className="page-hdr flex justify-end">
              <div className="search-box flex aic">
                <input
                  type="text"
                  className="txt cleanbtn cursor-pointer"
                  placeholder="Search here"
                  onChange={(event) =>handleSearch(event, "pending")}
                />
                <div className="icon flex aic jc">
                  <SearchIcon />
                </div>
              </div>
            </div>
            <div className="table-blk flex">
              <div className="tbl flex flex-col">
                {!pendingApprovalListLoading && !pendingApprovalListFetching ? (
                  <>
                    {filteredData?.length > 0 ? (
                      <DataTable
                        columns={renderColumns()}
                        data={filteredData}
                        responsive={true}
                        pagination={true} />
                    ) : filteredData?.length == 0 ? (
                      <div className="flex items-center justify-center h-52 text-base font-medium">
                        Data not found
                      </div>
                    ) : null}
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="approval-history flex flex-col mt-6">
          <div className="page-hdr flex justify-between">
            <div className="page-tag s18 b6">Approvals History</div>
            <div className="search-box flex aic">
              <input
                type="text"
                className="txt cleanbtn cursor-pointer"
                placeholder="Search here"
                onChange={(event) =>handleSearch(event, "list")}
              />
              <div className="icon flex aic jc">
                <SearchIcon />
              </div>
            </div>
          </div>
          <div className="table-blk flex">
            <div className="tbl flex flex-col">
              {!approvalListLoading && !approvalListFetching ? (
                <>
                  {filteredDataApprovedList?.length > 0 ? (
                    
                    <DataTable
                        columns={renderColumnsApproved()}
                        data={filteredDataApprovedList}
                        responsive={true}
                        pagination={true} />
                  ) : filteredDataApprovedList?.length == 0 ? (
                    <div className="flex items-center justify-center h-52 text-base font-medium">
                      Data not found
                    </div>
                  ) : null}
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserClaimsApprovalList;
