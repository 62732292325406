import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const generalService = createApi({
  reducerPath: "corporate",
  tagTypes: "general",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Get the Options List
      get: builder.query({
        query: () => {
          return {
            url: "/api/auth/options",
            method: "GET",
          };
        },
        providesTags: ["general"],
      }),

      //   Get the Options List
      getOptions: builder.query({
        query: () => {
          return {
            url: "/api/auth/options",
            method: "GET",
          };
        },
        providesTags: ["general"],
      }),

      //   Get the corporate information
      getCorporate: builder.query({
        query: () => {
          return {
            url: "/api/corporate",
            method: "GET",
          };
        },
        providesTags: ["general"],
      }),

      //   Get the Current Login User Info
      fetchUserInfo: builder.query({
        query: (uId) => {
          return {
            url: `/api/user/${uId}`,
            method: "GET",
          };
        },
        providesTags: ["general"],
      }),

      //   Get Countries List
      getCountry: builder.query({
        query: () => {
          return {
            url: "/api/csc",
            method: "GET",
          };
        },
        providesTags: ["general"],
      }),

      //   Get States List
      getState: builder.query({
        query: (cId) => {
          return {
            url: `/api/csc?country_code=${cId}`,
            method: "GET",
          };
        },
        providesTags: ["general"],
      }),

      //   Get Cities List
      getCity: builder.query({
        query: ({ countryCode, stateCode }) => {
          return {
            url: `/api/csc?country_code=${countryCode}&state_code=${stateCode}`,
            method: "GET",
          };
        },
        providesTags: ["general"],
      }),
    };
  },
});

export const {
  useGetQuery,
  useGetOptionsQuery,
  useGetCorporateQuery,
  useFetchUserInfoQuery,
  useGetCountryQuery,
  useGetStateQuery,
  useGetCityQuery,
} = generalService;

export default generalService;
