import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { DropDownIcon } from "../../assets";
import { useCreateMutation } from "../../store/services/departmentsService";

const CreateNewDepartment = () => {
  const navigate = useNavigate();
  const [hide, setHide] = useState(false);
  const [selectedCompany, setselectedcompany] = useState();
  const [statusData, setStatusData] = useState([
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "In-Active", value: "inActive" },
  ]);
  useEffect(() => {
    document.addEventListener("click", () => {
      setHide(false);
    });
  }, []);

  const [createDepart, setCreateDepart] = useState({
    name: "",
    description: "",
    status: "active",
  });
  const handleInput = (e) => {
    setCreateDepart({ ...createDepart, [e.target.name]: e.target.value });
  };

  const [saveDepart, data] = useCreateMutation();
  const errors = data?.error?.data ? data?.error?.data.message : null;


  const createDepartment = (e) => {
    e.preventDefault();
    saveDepart(createDepart);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/department");
    }
  }, [data?.isSuccess]);

  return (
    <form onSubmit={createDepartment} className="new-department flex">
      <div className="wrap wrapWidth flex flex-col">
        {errors !== null && (
          <div className="flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        <div className="row">
          <div className="field flex flex-col">
            <div className="lbl">Name</div>
            <input
              id="name"
              name="name"
              type="text"
              className="txt"
              placeholder="Name"
              value={createDepart.name}
              onChange={handleInput}
            />
          </div>
          <div className="field flex flex-col">
            <div className="lbl">Status</div>
            <div className="dropDown flex aic flex-col rel ">
              <div className="category flex aic">
                <div
                  className="cbox cleanbtn flex aic rel pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setHide(!hide);
                  }}
                >
                  <div className="slt flex aic">
                    <div className="unit-name flex aic font s14 b4">
                      <span
                        className="unit-eng flex aic font s14 b4"
                        placeholder="Status"
                      >
                        {selectedCompany ? selectedCompany : "Active"}
                      </span>
                    </div>
                  </div>

                  <div>
                    <DropDownIcon />
                  </div>
                </div>
              </div>
              <div className={`block flex aic abs ${hide ? "show" : ""}`}>
                <div className="manue flex aic col anim">
                  {statusData.map((item, index) => (
                    <div
                      key={index}
                      className="slt flex aic"
                      onClick={(e) => {
                        setHide(!hide);
                        setselectedcompany(item.title);
                        setCreateDepart({
                          ...createDepart,
                          status: item.value,
                        });
                      }}
                    >
                      <div className="unit-name flex aic font s14 b4">
                        <span className="unit-eng flex aic font s14 b4">
                          {item.title}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field flex flex-col pt-6">
          <div className="lbl">Description</div>
          <textarea
            id="description"
            name="description"
            type="text"
            className="txt h-[140px]"
            placeholder="Description"
            value={createDepart.description}
            onChange={handleInput}
          />
        </div>
        <div className="action flex aic justify-end">
          <input
            type="submit"
            value={data.isLoading ? "Loading..." : "Create"}
            className="btn button"
          />
        </div>
      </div>
    </form>
  );
};

export default CreateNewDepartment;
