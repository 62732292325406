import React from "react";

function ReportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="35"
      fill="none"
      viewBox="0 0 36 35"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.43 3.5A3.5 3.5 0 007.93 7v21a3.5 3.5 0 003.5 3.5h14a3.5 3.5 0 003.5-3.5V12.975a3.5 3.5 0 00-1.026-2.475L21.93 4.526A3.5 3.5 0 0019.455 3.5H11.43zm3.5 17.5a1.75 1.75 0 00-3.5 0v5.25a1.75 1.75 0 003.5 0V21zm3.5-5.25a1.75 1.75 0 011.75 1.75v8.75a1.75 1.75 0 01-3.5 0V17.5a1.75 1.75 0 011.75-1.75zm7-1.75a1.75 1.75 0 00-3.5 0v12.25a1.75 1.75 0 003.5 0V14z"
        clipRule="evenodd"
        opacity="0.6"
      ></path>
    </svg>
  );
}

export default ReportIcon;
