import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import Loader from "../Loader";
import { useGetQuery } from "../../store/services/generalService";
import { useCreateNewBankMutation } from "../../store/services/bankAccountService";

const CreateNewBankAc = () => {
  const navigate = useNavigate();
  const { data: options, isLoading } = useGetQuery();
  const [selectedBank, setSelectedBank] = useState(null);
  const [bankList, setBankList] = useState([]);
  

  const [createBank, setCreateBank] = useState({
    bankOf: "",
    accountName: "",
    accountNo: "",
  });
  const handleInput = (e) => {
    setCreateBank({ ...createBank, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!isLoading) {
      setBankList(options?.data.bankList);
    }
  }, [options?.data?.bankList]);

  useEffect(() => {
    setCreateBank({ ...createBank, bankOf: selectedBank?.value });
  }, [selectedBank]);

  const [saveBank, data] = useCreateNewBankMutation();
  const errors = data?.error?.data ? data?.error?.data.message : null;

  const createBankAccount = (e) => {
    e.preventDefault();
    saveBank(createBank);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/bank-account");
    }
  }, [data?.isSuccess]);

  return (
    <form onSubmit={createBankAccount} className="new-department flex">
      <div className="wrap wrapWidth flex flex-col">
        {!isLoading ? (
          <>
            {errors !== null && (
              <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
                {errors}
              </div>
            )}
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Bank</div>
                <Select
                  defaultValue={selectedBank}
                  onChange={setSelectedBank}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={bankList}
                  className="select"
                  id="bankOf"
                  name="bankOf"
                  placeholder="Select Bank"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Account Name</div>
                <input
                  id="accountName"
                  name="accountName"
                  type="text"
                  className="txt"
                  placeholder="Account Name"
                  value={createBank.accountName}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Account No.</div>
                <input
                  id="accountNo"
                  name="accountNo"
                  type="text"
                  className="txt"
                  placeholder="00-00000000000000"
                  value={createBank.accountNo}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Create"}
                className="btn button"
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </form>
  );
};

export default CreateNewBankAc;
