import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const componentService = createApi({
  reducerPath: "component",
  tagTypes: "compo",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create new Component
      createComponent: builder.mutation({
        query: (saveComponent) => {
          return {
            url: "/api/component",
            method: "POST",
            body: saveComponent,
          };
        },
        invalidatesTags: ["compo"],
      }),

      //   Delete a Component
      deleteComponent: builder.mutation({
        query: (uId) => {
          return {
            url: `/api/component/${uId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["compo"],
      }),

      //   Update the Component
      updateComponent: builder.mutation({
        query: (data) => {
          return {
            url: `/api/component/${data.uId}`,
            method: "PUT",
            body: data.createComponent,
          };
        },
        invalidatesTags: ["compo"],
      }),

      //   Get the List of Components
      getComponentList: builder.query({
        query: () => {
          return {
            url: "/api/component",
            method: "GET",
          };
        },
        providesTags: ["compo"],
      }),

      //   Get the Single Component Data
      fetchComponent: builder.query({
        query: (uId) => {
          return {
            url: `/api/component/${uId}`,
            method: "GET",
          };
        },
        providesTags: ["compo"],
      }),
    };
  },
});

export const {
  useGetComponentListQuery,
  useCreateComponentMutation,
  useDeleteComponentMutation,
  useFetchComponentQuery,
  useUpdateComponentMutation,
} = componentService;

export default componentService;
