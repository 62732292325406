import React, { useState, useEffect } from "react";
import { Link, Redirect, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { getVersion } from "../utils/common";
import logowhite from "../assets/logoblack.png";

import {
  setUserInfo,
  setUserId,
  setUserRole,
  setUserToken,
  setUserOrgId,
} from "../store/reducers/authReducer";

const SelectOrg = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userRole, userToken, userOrgId, userId, orgList } = useSelector(
    (state) => state.authReducer
  );

  const [errors, setErrors] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState({
    corpName: "",
    corpId: "",
  });

  useEffect(() => {
    if (selectedOrg.corpId !== "") {
      localStorage.setItem("user-orgid", selectedOrg?.corpId);
      dispatch(setUserOrgId(selectedOrg?.corpId));
    }
  }, [selectedOrg]);

  const sendGetRequest = async () => {
    if (selectedOrg?.corpId !== "") {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/access/organization`,
          {
            headers: {
              authorization: userToken,
              orgid: selectedOrg?.corpId,
            },
          }
        );

        if (resp?.statusText == "OK") {
          localStorage.setItem("user-info", JSON.stringify(resp?.data?.data));
          localStorage.setItem("user-role", resp?.data?.data.roles[0]);
          localStorage.setItem("user-uId", resp?.data?.data.uId);
          localStorage.setItem("user-userId", resp?.data?.data.userId);
          dispatch(setUserInfo(resp?.data?.data));
          dispatch(setUserRole(resp?.data?.data.roles[0]));
          dispatch(setUserId(resp?.data?.data.uId));
          window.location.replace("/");
          // navigate("/");
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
        setErrors(err);
      }
    } else {
      setErrors("Please select any one organization");
    }
  };

  return (
    <form className="login-p flex flex-col aic w-full">
      <div className="wrapper wrapWidth flex flex-col items-center">
        <div className="logo">
          <img src={logowhite} className="img" width={85} height={85}/>
          <span style={{fontSize: "14px", fontWeight: "bold"}}>Version {getVersion()} </span>
        </div>
        {errors !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        {orgList ? (
          <div className="form-block flex flex-col bg-white p-6 rounded-lg">
            <div className="title font s22 b5 c000 text-start">
              Select Organization
            </div>
            <div className="feild flex flex-col mb-5">
              <Select
                defaultValue={selectedOrg}
                value={selectedOrg}
                onChange={setSelectedOrg}
                getOptionLabel={(option) => option?.corpName}
                getOptionValue={(option) => option?.corpId}
                options={orgList}
                className="select"
                id="Organization"
                name="Organization"
                placeholder="Select Organization"
              />
            </div>

            <div className="feild btn flex flex-col mb-2">
              {/* <input
                type="submit"
                className="btn button"
                // disabled={response.isLoading ? true : false}
                value={"Login"}
              /> */}
              <div
                className="btn button"
                onClick={(e) => {
                  sendGetRequest();
                }}
              >
                Login
              </div>
            </div>
            <Link to="/" className="font s14 c68 hover:underline">
              Back
            </Link>
          </div>
        ) : null}
      </div>
    </form>
  );
};

export default SelectOrg;
