import * as XLSX from "xlsx";
import packageJson from "../../package.json";

export const thousandAndDecimalFormat = (data, allowDecimal, decimalScale) => {
  let decimalPoint = allowDecimal ? (decimalScale ? decimalScale : 0) : 0;
  return (isNaN(data)) ? Number.parseFloat(0).toFixed(decimalPoint).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : Number.parseFloat(data).toFixed(decimalPoint).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const downloadFile = (title, type, file) => {
  const url = window.URL.createObjectURL(new Blob([file], { type: `application/${type}` }));
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = title;
  link.href = url
  link.click();
}

export const exportXlsx = (values, colWidth, sheetName, downloadName) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(values);

  ws["!cols"] = colWidth;

  XLSX.utils.book_append_sheet(wb, ws, sheetName ? sheetName : "Sheet1");
  XLSX.writeFile(wb, `${downloadName ? downloadName : "download"}.xlsx`)
}

export const getVersion = () => {
  return packageJson?.version ? packageJson.version : "1.0.0";
}