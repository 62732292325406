import React, { useState, useEffects } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  SettingIcon,
  DropDownIcon,
  DropUpIcon,
  UserIcon,
  PayrollIcon,
  ReportIcon,
  LogoutIcon,
} from "../../assets";
import Sidebar from "./Sidebar/Sidebar";
import { getVersion } from "../../utils/common";
import logowhite from "../../assets/logowhite.png";
import { logout } from "../../store/reducers/authReducer";

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expend, setExpand] = useState("Settings");
  const navBarItems = [
    {
      title: "Settings",
      icon: <SettingIcon />,
      arrowDown: <DropDownIcon />,
      arrowUp: <DropUpIcon />,
      submenu: [
        {
          subTitle: "Company",
          arrowDown: <DropDownIcon />,
          arrowUp: <DropUpIcon />,
          child: [
            { lbl: "General", slug: "General" },
            { lbl: "Department", slug: "Department" },
            { lbl: "Bank account", slug: "BankAccount" },
            { lbl: "CPF account", slug: "CPF-account" },
          ],
        },
        {
          subTitle: "Component",
          arrowDown: <DropDownIcon />,
          arrowUp: <DropUpIcon />,
          child: "",
        },
      ],
      slug: "settings",
    },
  ];
  const ReFreshPage = () => {
    window.location.reload();
  };

  const userlogout = () => {
    dispatch(logout());
    navigate("/");
  }

  return (
    <div className="navbar flex jc">
      <div className="wrap flex  flex-col">
        <Link to="/" className="logo" style={{textAlign: "center"}}>
          <img src={logowhite} className="cursor-pointer"  width={60} height={60} style={{margin: "auto"}} />
          <span style={{fontSize: "12px", fontWeight: "600", color: "white", marginTop: "5px"}}>Version {getVersion()} </span>
          {/* <img src={logo} className="logo-img cursor-pointer"/> */}
        </Link>
        <div className="nav-list flex flex-col flex-1">
          <Sidebar />
          {/* <div className={`list-item flex flex-col`}>
            <div className="select flex aic">
              <div className="tab-icon">
                <SettingIcon />
              </div>
              <div className="lbl">Settings</div>
              <div className="arrow-icon">
                <DropDownIcon />
              </div>
            </div>
            <div className="sub-menu flex flex-col">
              <div className="select flex aic">
                <div className="lbl">Company</div>
                <div className="arrow-icon">
                  <DropDownIcon />
                </div>
              </div>
              <div className="sub-menu-items flex flex-col">
                <div></div>
              </div>
              <div className="select flex aic">
                <div className="lbl">Component</div>
                <div className="arrow-icon">
                  <DropDownIcon />
                </div>
              </div>
            </div>
            <div className="select flex aic">
              <div className="tab-icon">
                <UserIcon />
              </div>
              <div className="lbl">Users</div>
            </div>
            <div className="select flex aic">
              <div className="tab-icon">
                <PayrollIcon />
              </div>
              <div className="lbl">Payroll</div>
            </div>
            <div className="select flex aic">
              <div className="tab-icon">
                <ReportIcon />
              </div>
              <div className="lbl">Report</div>
              <div className="arrow-icon">
                <DropDownIcon />
              </div>
            </div>
          </div> */}
        </div>
        <div className="logout-btn flex">
          <div className="btn-out flex aic" onClick={() => userlogout()}>
            <div className="ico">
              <LogoutIcon />
            </div>
            <div className="out-lbl">Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
