import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import StatutoryFundsDetail from "./StatutoryFundsDetail";
import NetPayDetail from "./NetPayDetail";
import PaymentInformation from "./PaymentInformation";
import PayrollProcess from "./PayrollProcess";
import ExecutedSuccesfully from "./ExecutedSuccesfully";
import Payslip from "./Payslip";

import Modal from "../Modal";
import {
  SearchIcon,
  EditIconPy,
  UserAddIcon,
  DownloadIconPy,
  CalendarIcon,
  AddIcon,
  DropDownIcon,
  DelIconPy,
  ExportIcon,
} from "../../assets";

import {
  useExecutePayrollUsersMutation,
  useRemoveUserMutation,
} from "../../store/services/payrollService";
import { thousandAndDecimalFormat, downloadFile } from "../../utils/common";
import { exportPayslip } from "../../store/services/export.service";
import DataTable from "react-data-table-component";

const SalarySummary = ({ data, uId, periodStart, periodEnd, pyDetails, payDate }) => {
  const [open, setOpen] = useState(false);

  const [displayData, setDisplayData] = useState([]);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [payInfoIsEdit, setPayInfoIsEdit] = useState(false);
  const [selectStatus, setSelectStatus] = useState("all");
  const [checkedState, setCheckedState] = useState([]);
  const [allCheckState, setAllCheckedState] = useState(false);
  const [selectedData, setSelectData] = useState([]);
  const [pyDetailData, setPyDetailData] = useState(false);
  const [haveCheck, setHaveCheck] = useState(false);
  const [setColor, setBtnColour] = useState({
    executeBtn : '#d4d4d4',
    paidBtn : '#d4d4d4'
  });

  const [editData, setEditData] = useState({
    uId: "",
    detail_id: "",
  });

  useEffect(() => {
    setUsersIdsList({
      ...usersIdsList,
      uId: uId,
    });
    setEditData({
      ...editData,
      uId: uId,
    });
  }, [uId]);

  useEffect(() => {
    if (data) {
      setCheckedState(new Array(data.length).fill(false))
      const dataSet = data.filter((target) => {
        return String(selectStatus).toLowerCase() === "all" ? true : String(target.status).toLowerCase() === String(selectStatus).toLowerCase()
      });
      setDisplayData(dataSet)
    }
  }, [data, selectStatus]);
  const [disableButton, setDisableButton] = useState(false);
  const [usersIdsList, setUsersIdsList] = useState({ uId: uId, detail_id: [] });

  const handleCheckBox = (data) => {
    let dataArray = [];
    let checkedCount = 0;
    data.selectedRows.forEach((item, index) => {
        dataArray.push({
          targetId: item.payrollDetails_id,
          status: item.status
        })
        checkedCount ++;
    });
    if (checkedCount > 0) {
      setHaveCheck(true);
    } else if (checkedCount === 0) {
      setHaveCheck(false);
    }
    let draftCount = 0;
    let executeCount = 0;
    if (dataArray.length > 0) {
      dataArray.forEach((item) => {
        if (item.status === "draft") {
          draftCount ++;
        }
        if (item.status === "execute") {
          executeCount ++;
        }
      });
      setBtnColour({
        executeBtn : dataArray.length === draftCount ? '#4e7a7d' : '#d4d4d4',
        paidBtn : dataArray.length === executeCount ? '#4e7a7d' : '#d4d4d4'
      })
    } else {
      setBtnColour({
        executeBtn : '#d4d4d4',
        paidBtn : '#d4d4d4'
      })
    }
    setSelectData(dataArray)
  };

  const [updateExecuteUser, response] = useExecutePayrollUsersMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;
  const [errorMessage, setErrorMessage] = useState(null);
  const [executeMessage, setExecuteMessage] = useState({
    startPeriod: periodStart,
    endPeriod: periodEnd,
    response: "successfully"
  });

  const populateSelectedData = (targetData, isEditAble) => {
    setPayInfoIsEdit(isEditAble);
    setSelectData([targetData])
    let foundDetailData = {}
    pyDetails.forEach(element => {
      if (element.id === targetData) {
        foundDetailData = element
      }
    })
    setPyDetailData(foundDetailData);

  }
  
  const updateExecutePayroll = (e, targetData) => {
    if (targetData === "selected") {
      let idSets = [];
      selectedData.forEach((item) => {
        idSets.push(item.targetId)
      });
      updateExecuteUser({
        uId: uId,
        detail_id: idSets,
      });
    } else {
      updateExecuteUser({
        uId: uId,
        detail_id: [targetData],
      });
    }
  };


  const [removeExecuteUser, res] = useRemoveUserMutation();

  const removeUserErrors = response?.error?.data
    ? response?.error?.data.message
    : null;
  const [removeErrorMessage, setRemoveErrorMessage] = useState(null);

  const removeExecutePayroll = (targetData) => {
    removeExecuteUser({
      uId: uId,
      detail_id: targetData,
    });
  };

  const handleExportPayslip = (detail_id, title) => {
    exportPayslip({
      uId: uId,
      detail_id: detail_id
    })
    .then((res) => {
      if (res) {
        downloadFile(title, "pdf", res);
      }
    });
  }

  const renderColumns = () => {
    return [
        {
            name: "Name",
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            selector: row => row?.firstName,
            style: {
              fontSize: "10px"
            },
        },
        {
            name: "Salary",
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            style: {
              fontSize: "10px"
            },
            cell: row => (
              <div>{thousandAndDecimalFormat(row.salary, true, 2)}</div>
            )
        },
        {
            name: "Overtime",
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            style: {
              fontSize: "10px"
            },
            cell: row => (
              <div>{thousandAndDecimalFormat(row.overtime, true, 2)}</div>
            )
        },
        {
            name: 'Component',
            warp: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            style: {
              justifyContent: "center",
              fontSize: "10px"
            },
            cell: row => (
              <div className="col-item" style={{textAlign: "center"}}>
              {row?.component?.length > 0 ? 
                row?.component?.map((item, i) => (
                  <div key={i} className="flex" style={{justifyContent: "center"}}>
                    {item.name} : {thousandAndDecimalFormat(item.value, true, 2)}
                  </div>
                ))
                : 
                  "-"
                }
              </div>
            )
        },
        {
            name: "Claim",
            style: {
              fontSize: "10px"
            },
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div>{thousandAndDecimalFormat(row.claim, true, 2)}</div>
            )
        },
        {
            name: 'Deduction',
            warp: true,
            style: {
              justifyContent: "center",
              fontSize: "10px"
            },
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div className="col-item" style={{textAlign: "center"}}>
                {row?.deduction?.length > 0 ? 
                  row?.deduction?.map((item, i) => (
                    <div key={i} className="flex" style={{justifyContent: "center"}}>
                      {item.name} : {thousandAndDecimalFormat(item.value, true, 2)}
                    </div>
                  ))
                  :
                    "-"
                }
              </div>
            )
        },
        {
            name: "Employee Contributions",
            warp: true,
            style: {
              justifyContent: "center",
              fontSize: "10px"
            },
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div className="col-item" style={{textAlign: "center"}}>
                {row?.employerContribution?.length > 0 ? 
                  row?.employerContribution?.map((item, i) => (
                    <div key={i} className="flex" style={{justifyContent: "center"}}>
                      {item.name} : {thousandAndDecimalFormat(item.value, true, 2)}
                    </div>
                  ))
                  :
                  "-"
                }
                </div>
            )
        },
        {
            name: "Net Pay",
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            style: {
              fontSize: "10px",
              fontWeight: "bold"
            },
            cell: row => (
              <div>{thousandAndDecimalFormat(row.netPay, true, 2)}</div>
            )
        },
        {
            name: "Status",
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            selector: row => row?.status,
        },
        {
            name: 'Actions',
            button: true,
            width: "200px",
            cell: row => (
                  <>
                  {row.status === "draft" &&
                        <div
                          className="icon"
                          onClick={(e) => {
                            updateExecutePayroll(e, row.payrollDetails_id);
                            setOpen3(true);
                          }}
                          // onClick={(e) => setOpen(true)}
                        >
                          <div className="btn button" style={{borderRadius: "5px"}}>Execute</div>
                        </div>
                      }
                    {row.status === "execute" &&
                      <button
                        className="icon"
                        onClick={(e) => {
                          // updateExecutePayroll(e, row.payrollDetails_id);
                          
                          populateSelectedData(row.payrollDetails_id, true);
                          setOpen(true);
                        }}
                      >
                        <div className="btn button w-12" style={{borderRadius: "5px"}}>Paid</div>
                      </button>
                    }
                    
                    {row.status === "paid" ?
                        <div
                          className="icon"
                          onClick={() => {
                            populateSelectedData(row.payrollDetails_id, false);
                            setOpen(true);
                          }}
                        >
                          <ExportIcon status={row.status} />
                        </div>
                      :
                      <div className="icon" style={{cursor: "default"}}>
                        <ExportIcon status={row.status} />
                      </div>

                      }
                      {row.status !== "draft" ?
                        <div
                          className="icon"
                          onClick={() => {
                            handleExportPayslip(row.payrollDetails_id, `${row.firstName}_${payDate}`)
                            // setOpen4(true);
                            // setEditData({
                            //   ...editData,
                            //   detail_id: row.payrollDetails_id,
                            // });
                          }}
                        >
                          <DownloadIconPy status={row.status} />
                        </div>
                        :
                        <div
                          className="icon"
                          style={{cursor: "default"}}
                        >
                          <DownloadIconPy status={row.status} />
                        </div>
                      }
                      
                      {row.status === "draft" ?
                        <div
                          className="icon"
                          onClick={(e) => {
                            setOpen2(true);
                            setEditData({
                              ...editData,
                              detail_id: row.payrollDetails_id,
                            });
                          }}
                        >
                          <EditIconPy status={row.status} typePage="payroll" />
                        </div>

                      :
                        <div
                          className="icon"
                          style={{cursor: "default"}}
                        >
                          <EditIconPy status={row.status} typePage="payroll" />
                        </div>
                      }

                      {row.status === "draft" ?
                        <div
                          className="icon"
                          onClick={(e) => removeExecutePayroll(row.payrollDetails_id)}
                        >
                          <DelIconPy status={row.status} typePage="payroll" />
                        </div>
                        :
                        <div
                          className="icon"
                          style={{cursor: "default"}}
                        >
                          <DelIconPy status={row.status} typePage="payroll" />
                        </div>
                      }
                </>
            )
        }
    ];
  }
  return (
    <div className="payment-summary flex flex-col">
      <div className="filters-tabs flex aic justify-end">
        <div
          className={`tab-btn ${selectStatus === "all" ? "active" : ""}`}
          onClick={(e) => setSelectStatus("all")}
        >
          All
        </div>
        <div
          className={`tab-btn ${selectStatus === "Draft" ? "active" : ""}`}
          onClick={(e) => setSelectStatus("Draft")}
        >
          Draft
        </div>
        <div
          className={`tab-btn ${selectStatus === "Execute" ? "active" : ""}`}
          onClick={(e) => setSelectStatus("Execute")}
        >
          Execute
        </div>
        <div
          className={`tab-btn ${selectStatus === "Paid" ? "active" : ""}`}
          onClick={(e) => setSelectStatus("Paid")}
        >
          Paid
        </div>
      </div>
      <div className="salary-summary-table-blk flex flex-col">
        {data?.length > 0 ? (
          <div className="tbl flex flex-col">
            <DataTable
              columns={renderColumns()}
              data={displayData}
              responsive={true}
              selectableRows={true}
              pagination={true}
              onSelectedRowsChange={handleCheckBox}
               />
        </div>
        ) : (
          <div className="flex items-center justify-center">Data not found</div>
        )}
        {haveCheck === true ? 
        <div className="actions-pay flex aic justify-end my-10">
          <div className="btn button" style={{background: setColor.executeBtn}} onClick={(e) => updateExecutePayroll(e, "selected")}>
            Execute Payroll
          </div>
          <div className="btn button ml-4" style={{background: setColor.paidBtn}} onClick={(e) => {
            setPayInfoIsEdit(true);
            setOpen(true);
          }}>
            Paid
          </div>
        </div>
        
        : 
        
        <div className="actions-pay flex aic justify-end my-10">
          <div className="btn button" style={{background: setColor.executeBtn, cursor:"default"}}>
            Execute Payroll
          </div>
          <div className="btn button ml-4" style={{background: setColor.paidBtn, cursor:"default"}}>
            Paid
          </div>
        </div>
        }
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <PaymentInformation
          setOpen={setOpen}
          setOpen3={setOpen3}
          usersIdsList={selectedData?.map(x => x.targetId)}
          targetUidDetail={uId}
          payInfoIsEdit={payInfoIsEdit}
          pyDetailData={pyDetailData}
        />
      </Modal>
      <Modal open={open2} onClose={() => setOpen2(false)}>
        <PayrollProcess open2={open2} setOpen2={setOpen2} editData={editData} />
      </Modal>
      <Modal open={open3} onClose={() => setOpen3(false)}>
        <ExecutedSuccesfully setOpen3={setOpen3} executeMessage={executeMessage} />
      </Modal>
      <Modal open={open4} onClose={() => setOpen4(false)}>
        <div className="pay-slip-model flex flex-col">
          <Payslip setOpen4={setOpen4} editData={editData} />
        </div>
      </Modal>
    </div>
  );
};

export default SalarySummary;
