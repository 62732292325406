import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../assets";
import { useDeleteCpfAcMutation } from "../../store/services/cpfAccountService";

const CpfAccountDelete = ({ setOpen, data }) => {
  const [removeCpfAccount, response] = useDeleteCpfAcMutation();

  const deleteCPF = (uId) => {
    removeCpfAccount(uId);
  };

  useEffect(() => {
    if (response?.data?.data === "success") {
      setOpen(false);
    }
  }, [response?.data?.data === "success"]);

  return (
    <div className="depart-delete flex">
      <div className="wrap flex flex-col">
        <div className="hdr flex justify-end">
          <div
            className="icon flex aic jc cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="blk flex aic  flex-col justify-around">
          <div className="desc">
            Are you sure to delete this `{data?.accountNo}` CPF account?
          </div>
          <div className="actions flex aic jc">
            <div className="btn button" onClick={(e) => setOpen(false)}>
              Cancel
            </div>
            <div className="btn button" onClick={(e) => deleteCPF(data?.uId)}>
              Delete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CpfAccountDelete;
