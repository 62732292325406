import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const auditService = createApi({
  reducerPath: "audits",
  tagTypes: "auditsData",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {

      //   Get the List of data
      getDataList: builder.query({
        query: () => {
          return {
            url: "/api/audit",
            method: "GET",
          };
        },
        providesTags: ["auditsData"],
      }),

      //   Get the Own data
      fetchOwnData: builder.query({
        query: () => {
          return {
            url: `/api/audit/own`,
            method: "GET",
          };
        },
        providesTags: ["auditsData"],
      }),
    };
  },
});

export const {
  useGetDataListQuery,
  useFetchOwnDataQuery
} = auditService;

export default auditService;
