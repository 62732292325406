import Reactk, { useState, useEffect } from "react";
import Select from "react-select";
import { CloseIcon, SearchIcon } from "../../assets";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetUsersListQuery } from "../../store/services/usersService";
import {
  useAddUserInPayrollMutation,
  useFetchSinglePayrollDetailQuery,
} from "../../store/services/payrollService";
import { useGetDepartListQuery } from "../../store/services/departmentsService";



const AddPayrollUsers = ({ setOpen3, listData }) => {
  const navigate = useNavigate();
  const { payRollUid } = useSelector((state) => state.globalReducer);
  const {
    data: departmentsList,
    isLoading: departmentLoading,
    isFetching: departmentFetching,
  } = useGetDepartListQuery();
  const {
    data: usersList,
    isFetching: isFetchingUsersList,
    isLoading,
  } = useGetUsersListQuery();

  const { data: payrollUserDetailData, isFetching: isFetchingPayrollUser } =
    useFetchSinglePayrollDetailQuery(payRollUid);

  const [open, setOpen] = useState(false);
  const [departmentListData, setDepartmentListData] = useState([{_id: "all", name: "All"}]);
  const [allDataAdd , setDataAdd] = useState([]);
  const [filteredDataAdd , setFilteredDataAdd] = useState([]);
  const [selectNewPayrollUsers, setSelectNewPayrollUsers] = useState({
    uId: "",
    users_id: [],
  });

  useEffect(() => {
    if (!departmentFetching) {
      setDepartmentListData((prevState) => ([
        ...prevState,
        ...departmentsList?.data
      ]))
    }
  }, [departmentsList?.data]);
  const usersIdsList = selectNewPayrollUsers.users_id;
  const handleUserIds = (e) => {
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      usersIdsList.push(value);
      setSelectNewPayrollUsers({
        ...selectNewPayrollUsers,
        users_id: usersIdsList,
      });
    }

    // Case 2  : The user unchecked the box
    else {
      let newUsersIdsList = usersIdsList.filter(function (item) {
        return item !== value;
      });
      setSelectNewPayrollUsers({
        ...selectNewPayrollUsers,
        users_id: newUsersIdsList,
      });
    }
  };

  const [addNewUsersPayrollList, data] = useAddUserInPayrollMutation();
  

  const errors = data?.error?.data ? data?.error?.data.message : null;
  const [errorMessage, setErrorMessage] = useState(null);

  const addNewPayrollUsers = (e) => {
    e.preventDefault();
    if (selectNewPayrollUsers?.users_id?.length === 0) {
      setErrorMessage("Please select any user first");
    } else {
      addNewUsersPayrollList(selectNewPayrollUsers);
    }
  };

  const searchFieldIs = [
    "userName",
    "firstName",
    "lastName",
    "uId",
    "nationalId",
    "email",
    "department_id"
  ];
  const searchText = (target, value) => {
    return target.toLowerCase().indexOf(value) > -1 ? true : false;
  }
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    if (event.target.value !== "") {
      let result = [];
      result = allDataAdd.filter((data) => {
        let flag = false;
        for (let i = 0; i < searchFieldIs.length; i++) {
          const targetField = searchFieldIs[i];
          if (data[`${targetField}`]) {
            if (targetField === "department_id") {
              flag = searchText(data[`${targetField}`].name, value);
              if (flag) break;
            } else {
              flag = searchText(data[`${targetField}`], value);
              if (flag) break;
            }
          }
        }
        if (flag) return data
      });
      setFilteredDataAdd(result);
    } else {
      setFilteredDataAdd(allDataAdd);
    }
  }
  const filterByDepartment = (event) => {
    let value = event._id;
    if (value !== "all") {
      let result = [];
      result = allDataAdd.filter((data) => {
        let flag = false;
        if (data?.department_id?._id !== undefined) {
          flag = data.department_id._id === value;
        }
        if (flag) return data
      });
      setFilteredDataAdd(result);
    } else {
      setFilteredDataAdd(allDataAdd);
    }
  }
  useEffect(() => {
    if (!isFetchingPayrollUser) {
      setSelectNewPayrollUsers({
        ...selectNewPayrollUsers,
        uId: payrollUserDetailData?.data?.uId,
      });
    }
  }, [payrollUserDetailData?.data]);
  
  useEffect(() => {
    if (!isFetchingUsersList) {
      const removedExistData = usersList?.data?.filter(x => listData?.filter(l => l.user_id === x._id).length === 0);
      setDataAdd(removedExistData);
      setFilteredDataAdd(removedExistData);
    }
  }, [usersList?.data]);
  

  

  return (
    <div className="payroll-users flex">
      <div className="payroll-users-wrap flex flex-col w-full">
        {errors !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        {errorMessage !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errorMessage}
          </div>
        )}
        <div className="payroll-hdr flex items-center w-full">
          <div className="left flex items-center">
            <div className="lbl s16 b6 mr-6">Department</div>
            <Select
              // defaultValue={selectedDepart}
              // value={selectedDepart}
              onChange={(event) =>filterByDepartment(event)}
              options={departmentListData}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option._id}
              className="select"
              placeholder="Department"
            />
          </div>
          <div className="right flex items-center justify-end">
            <div className="search-box flex aic">
              <input
                type="text"
                className="txt cleanbtn cursor-pointer"
                placeholder="Search here"
                onChange={(event) =>handleSearch(event)}
              />
              <div className="icon flex aic jc">
                <SearchIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="container flex w-full">
          {!isLoading && !isFetchingUsersList ? (
            <div className="table-blk flex w-full">
              <div className="tbl flex flex-col">
                <div className="row flex aic">
                  <div className="col flex">
                    {/* <input
                      type="checkbox"
                      className="check-box h-4 w-4 cursor-pointer"
                    /> */}
                  </div>
                  <div className="col flex">
                    <div className="col-item">First Name</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Last Name</div>
                  </div>
                  {/* <div className="col flex">
                    <div className="col-item">Employee ID</div>
                  </div> */}
                  <div className="col flex">
                    <div className="col-item">National ID</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Email</div>
                  </div>
                  <div className="col flex items-end">
                    <div className="col-item">Department</div>
                  </div>
                </div>
                {filteredDataAdd.map((item, index) => (
                  <div key={index} className="row flex aic">
                    <div className="col flex">
                      <input
                        type="checkbox"
                        value={item._id}
                        onChange={handleUserIds}
                        className="check-box cursor-pointer h-4 w-4"
                      />
                    </div>
                    <div className="col flex">
                      <div className="col-item">{item.firstName}</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">{item.lastName}</div>
                    </div>
                    {/* <div className="col flex">
                      <div className="col-item">{item.uId}</div>
                    </div> */}
                    <div className="col flex">
                      <div className="col-item">{item.nationalId}</div>
                    </div>
                    <div className="col flex">
                      <div className="col-item">{item.email}</div>
                    </div>
                    <div className="col flex items-end">
                      <div className="col-item">
                        {item?.department_id ? item?.department_id?.name : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
        <div className="action flex items-center justify-end">
          <div className="btn button" onClick={(e) => addNewPayrollUsers(e)}>
            Confirm
          </div>
          <div className="btn button" onClick={(e) => setOpen3(false)}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPayrollUsers;
