import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modal";
import { EditIcon, DelIcon } from "../../assets";
import BankAccountDelete from "../../components/AdminSide/BankAccountDelete";
import Loader from "../../components/Loader";
import DataTable from "react-data-table-component";
import DepartmentDelete from "../../components/AdminSide/DepartmentDelete";
import {
  useGetQuery,
  useDeleteDepartmentMutation,
} from "../../store/services/bankAccountService";
import { useGetOptionsQuery } from "../../store/services/generalService";

const BankAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { data: options, isLoading: optionsIsLoading } = useGetOptionsQuery();

  const { data, isLoading, isFetching } = useGetQuery();

  const [deleteBankId, setDeleteBankId] = useState("");

  const BankName = (bankValue) => {
    var newArray = options?.data?.bankList?.filter(function (el) {
      return el.value == bankValue;
    });

    return newArray? newArray[0]?.display : "";
  };

  const renderColumns = () => {
    return [
        {
            name: "Bank",
            sortable: true,
            cell: row => (
              <div>{BankName(row?.bankOf)}</div>
            ),
        },
        {
            name: "Account Name",
            sortable: true,
            selector: row => row?.accountName,
        },
        {
            name: "Account No",
            sortable: true,
            selector: row => row?.accountNo,
        },
        {
            name: 'Actions',
            button: true,
            cell: row => (
                <>
                  <a
                    href={`/update-bank-account/${row.uId}`}
                    className="icon cursor-pointer"
                  >
                    <EditIcon />
                  </a>
                  <div
                    className="icon cursor-pointer"
                    onClick={(e) => {
                      setOpen(true);
                      setDeleteBankId({
                        uId: row?.uId,
                        name: row?.accountName,
                      });
                    }}
                  >
                    <DelIcon />
                  </div>
                </>
            )
        }
    ];
  };
  return (
    <div className="bank-account-page flex">
      <div className="wrap wrapWidth flex flex-col">
        {!isLoading || !isFetching ? (
          <div className="table-blk flex">
            <div className="tbl flex flex-col">
              <div className="row flex aic">
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item">
                    <a href="/new-bank-account" className="btn button">
                      New
                    </a>
                  </div>
                </div>
              </div>
          <DataTable
              columns={renderColumns()}
              data={data?.data}
              responsive={true}
              pagination={true} />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <BankAccountDelete setOpen={setOpen} data={deleteBankId} />
      </Modal>
    </div>
  );
};

export default BankAccount;
