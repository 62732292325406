import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Datetime from "react-datetime";
import { DropDownIcon, CalendarIcon, AttachmentIcon, EyeIcon } from "../../assets";

import Loader from "../../components/Loader";
import {
  useFetchClaimDetailDataQuery,
  useUpdateClaimsDetailMutation,
} from "../../store/services/claimsService";

const EditUserClaim = () => {
  const [date, setDate] = useState(new Date().getTime());

  const navigate = useNavigate();
  const { uId } = useParams();
  const { data, isFetching, isLoading } = useFetchClaimDetailDataQuery(uId);

  const [file, setFile] = useState();

  const [createClaimForm, setCreateClaimForm] = useState({
    receiptDate: "",
    amount: 0,
    description: "",
    proof: "",
  });
  const handleInput = (e) => {
    setCreateClaimForm({ ...createClaimForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setCreateClaimForm({ ...createClaimForm, proof: file });
  }, [file]);

  useEffect(() => {
    if (!isFetching) {
      setCreateClaimForm(data?.data);
    }
  }, [data?.data]);

  const [updateClaimsDetail, response] = useUpdateClaimsDetailMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;

  const claimDetailUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("receiptDate", createClaimForm.receiptDate);
    formData.append("amount", createClaimForm.amount);
    formData.append("description", createClaimForm.description);
    if (file) {
      formData.append("proof", file);
    }
    updateClaimsDetail({ formData, uId });
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/new-claims-form");
    }
  }, [response?.isSuccess]);

  return (
    <div className="add-new-claim flex">
      <form onSubmit={claimDetailUpdate} className="add-new-claim flex">
        <div className="wrap wrapWidth flex flex-col">
          {errors !== null && (
            <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
              {errors}
            </div>
          )}
          <div className="row">
            <div className="field flex flex-col">
              <div className="lbl">Reciept Date</div>
              <div className="date-picker flex aic jc">
                <Datetime
                  closeOnSelect={true}
                  value={
                    createClaimForm.receiptDate
                      ? new Date(createClaimForm.receiptDate).getTime()
                      : new Date().getTime()
                  }
                  onChange={(value) => {
                    setDate(new Date(value).getTime());
                    setCreateClaimForm({
                      ...createClaimForm,
                      receiptDate: new Date(value).getTime(),
                    });
                  }}
                  timeFormat={false}
                  dateFormat="DD-MM-YYYY"
                  className="start-date cleanbtn"
                />
                <div className="ico">
                  <CalendarIcon />
                </div>
              </div>
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Amount</div>
              <input
                type="number"
                name="amount"
                value={createClaimForm.amount}
                onChange={handleInput}
                className="txt"
                placeholder="Amount"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Proof</div>
              <div className="txt flex aic justify-between">
                <input
                  type="file"
                  name="proof"
                  onChange={(e) => {
                    let file = e.target.files[0];
                    setFile(file);
                  }}
                  className="cleanbtn w-full cursor-pointer"
                  placeholder="Proof"
                />
                <div className="ico">
                  <AttachmentIcon />
                </div>
                {(!file && createClaimForm.proof && createClaimForm.proof !== "undefined") && (
                    <a href={(createClaimForm.proof)} target="_blank">
                      <EyeIcon />
                    </a>
                )}
              </div>
            </div>
          </div>
          <div className="field flex flex-col pt-6">
            <div className="lbl">Description</div>
            <textarea
              type="text"
              name="description"
              value={createClaimForm.description}
              onChange={handleInput}
              className="txt h-[140px]"
              placeholder="Description"
            />
          </div>
          <div className="action flex aic justify-end">
            <Link to="/new-claims-form" className="btn button mr-8">
              Cancel
            </Link>
            <input
              type="submit"
              value={response.isLoading ? "Loading..." : "Update"}
              className="btn button"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditUserClaim;
