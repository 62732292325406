import React, { useState, useEffect } from "react";
import { SearchIcon } from "../../assets";
import Loader from "../../components/Loader";
import moment from "moment";
import DataTable from 'react-data-table-component';
import {
  useGetDataListQuery,
} from "../../store/services/activityService";

const ActivityLog = () => {
  const {
    data: logList,
    isFetching: isFetchinglogList,
    isLoading,
  } = useGetDataListQuery();
  const pageIs = "hr"
  const [open, setOpen] = useState(false);
  const [revokeId, setRevokeId] = useState("");
  const [allData , setData] = useState([]);
  const [filteredData , setFilteredData] = useState([]);

  const searchFieldIs = [
    "date",
    "status",
    "type",
    "subject",
    "operator",
    "description",
  ];
  const searchText = (target, value) => {
    return target.toLowerCase().indexOf(value) > -1 ? true : false;
  }
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    if (event.target.value !== "") {
      let result = [];
      result = allData.filter((data) => {
        let flag = false;
        for (let i = 0; i < searchFieldIs.length; i++) {
          const targetField = searchFieldIs[i];
          if (data[`${targetField}`]) {
            if (targetField === "operator") {
              flag = searchText(data[`${targetField}`].username, value);
              if (flag) break;
            } else {
              flag = searchText(data[`${targetField}`], value);
              if (flag) break;
            }
          }
        }
        if (flag) return data
      });
      setFilteredData(result);
    } else {
      setFilteredData(allData);
    }
  }

  useEffect(() => {
    if (!isFetchinglogList) {
      setData(logList?.data);
      setFilteredData(logList?.data);
    }
  }, [logList?.data]);
  const renderColumns = () => {
    return [
        {
            name: "Date",
            sortable: true,
            cell: row => (
              <div>
                {
                moment(row.date).format("DD-MM-YYYY HH:mm:ss")
                }
              </div>
            ),
        },
        {
            name: "Status",
            sortable: true,
            cell: row => (
              <div>
                {
                  (String(row.status).toLowerCase() === 'success') && <span className="status-tag success">{String(row.status).toUpperCase()}</span>
                }
                {
                  (String(row.status).toLowerCase() === 'failed') && <span className="status-tag danger">{String(row.status).toUpperCase()}</span>
                }
              
              </div>
            )
        },
        {
            name: "Type",
            sortable: true,
            selector: row => row.type
        },
        {
            name: "Subject",
            sortable: true,
            selector: row => row.subject
        },
        {
            name: "User",
            sortable: true,
            cell: row => (
              <div>
                {row.operator.userName}
              </div>
            )
        },
        {
            name: "Description",
            wrap: true,
            sortable: true,
            grow: 3,
            selector: row => row.description
        }
    ];
  };
  return (
    <div className="users-page claim-list felx">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-hdr flex justify-end">
          <div className="search-box flex aic">
            <input
              type="text"
              className="txt cleanbtn cursor-pointer"
              placeholder="Search here"
              onChange={(event) =>handleSearch(event)}
            />
            <div className="icon flex aic jc">
              <SearchIcon />
            </div>
          </div>
        </div>
        {!isLoading && !isFetchinglogList ? (
          
          <DataTable
            columns={renderColumns()}
            data={filteredData}
            responsive={true}
            pagination={true} />

        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ActivityLog;
