import React from "react";
import { CloseIcon, SuccessIcon } from "../../assets";

const ExecutedSuccesfully = ({ setOpen3, executeMessage }) => {
  return (
    <div className="depart-delete flex">
      <div className="wrap flex flex-col">
        <div className="hdr flex justify-end">
          <div
            className="icon flex aic jc cursor-pointer"
            onClick={(e) => setOpen3(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="blk flex aic  flex-col">
          <div className="icon">
            <SuccessIcon />
          </div>
          <div className="desc flex flex-col s16 b4">
            <div>Payroll for period</div>
            <div className="s20 b6">{executeMessage.startPeriod} to {executeMessage.endPeriod}</div>
            <div>executed {executeMessage.response}.</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExecutedSuccesfully;
