import React from "react";

function DropDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="6"
      fill="none"
      viewBox="0 0 11 6"
    >
      <path fill="#D7D7D7" d="M.516.52l5 4.971 5-4.971"></path>
    </svg>
  );
}

export default DropDownIcon;
