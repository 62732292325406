import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Pdf from "react-to-pdf";

import Loader from "../Loader";
import {
  useFetchSinglePayrollProcQuery,
  useFetchPayslipQuery,
} from "../../store/services/payrollService";
import { useFetchSingleUserQuery } from "../../store/services/usersService";
import { useGetCorporateQuery } from "../../store/services/generalService";
import { thousandAndDecimalFormat } from "../../utils/common";

const Payslip = ({ editData }) => {
  const ref = React.createRef();
  const [paySlipData, setPaySlipData] = useState();
  const { userId } = useSelector((state) => state.authReducer);
  const { payrollLabel, workPeriod } = useSelector(
    (state) => state.globalReducer
  );

  const { data: payrollProcessData, isFetching: payRollProcessIsFetching } =
    useFetchPayslipQuery({
      uId: editData?.uId,
      detail_id: editData?.detail_id,
    });

  const { data: payrollProcessData2, isFetching: payRollProcessIsFetching2 } =
    useFetchSinglePayrollProcQuery({
      uId: editData?.uId,
      detail_id: editData?.detail_id,
    });

  const { data: corporateData, isFetching: corporateIsFetching } =
    useGetCorporateQuery();

  const { data: singleUserData, isFetching: singleUserDataFetching } =
    useFetchSingleUserQuery(userId);

  useEffect(() => {
    if (!payRollProcessIsFetching) {
      setPaySlipData(payrollProcessData?.data);
    }
  }, [payrollProcessData?.data]);
  return (
    <div className="payslip-page flex flex-col">
      <div className="action flex">
        <Pdf targetRef={ref} filename="Pay-slip.pdf">
          {({ toPdf }) => (
            <div className="btn button rounded-xl" onClick={toPdf}>
              Print
            </div>
          )}
        </Pdf>
      </div>
      {!payRollProcessIsFetching ? (
        <div ref={ref} className="wrap flex flex-col justify-between">
          <div className="page-container flex flex-col w-full">
            <div className="meta flex flex-col w-full">
              <div className="page-hdr flex">
                <div className="page-tag">Payslip</div>
              </div>
              <div className="company-name">
                {corporateData?.data ? corporateData?.data?.corpName : "-"}
              </div>
              <div className="company-infos flex aic">
                <div className="comp-left flex flex-col">
                  
                  <div style={{fontWeight: "700"}}>Address :</div>
                  {paySlipData?.companyInfo?.address?.map((item, index) => (
                    <div className="comp-info-lbl">{item}</div>
                  ))}

                  <div className="comp-info-lbl">
                    {paySlipData?.companyInfo?.country} -{" "}
                    {paySlipData?.companyInfo?.postalCode}
                  </div>
                </div>
                <div className="comp-right flex flex-col">
                  <div className="item flex aic">
                    <div className="item-tag">Pay Date</div>
                    <div className="colon">:</div>
                    <div className="item-lbl">
                      {`${moment(paySlipData?.payDate).format("D")}-${moment(
                        paySlipData?.payDate
                      ).format("MM")}-${moment(paySlipData?.payDate).format(
                        "YYYY"
                      )}`}
                    </div>
                  </div>
                  <div className="item flex aic">
                    <div className="item-tag">Pay Period From</div>
                    <div className="colon">:</div>
                    <div className="item-lbl">
                      {`${moment(paySlipData?.workPeriod?.from).format(
                        "D"
                      )}-${moment(paySlipData?.workPeriod?.from).format(
                        "MM"
                      )}-${moment(paySlipData?.workPeriod?.from).format(
                        "YYYY"
                      )}`}
                    </div>
                  </div>
                  <div className="item flex aic">
                    <div className="item-tag">Pay Period To</div>
                    <div className="colon">:</div>
                    <div className="item-lbl">{`${moment(
                      paySlipData?.workPeriod?.to
                    ).format("D")}-${moment(paySlipData?.workPeriod?.to).format(
                      "MM"
                    )}-${moment(paySlipData?.workPeriod?.to).format(
                      "YYYY"
                    )}`}</div>
                  </div>
                </div>
              </div>
              <div className="employee-infos flex aic">
                <div className="employee-left flex flex-col h-full">
                  <div className="employee-name">
                    {paySlipData?.userInfo?.firstName}{" "}
                    {paySlipData?.userInfo?.lastName}
                  </div>
                  <div style={{fontWeight: "700"}}>Address :</div>
                  {paySlipData?.userInfo?.address?.map((item, index) => (
                    <div className="employee-info-lbl">{item}</div>
                  ))}
                  <div className="employee-info-lbl">
                    {paySlipData?.userInfo?.country && (
                      <>
                        {paySlipData?.userInfo?.country} -{" "}
                        {paySlipData?.userInfo?.postalCode}
                      </>
                    )}
                  </div>
                </div>
                <div className="employee-right flex flex-col items-start h-full">
                  <div style={{fontWeight: "700"}}>National ID :</div>
                  <div className="employee-name">{paySlipData?.userInfo?.nationalId}</div>
                  <div style={{fontWeight: "700"}}>Department :</div>
                  <div className="item-lbl">{paySlipData?.userInfo?.department}</div>
                </div>
              </div>
              <div className="salary-info flex">
                <div className="salary-info-left flex flex-col">
                  <div className="salary-item flex aic">
                    <div className="salary-lbl">Basic Salary</div>
                    <div className="salary-val">
                      S$ {thousandAndDecimalFormat(paySlipData?.payslipInfo?.basicSalary, true, 2)}
                    </div>
                  </div>
                  <div className="salary-item flex aic">
                    <div className="salary-lbl">Total Overtimes</div>
                    <div className="salary-val">
                      S${" "}
                      {paySlipData?.payslipInfo?.totalOverTime
                        ? paySlipData?.payslipInfo?.totalOverTime
                        : thousandAndDecimalFormat(0, true, 2)}
                    </div>
                  </div>
                  <div className="salary-item flex aic">
                    <div className="salary-lbl">Total Components</div>
                    <div className="salary-val">
                      S${" "}
                      {paySlipData?.payslipInfo?.totalComponent
                        ? paySlipData?.payslipInfo?.totalComponent
                        : thousandAndDecimalFormat(0, true, 2)}
                      
                    </div>
                  </div>
                  <div className="component-info flex flex-col">
                    {paySlipData?.payslipInfo?.components?.map(
                      (item, index) => (
                        <div className="comp-item flex aic justify-between">
                          <div className="comp-lbl">{item.name}</div>
                          <div className="comp-val">
                            S$ {item.amount ? thousandAndDecimalFormat(item.amount, true, 2) : thousandAndDecimalFormat(0, true, 2)}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="salary-item flex aic pt-3 pb-3">
                    <div className="salary-lbl">Total Claim</div>
                    <div className="salary-val">
                      S${" "}
                      {paySlipData?.payslipInfo?.totalClaim
                        ? thousandAndDecimalFormat(paySlipData?.payslipInfo?.totalClaim, true, 2)
                        : thousandAndDecimalFormat(0, true, 2)}
                      
                    </div>
                  </div>
                  <div className="gross-earning-item flex aic">
                    <div className="salary-lbl">Gross Earning</div>
                    <div className="salary-val">
                      S${" "}
                      {paySlipData?.payslipInfo?.grossEarning
                        ? thousandAndDecimalFormat(paySlipData?.payslipInfo?.grossEarning, true, 2)
                        : thousandAndDecimalFormat(0, true, 2)}
                      
                    </div>
                  </div>
                  <div className="salary-item flex aic pt-3">
                    <div className="salary-lbl">Total Deductions</div>
                    <div className="salary-val">
                      (S${" "}
                      {paySlipData?.payslipInfo?.totalDeduction
                        ? thousandAndDecimalFormat(paySlipData?.payslipInfo?.totalDeduction, true, 2)
                        : thousandAndDecimalFormat(0, true, 2)}
                      )
                    </div>
                  </div>
                  <div className="component-info flex flex-col ">
                    {paySlipData?.payslipInfo?.deductions?.map(
                      (item, index) => (
                        <div className="comp-item flex aic justify-between">
                          <div className="comp-lbl">{item.name}</div>
                          <div className="comp-val">(S$ {thousandAndDecimalFormat(item.amount, true, 2)})</div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="net-salary flex aic justify-between">
                    <div className="net-salary-lbl">Net Salary</div>
                    <div className="net-salary-lbl">
                      S${" "}
                      {paySlipData?.payslipInfo?.netSalary
                        ? thousandAndDecimalFormat(paySlipData?.payslipInfo?.netSalary, true, 2)
                        : thousandAndDecimalFormat(0, true, 2)}
                    </div>
                  </div>
                </div>
                <div className="salary-info-right flex flex-col">
                  <div className="salary-item flex aic justify-between">
                    <div className="salary-lbl">Statutory Funds</div>
                  </div>
                  <div className="component-info flex flex-col">
                    {paySlipData?.payslipInfo?.statutoryFunds?.map(
                      (item, index) => (
                        <div className="comp-item flex aic justify-between">
                          <div className="comp-lbl">{item.name}</div>
                          <div className="comp-val">S$ {thousandAndDecimalFormat(item.amount, true, 2)}</div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="salary-item flex aic py-3 bd-t">
                    <div className="salary-lbl">Total Statutory Funds</div>
                    <div className="salary-val">
                      S${" "}
                      {paySlipData?.payslipInfo?.totalStatutoryFund
                        ? thousandAndDecimalFormat(paySlipData?.payslipInfo?.totalStatutoryFund, true, 2)
                        : thousandAndDecimalFormat(0, true, 2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bank-info flex">
              <div className="paid-by-bank flex">
                *Paid By: {payrollProcessData2?.data?.paymentInfo?.paidBy}{" "}
                Transfer - Bank name:{" "}
                {payrollProcessData2?.data?.paymentInfo?.bank_id?.accountName}
                {" -  "}
                Account no:{" "}
                {payrollProcessData2?.data?.paymentInfo?.bank_id?.accountNo}
              </div>
            </div>
          </div>
          <div className="page-footer flex">
            This is generated by Blue Silo Payroll
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Payslip;
