import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DropDownIcon } from "../../assets";
import Loader from "../Loader";
import {
  useFetchDepartmentQuery,
  useUpdateDepartmentMutation,
} from "../../store/services/departmentsService";

const DepartmentDetail = () => {
  const navigate = useNavigate();
  const { uId } = useParams();
  const { data, isFetching } = useFetchDepartmentQuery(uId);

  const [hide, setHide] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [statusData, setStatusData] = useState([
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "In Active", value: "inActive" },
  ]);

  const [createDepart, setCreateDepart] = useState({
    name: "",
    description: "",
    status: "",
  });

  const handleInput = (e) => {
    setCreateDepart({ ...createDepart, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setHide(false);
    });
  }, []);

  useEffect(() => {
    if (!isFetching) {
      setCreateDepart(data?.data);
    }
  }, [data?.data]);

  const [updateDepart, response] = useUpdateDepartmentMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;

  const updateDepartment = (e) => {
    e.preventDefault();
    updateDepart({ createDepart, uId });
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/department");
    }
  }, [response?.isSuccess]);

  return (
    <form onSubmit={updateDepartment} className="new-department flex">
      <div className="wrap wrapWidth flex flex-col">
        {errors !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        {!isFetching ? (
          <div className="flex flex-col">
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Name</div>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="txt"
                  placeholder="Name"
                  value={createDepart.name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Status</div>
                <div className="dropDown flex aic flex-col rel ">
                  <div className="category flex aic">
                    <div
                      className="cbox cleanbtn flex aic rel pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setHide(!hide);
                      }}
                    >
                      <div className="slt flex aic">
                        <div className="unit-name flex aic font s14 b4">
                          <span
                            className="unit-eng flex aic font s14 b4"
                            placeholder="Status"
                          >
                            {selectedStatus
                              ? selectedStatus
                              : data?.data.status}
                          </span>
                        </div>
                      </div>

                      <div>
                        <DropDownIcon />
                      </div>
                    </div>
                  </div>
                  <div className={`block flex aic abs ${hide ? "show" : ""}`}>
                    <div className="manue flex aic col anim">
                      {statusData.map((item, index) => (
                        <div
                          key={index}
                          className="slt flex aic"
                          onClick={(e) => {
                            setHide(!hide);
                            setSelectedStatus(item.title);
                            setCreateDepart({
                              ...createDepart,
                              status: item.value,
                            });
                          }}
                        >
                          <div className="unit-name flex aic font s14 b4">
                            <span className="unit-eng flex aic font s14 b4">
                              {item.title}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="field flex flex-col pt-6">
              <div className="lbl">Description</div>
              <textarea
                id="description"
                name="description"
                type="text"
                className="txt h-[140px]"
                placeholder="Description"
                value={createDepart.description}
                onChange={handleInput}
              />
            </div>
            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={response.isLoading ? "Loading..." : "Update"}
                className="btn button"
              />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </form>
  );
};
export default DepartmentDetail;
