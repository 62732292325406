import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const claimsService = createApi({
  reducerPath: "claims",
  tagTypes: "claim",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create Claim Form
      createClaimForm: builder.mutation({
        query: (todayDate) => {
          return { url: "/api/claim", method: "POST", body: todayDate };
        },
        invalidatesTags: ["claim"],
      }),

      //   Create Claim Detail
      createNewClaimDetail: builder.mutation({
        query: (newClaimForm) => {
          return {
            url: "/api/claim/detail",
            method: "POST",
            body: newClaimForm,
          };
        },
        invalidatesTags: ["claim"],
      }),

      //   Delete a My Claim
      deleteMyClaim: builder.mutation({
        query: (uId) => {
          return { url: `/api/claim/${uId}`, method: "DELETE" };
        },
        invalidatesTags: ["claim"],
      }),

      //   Delete a Claim Detail
      deleteClaimDetail: builder.mutation({
        query: (uId) => {
          return { url: `/api/claim/detail/${uId}`, method: "DELETE" };
        },
        invalidatesTags: ["claim"],
      }),

      // Update the Claims Details Status Approved/Reject
      updateClaimsStatus: builder.mutation({
        query: (data) => {
          return {
            url: `/api/claim/${data.uId}`,
            method: "PUT",
            body: data.updatedData,
          };
        },
        invalidatesTags: ["claim"],
      }),

      // Update the Claims Details
      updateClaimsDetail: builder.mutation({
        query: (data) => {
          return {
            url: `/api/claim/detail/${data.uId}`,
            method: "PUT",
            body: data?.formData,
          };
        },
        invalidatesTags: ["claim"],
      }),

      // Update the Claim
      updateClaim: builder.mutation({
        query: ({ claimFormData, uId }) => {
          return {
            url: `/api/claim/${uId}`,
            method: "PUT",
            body: claimFormData,
          };
        },
        invalidatesTags: ["claim"],
      }),

      //   Get the List of Users Claims
      getClaimList: builder.query({
        query: (user_id) => {
          return {
            url: `/api/claim?user_id=${user_id}`,
            method: "GET",
          };
        },
        providesTags: ["claim"],
      }),

      //   Get the List of Users Claims for Admin Side
      getClaimListAdmin: builder.query({
        query: () => {
          return {
            url: `/api/claim?status_hr=${"pending"}`,
            method: "GET",
          };
        },
        providesTags: ["claim"],
      }),
      getHistoryClaimListAdmin: builder.query({
        query: () => {
          return {
            url: `/api/claim?status_hr=${"not_pending"}`,
            method: "GET",
          };
        },
        providesTags: ["claim"],
      }),
      //   Get the Detail Claims List
      getListByIds: builder.query({
        query: (abc) => {
          return {
            url: `/api/claim/detail/list?${abc}`,
            method: "GET",
          };
        },
        providesTags: ["claim"],
      }),

      //   Get the List of Users Claims My Approved List
      getClaimPendingApprovalsList: builder.query({
        query: (user_id) => {
          return {
            url: `/api/claim?status_supervisor=pending&supervisor_id=${user_id}`,
            method: "GET",
          };
        },
        providesTags: ["claim"],
      }),

      //   Get the List of Users Claims My Approved List
      getClaimApprovedList: builder.query({
        query: (user_id) => {
          return {
            url: `/api/claim?status_supervisor=not_pending&supervisor_id=${user_id}`,
            method: "GET",
          };
        },
        providesTags: ["claim"],
      }),

      //   Get the List of Approved by HR and Status by Supervisor for payroll process list
      getClaimApprovedListPayroll: builder.query({
        query: ({ user_id }) => {
          return {
            url: `/api/claim?status_supervisor=approved&status_hr=approved&user_id=${user_id}`,
            method: "GET",
          };
        },
        providesTags: ["claim"],
      }),

      //   Get the Single Claims Form
      fetchClaimFormDetail: builder.query({
        query: (uId) => {
          return {
            url: `/api/claim/${uId}`,
            method: "GET",
          };
        },
        providesTags: ["claim"],
      }),

      //   Get the Single Claims Form details
      fetchClaimDetailData: builder.query({
        query: (uId) => {
          return {
            url: `/api/claim/detail/${uId}`,
            method: "GET",
          };
        },
        providesTags: ["claim"],
      }),
    };
  },
});

export const {
  // User Side
  useCreateClaimFormMutation,
  useCreateNewClaimDetailMutation,
  useGetClaimListQuery,
  userGetListByIdsQuery,
  useGetClaimApprovedListQuery,
  useGetClaimPendingApprovalsListQuery,
  useDeleteMyClaimMutation,
  useDeleteClaimDetailMutation,
  useFetchClaimFormDetailQuery,
  useFetchClaimDetailDataQuery,
  useUpdateClaimsStatusMutation,
  useUpdateClaimMutation,
  useUpdateClaimsDetailMutation,

  // Admin Side
  useGetClaimListAdminQuery,
  useGetHistoryClaimListAdminQuery,
  useGetClaimApprovedListPayrollQuery,
} = claimsService;

export default claimsService;
