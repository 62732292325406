import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const dashboardService = createApi({
  reducerPath: "DashboardData",
  tagTypes: "uDashboard",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Get User Side Dashboard Data
      getUserDashboardData: builder.query({
        query: () => {
          return {
            url: "/api/auth/dashboard/user",
            method: "GET",
          };
        },
        providesTags: ["uDashboard"],
      }),

      //   Get HR Side Dashboard Data
      getHrDashboardData: builder.query({
        query: () => {
          return {
            url: "/api/auth/dashboard/hr",
            method: "GET",
          };
        },
        providesTags: ["uDashboard"],
      }),
    };
  },
});

export const { useGetUserDashboardDataQuery, useGetHrDashboardDataQuery } =
  dashboardService;

export default dashboardService;
