import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CalendarIcon, EditIcon, DelIcon,Ey, EyeIcon } from "../../assets";

import Modal from "../../components/Modal";
import ClaimDelete from "../../components/UserSide/ClaimDetailDelete";
import Loader from "../../components/Loader";
import { setClaimFormId } from "../../store/reducers/globalReducer";
import {
  useFetchClaimFormDetailQuery,
  useUpdateClaimMutation,
} from "../../store/services/claimsService";
import { thousandAndDecimalFormat } from "../../utils/common";

const UpdateClaimForm = () => {
  const { userRole, userToken, userOrgId, userId } = useSelector(
    (state) => state.authReducer
  );
  const navigate = useNavigate();
  const { uId } = useParams();
  const dispatch = useDispatch();

  const { data: formDetailsData, isFetching: formDetailFetching } =
    useFetchClaimFormDetailQuery(uId);


  const [open, setOpen] = useState(false);
  const [claimDetailId, setClaimDetailId] = useState();
  const [date, setDate] = useState(new Date().getTime());
  const [claimFormData, setClaimFormData] = useState({
    requestDate: date,
  });

  const [createClaimForm, data] = useUpdateClaimMutation();
  const errors = data?.error?.data ? data?.error?.data.message : null;

  useEffect(() => {
    setClaimFormData({
      ...claimFormData,
      requestDate: formDetailsData?.data?.requestDate,
    });
  }, [formDetailsData?.data]);

  const updateClaimFormApi = (e) => {
    e.preventDefault();
    createClaimForm({ claimFormData: claimFormData, uId });
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/user-claims-list");
    }
  }, [data?.isSuccess]);

  const sum = formDetailsData?.data?.details
    ?.map((item) => item.amount)
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <div className="users-claims-form flex">
      <div className="wrap wrapWidth flex flex-col">
        {errors !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        <div className="page-hdr flex justify-between items-center">
          <div className="field flex flex-col">
            <div className="lbl mb-2">Request Date</div>
            <div className="date-picker flex aic jc">
              <Datetime
                closeOnSelect={true}
                value={
                  claimFormData?.requestDate
                    ? new Date(claimFormData?.requestDate).getTime()
                    : new Date().getTime()
                }
                onChange={(value) => {
                  setClaimFormData({
                    ...claimFormData,
                    requestDate: new Date(value).getTime(),
                  });
                }}
                timeFormat={false}
                dateFormat="MMMM Do, YYYY"
                className="start-date cleanbtn"
              />
              <div className="ico">
                <CalendarIcon />
              </div>
            </div>
          </div>
          <div className="page-tag s20 b6 pr-6">Update Claim Form</div>
        </div>
        {!formDetailFetching ? (
          <>
            <div className="table-blk flex">
              <div className="tbl flex flex-col">
                <div className="row flex aic">
                  <div className="col flex">
                    <div className="col-item">Receipt Date</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Description</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Amount</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Proof</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">
                      <Link
                        to={`/addNew-claim-forUpdate`}
                        state={{
                          _id: uId,
                          details: formDetailsData?.data?.details,
                        }}
                        className="btn cursor-pointer"
                      >
                        New
                      </Link>
                    </div>
                  </div>
                </div>

                {formDetailsData?.data?.details?.length ? (
                  formDetailsData?.data?.details?.map((item, index) => (
                    <div key={index} className="row flex aic">
                      <div className="col flex">
                        <div className="col-item">
                          {`${moment(item.receiptDate).format("D")}-${moment(
                            item.receiptDate
                          ).format("MM")}-${moment(item.receiptDate).format(
                            "YYYY"
                          )}`}
                        </div>
                      </div>
                      <div className="col flex">
                        <div className="col-item">{item.description}</div>
                      </div>
                      <div className="col flex">
                        <div className="col-item">{thousandAndDecimalFormat(item.amount, true, 2)}</div>
                      </div>
                      <div className="col flex">
                        <div className="col-item underline cursor-pointer">
                          {item.proof ? <a href={item.proof} target="_blank">
                              <EyeIcon />
                            </a> : "Null"}
                        </div>
                      </div>
                      <div className="col flex">
                        <div className="col-item flex aic jc">
                          <Link
                            to={`/update-user-claim/${item?.uId}`}
                            state={{
                              _id: uId,
                            }}
                            className="icon cursor-pointer"
                            onClick={(e) => dispatch(setClaimFormId(uId))}
                          >
                            <EditIcon />
                          </Link>
                          <div
                            className="icon cursor-pointer"
                            onClick={(e) => {
                              setClaimDetailId({ uId: item.uId });
                              setOpen(true);
                            }}
                          >
                            <DelIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex items-center justify-center h-52 text-base font-medium">
                    Data not found
                  </div>
                )}
              </div>
            </div>

            <div className="page-footer flex justify-between my-6">
              <div className="amount flex aic ">
                <div className="lbl mr-1">Total Amount:</div>
                <div className="val b7 s20">{thousandAndDecimalFormat(sum, true, 2)} SGD</div>
              </div>
              <div className="actions">
                <div
                  className="btn button"
                  onClick={(e) => updateClaimFormApi(e)}
                >
                  Update
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ClaimDelete setOpen={setOpen} data={claimDetailId} />
      </Modal>
    </div>
  );
};

export default UpdateClaimForm;
