import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        fill="#4E7A7D"
        d="M18.493 17.782L14.17 13.46a7.554 7.554 0 001.862-4.973c0-4.185-3.404-7.585-7.585-7.585C4.263.902.863 4.306.863 8.487c0 4.18 3.404 7.584 7.584 7.584 1.901 0 3.64-.702 4.974-1.862l4.322 4.323c.103.102.24.157.375.157a.533.533 0 00.375-.907zM1.924 8.487a6.527 6.527 0 016.52-6.52 6.526 6.526 0 016.519 6.52c0 3.592-2.923 6.523-6.52 6.523-3.592 0-6.519-2.927-6.519-6.523z"
      ></path>
    </svg>
  );
}

export default SearchIcon;
