import React from "react";

function EyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <g fill="#4E7A7D" clipPath="url(#clip0_737_6372)">
        <path d="M12.978 14.402a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z"></path>
        <path
          fillRule="evenodd"
          d="M1.523 12.002c1.53-4.869 6.077-8.4 11.45-8.4 5.374 0 9.922 3.531 11.451 8.4-1.529 4.868-6.077 8.4-11.45 8.4-5.374 0-9.922-3.532-11.45-8.4zm16.25 0a4.8 4.8 0 11-9.6 0 4.8 4.8 0 019.6 0z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_737_6372">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(.977)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default EyeIcon;
