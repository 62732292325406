import axios from "axios";

const authorization = localStorage.getItem("user-token");
const orgid = localStorage.getItem("user-orgid");

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: authorization ? authorization : "",
    orgid: orgid ? orgid : ""
  },
});

export const exportBulk = async ({ uId }) => {
  try {
    const res = await customAxios.get(`/api/export/${uId}/bulk`, {
      responseType: "blob"
    });

    return res.data;
  } catch(err) {
    console.log("ERROR API", err);
  }
}

export const exportOwnBulk = async () => {
  try {
    const res = await customAxios.get(`/api/export/bulk/own`, {
      responseType: "blob"
    });

    return res.data;
  } catch(err) {
    console.log("ERROR API", err);
  }
}


export const exportPayslip = async ({ uId, detail_id }) => {
  try {
    const res = await customAxios.get(`/api/export/${uId}/detail/${detail_id}/payslip`, {
      responseType: "blob"
    });

    return res.data;
  } catch(err) {
    console.log("ERROR API", err);
  }
}