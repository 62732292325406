import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EditIcon, CalendarIcon, DropDownIcon } from "../../assets";
import Spinner from "../../components/Spinner";
import Loader from "../../components/Loader";

import { useGetGeneralInfoQuery } from "../../store/services/usersService";
import { useGetQuery } from "../../store/services/corporateService";

const CompanyInfo = () => {
  const dispatch = useDispatch();
  const [companyInfo, setCompanyInfo] = useState();

  const {
    data: infoData,
    isLoading: infoDataLoading,
    isFetching: infoDataFetching,
  } = useGetGeneralInfoQuery();


  useEffect(() => {
    setCompanyInfo(infoData?.data);
  }, [infoDataFetching]);

  window.onload = function () {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  };

  return (
    <div className="company-info flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="action flex justify-end">
          <a
            href="/update-general"
            className="flex items-center justify-center cursor-pointer"
          >
            <EditIcon />
          </a>
        </div>
        {!infoDataLoading || !infoDataFetching ? (
          <div id="loaded" className="wrap-block">
            <div className="field flex flex-col">
              <div className="lbl">Corporate name</div>
              <input
                type="text"
                id="corpName"
                className="txt"
                value={companyInfo?.corpName}
                disabled
                placeholder="Corporate name"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Alias</div>
              {companyInfo?.alias?.length > 0 ? (
                <div className="txt flex items-center">
                  {companyInfo?.alias?.map((item, index) => (
                    <div key={index} className="flex aic disabled-input">
                      <span className="mr-2">{item},</span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="txt disabled-input">Alias is null</div>
              )}
            </div>
            <div className="field flex flex-col">
              <div className="lbl">UEN</div>
              <input
                type="text"
                id="uen"
                className="txt"
                value={companyInfo?.uen}
                disabled
                placeholder="UEN"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Date of Incorporation</div>
              <div className="date-picker flex aic justify-between disabled-input">
                <div className="">
                  {`${moment(companyInfo?.dateOfIncorporation).format(
                    "D"
                  )}-${moment(companyInfo?.dateOfIncorporation).format(
                    "MM"
                  )}-${moment(companyInfo?.dateOfIncorporation).format(
                    "YYYY"
                  )}`}
                </div>

                <div className="ico">
                  <CalendarIcon />
                </div>
              </div>
            </div>
            <div className="field flex flex-col">
              <div className="lbl">CPF CSN Number</div>
              <input
                type="text"
                id="CPFCSNNo"
                className="txt"
                value={companyInfo?.CPFCSNNo}
                disabled
                placeholder="CPF CSN Number"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">CPF Employee Min Salary</div>
              <input
                type="number"
                id="minSalary"
                className="txt"
                value={companyInfo?.minSalary}
                disabled
                placeholder="CPF Employee Min Salary"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Contact no</div>
              <input
                type="text"
                id="contactNo"
                className="txt"
                value={companyInfo?.contactNo}
                disabled
                placeholder="Contact no"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Country</div>
              <input
                type="text"
                id="country"
                className="txt"
                value={companyInfo?.country}
                disabled
                placeholder="Contact no"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">State/Region</div>
              <input
                type="text"
                id="state"
                className="txt"
                value={companyInfo?.state}
                disabled
                placeholder="Contact no"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">City</div>
              <input
                type="text"
                id="city"
                className="txt"
                value={companyInfo?.city}
                disabled
                placeholder="Contact no"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Postal Code</div>
              <input
                type="text"
                id="postalCode"
                className="txt"
                value={companyInfo?.postalCode}
                disabled
                placeholder="Postal Code"
              />
            </div>
            <div className="field flex flex-col">
              <div className="lbl">Sector</div>
              <input
                type="text"
                id="Sector"
                name="Sector"
                className="txt"
                value={companyInfo?.sector}
                disabled
                placeholder="Sector"
              />
            </div>
            {companyInfo?.address?.length > 0 ? (
              <>
                {companyInfo?.address.map((item, index) => (
                  <div key={index} className="field flex flex-col">
                    <div className="lbl">Address {index + 1}</div>
                    <input
                      type="text"
                      className="txt"
                      value={item}
                      disabled
                      placeholder="Address"
                    />
                  </div>
                ))}
              </>
            ) : null}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default CompanyInfo;
