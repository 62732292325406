import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import { DownloadIcon, CalendarIcon, SearchIcon, DownloadIconPy } from "../../assets";
import { useGetUserPayrollHistoryQuery } from "../../store/services/reportService";

import Loader from "../../components/Loader";
import { thousandAndDecimalFormat, downloadFile } from "../../utils/common";
import { exportOwnBulk, exportPayslip } from "../../store/services/export.service";
import DataTable from "react-data-table-component";

const UserPayrollHistory = () => {
  const [fromDate, setFromDate] = useState(new Date(moment().startOf('month').format('MM/DD/YYYY hh:mm')));
  const [toDate, setToDate] = useState(new Date(moment().endOf('month').format('MM/DD/YYYY hh:mm')));
  
  const handleFromDate = (date) => {
    setFromDate(new Date(moment(date).startOf('day').format('MM/DD/YYYY hh:mm')));
  };

  const handleToDate = (date) => {
    setToDate(new Date(moment(date).endOf('day').format('MM/DD/YYYY hh:mm')));
  };
  const {
    data: payrollHistoryList,
    isFetching: payrollListIsFetching,
    isLoading: payrollListIsLoading,
  } = useGetUserPayrollHistoryQuery({
    fromDate,
    toDate,
  });

  const handleExportBulkPayslip = () => {
    exportOwnBulk()
    .then((res) => {
      if (res) {
        const title = `My_Payslip`;
        downloadFile(title, "zip", res);
      }
    });
  }

  const handleExportPayslip = (uId, detail_id, title) => {
    exportPayslip({
      uId: uId,
      detail_id: detail_id
    })
    .then((res) => {
      if (res) {
        downloadFile(title, "pdf", res);
      }
    });
  }

  const renderColumns = () => {
    return [
        {
            name: "Pay Date",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div >
                {`${moment(row.payDate).format("D")}-${moment(
                  row.payDate
                ).format("MM")}-${moment(row.payDate).format(
                  "YYYY"
                )}`}
              </div>
            ),
        },
        {
            name: "Salary",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div >{thousandAndDecimalFormat(row?.salary, true, 2)}</div>
            )
        },
        {
            name: "Overtime",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div >{thousandAndDecimalFormat(row?.ovetime, true, 2)}</div>
            )
        },
        {
            name: "Claims",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div >{thousandAndDecimalFormat(row?.claim, true, 2)}</div>
            )
        },
        {
            name: "Additional Earning",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div >
                {thousandAndDecimalFormat(row.additionalEarning, true, 2)}
              </div>
            )
        },
        {
            name: "Deductions",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div>
                {row?.deductions.map((item, index) => (
                  <div key={index} className="col-item flex  flex-col">
                    <div className="flex s10">
                      {item.name}: {thousandAndDecimalFormat(item.value, true, 2)}
                    </div>
                  </div>
                ))}
              </div>
            )
        },
        {
            name: "Employee Contributions",
            sortable: true,
            width: "133px",
            cell: row => (
              <div>
                {row?.employerContribution.map(
                  (item, index) => (
                    <div key={index} className="col-item flex  flex-col">
                      <div className="flex s10">
                        {item.name}: {thousandAndDecimalFormat(item.value, true, 2)}
                      </div>
                    </div>
                  )
                )}
              </div>
            )
        },
        {
            name: "Net Pay",
            sortable: true,
            width: "auto",
            minWidth: "auto",
            maxWidth: "auto",
            cell: row => (
              <div >{thousandAndDecimalFormat(row.netPay, true, 2)}</div>
            )
        },
        {
            name: 'Actions',
            button: true,
            cell: row => (
                  <>
                  <div className="icon cursor-pointer" onClick={() => {
                    handleExportPayslip(
                      row.uId,
                      row.detail_id,
                      `Payslip_${moment(row.payDate).format("DD-MM-YYYY")}`
                    )
                  }}>
                  <DownloadIconPy status={"enabled"}/>
                </div>
                </>
            )
        }
    ];
  };
  return (
    <div className="user-payroll-history flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="history-modification-list flex flex-col">
          <div className="page-hdr flex">
            <div className="hdr-right flex flex-1 aic">
              <div className="flex aic">
                <div className="field flex aic mr-3">
                  <div className="lbl">From</div>
                  <div className="date-picker flex aic jc">
                    <Datetime
                      closeOnSelect={true}
                      value={fromDate ? fromDate : new Date()}
                      onChange={(value) => {
                        handleFromDate(new Date(value));
                      }}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      className="start-date cleanbtn"
                    />
                    <div className="ico">
                      <CalendarIcon />
                    </div>
                  </div>
                </div>
                <div className="field flex aic ">
                  <div className="lbl">To</div>
                  <div className="date-picker flex aic jc ">
                    <Datetime
                      closeOnSelect={true}
                      value={toDate ? toDate : new Date()}
                      onChange={(value) => {
                        handleToDate(new Date(value));
                      }}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      className="start-date cleanbtn"
                    />
                    <div className="ico">
                      <CalendarIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hdr-right flex aic justify-end">
              <div className="btn-export button flex aic jc" onClick={() => handleExportBulkPayslip()}>
                Export Bulk Payslip <DownloadIcon />
              </div>
            </div>
          </div>
          <div className="table-blk flex">
            <div className="tbl flex flex-col">
              {!payrollListIsLoading ? (
                <>
                  {payrollHistoryList?.data?.length > 0 ? (
                    <>
                    <DataTable
                      columns={renderColumns()}
                      data={payrollHistoryList?.data}
                      responsive={true}
                      pagination={true} />
                    </>
                  ) : (
                    <div className="h-80 flex aic jc">No Data found</div>
                  )}
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPayrollHistory;
