import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

import { useVerifyTokenMutation } from "../store/services/authService";

import {
  setUserInfo,
  setUserId,
  setUserRole,
  setUserToken,
  setUserOrgId,
  setOrgList,
} from "../store/reducers/authReducer";

const JumpAuthorize = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchParams = useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const bearerToken = searchParams.get("token");
  const splitOrg = search?.toString().split("organizationId=");
  const organizationId = splitOrg.length > 0 ? splitOrg[splitOrg.length - 1] : "";

  const [verifyToken, response] = useVerifyTokenMutation();

  useEffect(() => {
    verifyToken({
      bearerToken: bearerToken,
      orgId: organizationId
    });
  }, []);

  useEffect(() => {
    if (!response.isLoading && response.status !== "uninitialized") {
      if (response.isSuccess) {
        localStorage.setItem(
          "orgList",
          JSON.stringify(response?.data?.data?.organizations)
        );
        localStorage.setItem("user-info", JSON.stringify(response?.data?.data));
        localStorage.setItem("user-token", response?.data?.data.token);
        localStorage.setItem("user-role", response?.data?.data.roles[0]);
        localStorage.setItem("user-uId", response?.data?.data.uId);
        localStorage.setItem("user-userId", response?.data?.data.userId);
        localStorage.setItem(
          "user-orgid",
          response?.data?.data.orgId
        );

        dispatch(setOrgList(response?.data?.data?.organizations));
        dispatch(setUserInfo(response?.data?.data));
        dispatch(setUserId(response?.data?.data.uId));
        dispatch(setUserRole(response?.data?.data.roles[0]));
        dispatch(setUserToken(response?.data?.data.token));
        dispatch(setUserOrgId(response?.data?.data.orgId));
        navigate("/");
      } else {
        navigate("/");
      }
    }
  }, [response])

  return (
    <div></div>
  );
};

export default JumpAuthorize;
