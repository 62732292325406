import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const reportService = createApi({
  reducerPath: "reportsApi",
  tagTypes: "report",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Get hr admin get cpf history list Copy
      getCpfReport: builder.query({
        query: ({ fromDate, toDate }) => {
          return {
            url: `/api/payroll/history/cpf?fromDate=${fromDate}&toDate=${toDate}`,
            method: "GET",
          };
        },
        providesTags: ["report"],
      }),
      //   Get hr admin get cpf history list Copy
      getCpfUserReport: builder.query({
        query: ({ fromDate, toDate }) => {
          return {
            url: `/api/payroll/history/cpf/own?fromDate=${fromDate}&toDate=${toDate}`,
            method: "GET",
          };
        },
        providesTags: ["report"],
      }),
      //   Get hr admin get cpf history list Copy
      getUserPayrollHistory: builder.query({
        query: ({ fromDate, toDate }) => {
          return {
            url: `/api/payroll/history/payroll?fromDate=${fromDate}&toDate=${toDate}`,
            method: "GET",
          };
        },
        providesTags: ["report"],
      }),
    };
  },
});

export const { useGetCpfReportQuery, useGetCpfUserReportQuery, useGetUserPayrollHistoryQuery } =
  reportService;

export default reportService;
