import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../assets";
import { useRevokeUserMutation } from "../../store/services/usersService";

const UserRevokeAccess = ({ setOpen, revokeId }) => {
  const [revokeUserId, response] = useRevokeUserMutation();

  const userRevoke = (uId) => {
    revokeUserId(uId);
    setOpen(false);
  };

  useEffect(() => {
    if (response === "User access in organization have been revoked") {
      setOpen(false);
    }
  }, [response === "User access in organization have been revoked"]);

  return (
    <div className="depart-delete flex">
      <div className="wrap flex flex-col">
        <div className="hdr flex justify-end">
          <div
            className="icon flex aic jc cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="blk flex aic  flex-col justify-around">
          <div className="desc">Are you sure to revoke this user access?</div>
          <div className="actions flex aic jc">
            <div className="btn button" onClick={(e) => setOpen(false)}>
              Cancel
            </div>
            <div className="btn button" onClick={(e) => userRevoke(revokeId)}>
              Revoke
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRevokeAccess;
