import React from "react";

function UserAddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#4E7A7D"
        d="M12.094 15.902c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5zM15.804 4.562a4.948 4.948 0 00-3.71-1.66c-1.4 0-2.68.57-3.59 1.51-.87.9-1.41 2.14-1.41 3.49 0 .94.26 1.82.73 2.57.25.43.57.82.95 1.14.87.8 2.03 1.29 3.32 1.29 1.83 0 3.41-.98 4.28-2.43.26-.43.46-.91.57-1.41.1-.37.15-.76.15-1.16 0-1.28-.49-2.45-1.29-3.34zm-1.84 4.26h-.93v.97a.939.939 0 11-1.88 0v-.97h-.93a.939.939 0 110-1.88h.93v-.89a.939.939 0 111.88 0v.89h.93a.939.939 0 110 1.88z"
      ></path>
    </svg>
  );
}

export default UserAddIcon;
