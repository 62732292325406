import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../assets";
import { useSelector } from "react-redux";
import { useDeletePayrollMutation } from "../../store/services/payrollService";
import {  useNavigate } from "react-router-dom";

const DeletePayrollHistory = ({ setOpenDel, refetchList }) => {
  
  const navigate = useNavigate();
  const { payRollUid } = useSelector((state) => state.globalReducer);
  const [deletePayroll, response] = useDeletePayrollMutation();

  const payrollDelete = (payRollUid) => {
    deletePayroll(payRollUid);
    refetchList();
  };

  useEffect(() => {
    if (response?.data?.data === "success") {
      setOpenDel(false);
    }
  }, [response?.data?.data === "success"]);

  return (
    <div className="depart-delete flex">
      <div className="wrap flex flex-col">
        <div className="hdr flex justify-end">
          <div
            className="icon flex aic jc cursor-pointer"
            onClick={(e) => setOpenDel(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="blk flex aic  flex-col justify-around">
          <div className="desc">Are you sure to delete this ?</div>
          <div className="actions flex aic jc">
            <div className="btn button" onClick={(e) => setOpenDel(false)}>
              Cancel
            </div>
            <div
              className="btn button"
              onClick={(e) => payrollDelete(payRollUid)}
            >
              Delete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePayrollHistory;
