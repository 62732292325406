import React from "react";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M18.352 32.164a14 14 0 100-28 14 14 0 000 28zm6.487-16.263a1.75 1.75 0 00-2.475-2.474l-5.762 5.763-2.263-2.263a1.75 1.75 0 00-2.475 2.474l3.5 3.5a1.75 1.75 0 002.475 0l7-7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CheckIcon;
