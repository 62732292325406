import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modal";
import { EditIcon, DelIcon } from "../../assets";

import Loader from "../../components/Loader";
import CpfAccountDelete from "../../components/AdminSide/CpfAccountDelete";
import {
  useGetQuery,
  useDeleteDepartmentMutation,
} from "../../store/services/cpfAccountService";
import { useGetOptionsQuery } from "../../store/services/generalService";
import DataTable from "react-data-table-component";

const CpfAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isLoading, isFetching } = useGetQuery();
  const { data: options, isLoading: isLoadOp } = useGetOptionsQuery();
  
  const [employeeTypeList, setEmployeeTypeList] = useState([]);

  useEffect(() => {
    if (!isLoadOp) {
      setEmployeeTypeList(options?.data.userStatuses);
    }
  }, [options?.data]);

  const typeNaming = (data) => {
    var newArray = employeeTypeList.filter(function (el) {
      return el.option == data.value;
    });

    return newArray? newArray[0]?.display : "";
  };
  const [open, setOpen] = useState(false);
  const [deletedCpf, setDeletedCpf] = useState(null);

  const renderColumns = () => {
    return [
        {
            name: "CPF Account",
            sortable: true,
            selector: row => row?.accountNo,
        },
        {
            name: "CPF Submission No.",
            sortable: true,
            selector: row => row?.submissionNo,
        },
        {
            name: "Organization ID",
            sortable: true,
            selector: row => row?.organizationIdNo,
        },
        {
            name: 'Employee Type',
            button: true,
            cell: row => (
                  <div>
                  {typeNaming(row.employeeType)}
                  </div>
            )
        },
        {
            name: 'Actions',
            button: true,
            cell: row => (
                  <>
                  <Link
                  to={`/update-cpf-account/${row.uId}`}
                  className="icon cursor-pointer"
                >
                  <EditIcon />
                </Link>
                <div
                  className="icon cursor-pointer"
                  onClick={(e) => {
                    setOpen(true);
                    setDeletedCpf({
                      uId: row.uId,
                      accountNo: row.accountNo,
                    });
                  }}
                >
                  <DelIcon />
                </div>
                </>
            )
        }
    ];
  };
  return (
    <div className="cpf-account-page flex">
      <div className="wrap wrapWidth flex flex-col">
        {!isLoading || !isFetching  || !isLoadOp ? (
          <div className="table-blk flex">
            <div className="tbl flex flex-col">
              <div className="row flex aic">
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item">
                    <Link to="/new-cpf-account" className="btn button">
                      New
                    </Link>
                  </div>
                </div>
              </div>
          <DataTable
              columns={renderColumns()}
              data={data?.data}
              responsive={true}
              pagination={true} />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <CpfAccountDelete setOpen={setOpen} data={deletedCpf} />
      </Modal>
    </div>
  );
};

export default CpfAccount;
