import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import logowhite from "../assets/logoblack.png";

const Registration = ({ history }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");

  return (
    <div className="register-p flex flex-col aic w-full">
      <div className="wrapper wrapWidth">
        <div className="logo flex aic">
          <img src={logowhite} className="img" width={100} height={100}/>
        </div>
        <div className="form-block flex flex-col bg-white p-6 rounded-lg">
          <div className="title font s32 b5 c000">Registration</div>
          <div className="row">
            <div className="feild flex flex-col">
              <div className="lbl font s14 c68">First Name</div>
              <input
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                placeholder="First Name"
                className="iput font s14 c000"
              />
            </div>
            <div className="feild flex flex-col">
              <div className="lbl font s14 c68">Last Name</div>
              <input
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                placeholder="Last Name"
                className="iput font s14 c000"
              />
            </div>
            <div className="feild flex flex-col">
              <div className="lbl font s14 c68">Email</div>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
                className="iput font s14 c000"
              />
            </div>
            <div className="feild flex flex-col">
              <div className="lbl font s14 c68">Company Name</div>
              <input
                onChange={(e) => setCompany(e.target.value)}
                type="text"
                placeholder="Company Name"
                className="iput font s14 c000"
              />
            </div>
            <div className="feild flex flex-col">
              <div className="lbl font s14 c68">Contact</div>
              <input
                onChange={(e) => setContactNo(e.target.value)}
                type="text"
                placeholder="Contact No."
                className="iput font s14 c000"
              />
            </div>
            <div className="feild flex flex-col">
              <div className="lbl font s14 c68">Password</div>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
                className="iput font s14 c000"
              />
            </div>
          </div>
          <div className="feild btn flex flex-col">
            <button className="btn button">Register</button>
          </div>
          <Link to="/" className="font s14 c68 hover:underline">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Registration;
