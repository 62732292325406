import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../assets";
import { useDeleteComponentMutation } from "../../store/services/componentService";

const ComponentDelete = ({ setOpen, data }) => {
  const [removeComponent, response] = useDeleteComponentMutation();

  const deleteComponent = (uId) => {
    removeComponent(uId);
  };

  useEffect(() => {
    if (response?.data?.data === "success") {
      setOpen(false);
    }
  }, [response?.data?.data === "success"]);

  return (
    <div className="depart-delete flex">
      <div className="wrap flex flex-col">
        <div className="hdr flex justify-end">
          <div
            className="icon flex aic jc cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="blk flex aic  flex-col justify-around">
          <div className="desc">
            Are you sure to delete this ‘{data?.name}’ component?
          </div>
          <div className="actions flex aic jc">
            <div className="btn button" onClick={(e) => setOpen(false)}>
              Cancel
            </div>
            <div
              className="btn button"
              onClick={(e) => deleteComponent(data?.uId)}
            >
              Delete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentDelete;
