import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import {
  EditIcon,
  CalendarIcon,
  DropDownIcon,
  AttachmentIcon,
} from "../../assets";

const EditClaim = () => {
  const [img, setImg] = useState();
  const [date, setDate] = useState(new Date().getTime());

  return (
    <div className="company-info flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="wrap-block">
          <div className="field flex flex-col">
            <div className="lbl">Reciept Date</div>
            <div className="date-picker flex aic jc">
              <Datetime
                closeOnSelect={true}
                value={date ? date : new Date().getTime()}
                onChange={(value) => {
                  setDate(new Date(value).getTime());
                }}
                timeFormat={false}
                dateFormat="DD-MM-YYYY"
                className="start-date cleanbtn"
              />
              <div className="ico">
                <CalendarIcon />
              </div>
            </div>
          </div>
          <div className="field flex flex-col">
            <div className="lbl">Amount</div>
            <input type="text" className="txt" placeholder="Amount" />
          </div>
          <div className="field flex flex-col">
            <div className="lbl">Comments</div>
            <textarea
              type="text"
              className="txt h-[110px]"
              placeholder="Comments..."
            />
          </div>
          <div className="field flex flex-col">
            <div className="lbl">Proof</div>
            <div className="txt flex aic">
              <input
                type="file"
                accept="*"
                title=""
                id="upload_file"
                className="select-file cleanbtn w-full"
                onChange={(e) => {
                  let file = e.target.files[0];
                  //setImg(e.target.files[0]);
                  setImg(file);
                }}
              />
              <div className="icon">
                <AttachmentIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="action-back flex aic justify-end">
          <Link to="/claims-details" className="btn-back button mr-4">
            Cancel
          </Link>
          <div className="btn-back button">Update</div>
        </div>
      </div>
    </div>
  );
};

export default EditClaim;
