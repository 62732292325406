import React, { useState, useEffect } from "react";
import SidebarItem from "./SidebarItem";
// import items from "./sidebar.json";
import "./index.css";
import {
  SettingIcon,
  DropDownIcon,
  DropUpIcon,
  UserIcon,
  PayrollIcon,
  ReportIcon,
  HomeIcon,
  CheckIcon,
} from "../../../assets";

export default function Sidebar() {
  const items = [
    {
      title: "Dashboard",
      icon: <HomeIcon />,
      childrens: [],
      slug: "/",
      subChid: false,
    },
    {
      title: "Payroll History",
      icon: <PayrollIcon />,
      childrens: [],
      slug: "/userPayrollHistory",
      subChid: false,
    },
    {
      title: "Claims",
      icon: <CheckIcon />,
      slug: "/user-claims-list",
      subChid: true,
      childrens: [
        {
          title: "My Claims",
          icon: "",
          slug: "/user-claims-list",
        },
        {
          title: "My Approvals",
          icon: "",
          slug: "/user-claim-approval",
        },
      ],
    },
    {
      title: "Report",
      icon: <ReportIcon />,
      slug: "/cpf-report-user",
      subChid: true,
      childrens: [
        {
          title: "CPF Report",
          icon: "",
          slug: "/cpf-report-user",
        },
      ],
    },
    {
      title: "Activity Log",
      icon: <ReportIcon />,
      childrens: [],
      slug: "/user-activity-log",
      subChid: false,
    },
  ];
  return (
    <div className="sidebar">
      {items.map((item, index) => (
        <SidebarItem key={index} item={item} />
      ))}
    </div>
  );
}
