import React, { useState, useEffect } from "react";
import { CloseIcon, DropDownIcon, DropUpIcon } from "../../assets";
import { thousandAndDecimalFormat } from "../../utils/common";

const NetPayDetail = ({ setOpen2, data }) => {
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);
  const [show3, setShow3] = useState(true);
  return (
    <div className="statutory-detail flex flex-col">
      <div className="detail-wrap flex flex-col">
        <div className="detail-hdr flex flex-col">
          <div className="hdr-top flex aic">
            <div className="page-tag flex aic jc flex-1 text-center">
              Total Net Pay
            </div>
            <div
              className="icon cursor-pointer"
              onClick={(e) => setOpen2(false)}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="hdr-btm flex aic pt-5 pb-1">
            <div className="le flex flex-1">Total User</div>
            <div className="re flex">{data ? thousandAndDecimalFormat(data?.totalUser, false) : thousandAndDecimalFormat(0, true, 2)}</div>
          </div>
        </div>
        <div className="detail-box flex flex-col pt-6">
          <div className="total-emp flex flex-col">
            <div className="row-head flex aic cursor-pointer">
              <div className="row-left flex flex-1 aic ">Total Salary</div>
              <div className="row-right flex flex-[0.6] justify-between">
                <span className="sign">$</span>
                <span className="numb">{data ? thousandAndDecimalFormat(data?.totalSalary, true, 2) : thousandAndDecimalFormat(0, true, 2)}</span>
              </div>
            </div>
          </div>

          <div className="total-emp flex flex-col">
            <div className="row-head flex aic cursor-pointer">
              <div className="row-left flex flex-1 aic ">Total Overtime</div>
              <div className="row-right flex flex-[0.6] justify-between">
                <span className="sign">$</span>
                <span className="numb">
                  {data ? thousandAndDecimalFormat(data?.totalOvertimes, true, 2) : thousandAndDecimalFormat(0, true, 2)}
                </span>
              </div>
            </div>
          </div>

          <div className="total-emp flex flex-col">
            <div
              className="row-head flex aic cursor-pointer"
              onClick={(e) => setShow(!show)}
            >
              <div className="row-left flex flex-1 aic ">
                Total Components
                <span className="ml-2">
                  {show ? <DropUpIcon /> : <DropDownIcon />}
                </span>
              </div>
              <div className="row-right flex flex-[0.6] justify-between">
                <span className="sign">$</span>
                <span className="numb">
                  {" "}
                  {data ? thousandAndDecimalFormat(data?.totalComponents?.total, true, 2) : thousandAndDecimalFormat(0, true, 2)}
                </span>
              </div>
            </div>
            <div className={`row-list flex flex-col ${show ? "show" : ""}`}>
              {data?.totalComponents?.childData?.map((item, i) => (
                <div key={i} className="row flex aic">
                  <div className="row-le flex">{item?.display}</div>
                  <div className="row-re flex">$ {thousandAndDecimalFormat(item?.totalValue, true, 2)} </div>
                </div>
              ))}
            </div>
          </div>

          <div className="total-emp flex flex-col">
            <div
              className="row-head flex aic cursor-pointer"
              onClick={(e) => setShow2(!show2)}
            >
              <div className="row-left flex flex-1 aic ">
                Total Deductions
                <span className="ml-2">
                  {show2 ? <DropUpIcon /> : <DropDownIcon />}
                </span>
              </div>
              <div className="row-right flex flex-[0.6] justify-between">
                <span className="sign">$</span>
                <span className="numb">
                  {data ? thousandAndDecimalFormat(data?.totalDeductions?.total, true, 2) : thousandAndDecimalFormat(0, true, 2)}
                </span>
              </div>
            </div>
            <div className={`row-list flex flex-col ${show2 ? "show" : ""}`}>
              {data?.totalDeductions?.childData?.map((item, i) => (
                <div key={i} className="row flex aic">
                  <div className="row-le flex">{item?.display}</div>
                  <div className="row-re flex">$ {thousandAndDecimalFormat(item?.totalValue, true, 2)} </div>
                </div>
              ))}
            </div>
          </div>

          <div className="total-emp flex flex-col">
            <div className="row-head flex aic cursor-pointer">
              <div className="row-left flex flex-1 aic ">Total Claims </div>
              <div className="row-right flex flex-[0.6] justify-between">
                <span className="sign">$</span>
                <span className="numb">{data ? thousandAndDecimalFormat(data?.totalClaims, true, 2) : thousandAndDecimalFormat(0, true, 2)}</span>
              </div>
            </div>
          </div>

          {/* <div className="total-emp flex flex-col">
            <div
              className="row-head flex aic cursor-pointer"
              onClick={(e) => setShow3(!show3)}
            >
              <div className="row-left flex flex-1 aic ">
                Total Employee Contributions
                <span className="ml-2">
                  {show3 ? <DropUpIcon /> : <DropDownIcon />}
                </span>
              </div>
              <div className="row-right flex flex-[0.6] justify-between">
                <span className="sign">$</span>
                <span className="numb">(1,450.00)</span>
              </div>
            </div>
            <div className={`row-list flex flex-col ${show3 ? "show" : ""}`}>
              <div className="row flex aic">
                <div className="row-le flex">Total CPF Employee</div>
                <div className="row-re flex">$ (1250.00)</div>
              </div>
            </div>
          </div> */}

          <div className="total-emp flex flex-col">
            <div className="row-head flex aic cursor-pointer">
              <div className="row-left flex flex-1 aic ">Total Net Pay</div>
              <div className="row-right flex flex-[0.6] justify-between">
                <span className="sign">$</span>
                <span className="numb">{data ? thousandAndDecimalFormat(data?.totalNetPay, true, 2) : thousandAndDecimalFormat(0, true, 2)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetPayDetail;
