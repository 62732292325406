import React from "react";

function AddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        fill="#4E7A7D"
        d="M16.008 2.36h-8c-4 0-6 2-6 6v13c0 .55.45 1 1 1h13c4 0 6-2 6-6v-8c0-4-2-6-6-6zm-.5 10.75h-2.75v2.75c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-2.75h-2.75c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h2.75V8.86c0-.41.34-.75.75-.75s.75.34.75.75v2.75h2.75c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
    </svg>
  );
}

export default AddIcon;