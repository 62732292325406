import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { DropDownIcon } from "../../assets";
import Loader from "../../components/Loader";
import { useGetHrDashboardDataQuery } from "../../store/services/dashboardService";
import { thousandAndDecimalFormat } from "../../utils/common";

const Main = () => {
  const {
    data: hrDashboardData,
    isLoading: hrDashboardDataLoading,
    isFetching: hrDashboardDataFetching,
  } = useGetHrDashboardDataQuery();

  const [date, setDate] = useState(new Date().getFullYear());

  const [hide, setHide] = useState(false);
  const [selectedCompany, setselectedcompany] = useState();
  const [statusData, setStatusData] = useState([
    { id: 1, title: "2019" },
    { id: 2, title: "2020" },
    { id: 3, title: "2021" },
    { id: 4, title: "2022" },
    { id: 5, title: "2023" },
  ]);
  useEffect(() => {
    document.addEventListener("click", () => {
      setHide(false);
    });
  }, []);
  return (
    <div className="home-page flex flex-col">
      <div className="wrapWidth wrap flex flex-col">
        {!hrDashboardDataFetching ? (
          <div className="reports">
            <div className="item flex flex-col">
              <div className="blk flex flex-col justify-between">
                <div className="hdr flex aic">
                  <div className="title">Last Ran Payroll</div>
                </div>
                <div className="numbs flex aic jc">
                  <div className="num">
                    {hrDashboardData?.data?.payrollStatus?.lastRanPayroll ? (
                      <div>
                        { hrDashboardData?.data?.payrollStatus?.lastRanPayroll}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className="action flex justify-end">
                  <Link to="/payroll" className="btn button">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="item flex flex-col">
              <div className="blk flex flex-col justify-between">
                <div className="hdr flex aic">
                  <div className="title flex aic flex-1">
                    Total Disbursement in
                  </div>
                  <div className="current-year flex aic jc">
                    {date ? date : "-"}
                  </div>
                  {/* <div className="dropDown flex aic jc flex-col rel flex-[0.3]">
                  <div className="category flex aic">
                    <div
                      className="cbox cleanbtn flex aic rel"
                      onClick={(e) => {
                        e.stopPropagation();
                        setHide(!hide);
                      }}
                    >
                      <div className="slt flex aic">
                        <div className="unit-name flex aic font s14 b4">
                          <span
                            className="unit-eng flex aic font s14 b4"
                            placeholder="2021"
                          >
                            {selectedCompany ? selectedCompany : "2021"}
                          </span>
                        </div>
                      </div>

                      <div>
                        <DropDownIcon />
                      </div>
                    </div>
                  </div>
                  <div className={`block flex aic abs ${hide ? "show" : ""}`}>
                    <div className="manue flex aic col anim">
                      {statusData.map((item, index) => (
                        <div
                          key={index}
                          className="slt flex aic"
                          onClick={(e) => {
                            setHide(!hide);
                            setselectedcompany(item.title);
                          }}
                        >
                          <div className="unit-name flex aic font s14 b4">
                            <span className="unit-eng flex aic font s14 b4">
                              {item.title}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div> */}
                </div>
                <div className="numbs flex aic jc">
                  <div className="num">
                    {hrDashboardData?.data?.totalReimbursementIn
                      ? thousandAndDecimalFormat(hrDashboardData?.data?.totalReimbursementIn?.[`${date}`], true, 2)
                      : "-"}{" "}
                    SGD
                  </div>
                </div>
                <div className="action flex justify-end opacity-0">
                  <div className="btn button">View Details</div>
                </div>
              </div>
            </div>
            <div className="item flex flex-col">
              <div className="blk flex flex-col">
                <div className="row flex aic">
                  <div className="lbl flex aic">
                    Number of completed Payrolls
                  </div>
                  <div className="numb">
                    {hrDashboardData?.data?.payrollStatus?.numberOfComplete
                      ? thousandAndDecimalFormat(hrDashboardData?.data?.payrollStatus?.numberOfComplete, false)
                      : "-"}
                  </div>
                </div>
                <div className="row flex aic">
                  <div className="lbl flex aic">
                    Number of processes Payrolls
                  </div>
                  <div className="numb">
                    {hrDashboardData?.data?.payrollStatus?.numberOfProcess
                      ? thousandAndDecimalFormat(hrDashboardData?.data?.payrollStatus?.numberOfProcess, false)
                      : "-"}
                  </div>
                </div>
                <div className="row flex aic">
                  <div className="lbl flex aic">Upcoming Payroll Date</div>
                  <div className="numb">
                    {hrDashboardData?.data?.payrollStatus?.upcomingDate ? (
                      <div>
                        {`${moment(
                          hrDashboardData?.data?.payrollStatus?.upcomingDate
                        ).format("D")}-${moment(
                          hrDashboardData?.data?.payrollStatus?.upcomingDate
                        ).format("MM")}-${moment(
                          hrDashboardData?.data?.payrollStatus?.upcomingDate
                        ).format("YYYY")}`}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>

                <div className="action flex justify-end">
                  <Link to="/payroll" className="btn button">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="item flex flex-col">
              <div className="blk flex flex-col justify-between">
                <div className="hdr flex aic">
                  <div className="title">Total Users</div>
                </div>
                <div className="numbs flex aic jc">
                  <div className="num">
                    {hrDashboardData?.data?.totalUsers
                      ? thousandAndDecimalFormat(hrDashboardData?.data?.totalUsers, false)
                      : "-"}
                  </div>
                </div>
                <div className="action flex justify-end">
                  <Link to="/users" className="btn button">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default Main;
