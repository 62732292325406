import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Datetime from "react-datetime";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import { useUpdatePayrollProcessMutation } from "../../store/services/payrollService";
import Loader from "../../components/Loader";
import { CalendarIcon, CloseIcon } from "../../assets";

const EditPayrollProcess = ({ setOpenEdit, selectedData }) => {
  const navigate = useNavigate();
  const { payRollUid } = useSelector((state) => state.globalReducer);


  const [startDate, setStartDate] = useState(new Date());

  const today = new Date();
  // const day = "";
  // const month = "";
  // const year = "";

  const [updatePayrollUser, setUpdatePayrollUser] = useState({
    label: {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      name: ""
    },
    workPeriod: {
      from: today,
      to: today
    },
    payDate: today
  });


  useEffect(() => {
    if (selectedData) {
      setUpdatePayrollUser({
        uId: selectedData.uId,
        label: {
          year: new Date(selectedData.label.year).getFullYear(),
          month: new Date(selectedData.label.month).getMonth(),
          name: selectedData.label.name,
        },
        workPeriod: {
          from: new Date(selectedData.workPeriod.from),
          to: new Date(selectedData.workPeriod.to)
        },
        payDate: new Date(selectedData.payDate)
      });
      setStartDate(new Date(selectedData.label.year, selectedData.label.month));
    }
  }, [selectedData]);

  const changeLabelDate = (value) => {
    setStartDate(new Date(value));
    setUpdatePayrollUser((prevState) => ({
      ...prevState,
      label: {
        ...prevState.label,
        year: new Date(value).getFullYear(),
        month: new Date(value).getMonth() + 1
      }
    }));
  };

  const changeLabelName = (value) => {
    setUpdatePayrollUser((prevState) => ({
      ...prevState,
      label: {
        ...prevState.label,
        name: value
      }
    }));
  };

  
  const hangleWorkPeriodDateChange = (e, fieldName) => {
    const newChangedDate = new Date(e);
    setUpdatePayrollUser((prevState) => ({
      ...prevState,
      workPeriod: {
        ...prevState.workPeriod,
        [`${fieldName}`]: newChangedDate,
      }
    }));
  };
  
  const hanglePayDateChange = (e) => {
    const newChangedDate = new Date(e);
    setUpdatePayrollUser((prevState) => ({
      ...prevState,
      payDate: newChangedDate
    }));
  };

  const [savePayroll, data] = useUpdatePayrollProcessMutation();

  const errors = data?.error?.data ? data?.error?.data.message : null;
  const [errorMessage, setErrorMessage] = useState(null);

  const updatePayroll = (e) => {
    e.preventDefault();

    if (updatePayrollUser?.label?.name === "") {
      setErrorMessage("Please enter Label");
    } else {
      savePayroll(updatePayrollUser);
    }
  };

  useEffect(() => {
    if (data?.isSuccess) {
      setOpenEdit(false);
    }
  }, [data?.isSuccess]);

  return (
    <div className="edit-payroll-process flex">
      <div className="wrap flex flex-col w-full">
        <div className="heading-tag flex items-center justify-between">
          <div className="box-tag">Edit Payroll Process</div>
          <div
            className="icon cursor-pointer flex items-center justify-center"
            onClick={(e) => setOpenEdit(false)}
          >
            <CloseIcon />
          </div>
        </div>
        
        {errorMessage !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errorMessage}
          </div>
        )}
        <div className="hdr-filters flex flex-col">
          <div className="row">
            <div className="row-field flex flex-col">
              <div className="page-tag mb-3">Payroll Label</div>
              <div className="input-field flex aic">
                <div className="date-picker flex aic jc">
                  <Datetime
                    closeOnSelect={true}
                    value={startDate}
                    onChange={(value) => changeLabelDate(value)}
                    timeFormat={false}
                    dateFormat="yyyy-MM"
                    className="start-date cleanbtn"
                  />
                  <div className="ico">
                    <CalendarIcon />
                  </div>
                </div>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={updatePayrollUser.label.name}
                  onChange={(e) => changeLabelName(e.target.value)}
                  className="txt"
                  placeholder="Label"
                />
              </div>
            </div>
            <div className="row-field flex flex-col">
              <div className="page-tag mb-3">Work Period</div>
              <div className="input-field flex aic">
                <div className="date-field flex aic">
                  <div className="lbl mr-2 s14">From</div>
                  <div className="date-picker flex aic jc">
                    <Datetime
                      closeOnSelect={true}
                      value={updatePayrollUser.workPeriod.from}
                      onChange={(value) => {
                        hangleWorkPeriodDateChange(new Date(value), "from");
                      }}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      className="start-date cleanbtn"
                    />
                    <div className="ico">
                      <CalendarIcon />
                    </div>
                  </div>
                </div>
                <div className="date-field flex aic">
                  <div className="lbl ml-5 mr-2 s14">To</div>
                  <div className="date-picker flex aic jc">
                    <Datetime
                      closeOnSelect={true}
                      value={updatePayrollUser.workPeriod.to}
                      onChange={(value) => {
                        hangleWorkPeriodDateChange(new Date(value), "to");
                      }}
                      timeFormat={false}
                      dateFormat="DD-MM-YYYY"
                      className="start-date cleanbtn"
                    />
                    <div className="ico">
                      <CalendarIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row-field flex flex-col">
              <div className="page-tag mb-3">Pay Date</div>
              <div className="input-field flex aic">
                <div className="date-picker flex aic jc">
                  <Datetime
                    closeOnSelect={true}
                    value={updatePayrollUser.payDate}
                    onChange={(value) => {
                      hanglePayDateChange(new Date(value));
                    }}
                    timeFormat={false}
                    dateFormat="DD-MM-YYYY"
                    className="start-date cleanbtn"
                  />
                  <div className="ico">
                    <CalendarIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="actions flex items-center justify-end">
          <div className="btn button" onClick={(e) => setOpenEdit(false)}>
            Cancel
          </div>
          <div className="btn button" onClick={(e) => updatePayroll(e)}>
            Update
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPayrollProcess;
