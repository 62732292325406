import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Datetime from "react-datetime";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  CalendarIcon,
  AddIcon,
  EditIcon,
  DelIcon,
  EyeIcon,
  UserAddIcon,
} from "../../assets";

import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import ClaimDelete from "../../components/UserSide/ClaimDetailDelete";

import { useCreateClaimFormMutation } from "../../store/services/claimsService";
import { thousandAndDecimalFormat } from "../../utils/common";

const NewClaimForm = () => {
  const { userRole, userToken, userOrgId, userId } = useSelector(
    (state) => state.authReducer
  );
  const navigate = useNavigate();

  const [tblDataList, setTblDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [claimDetailId, setClaimDetailId] = useState();
  const [date, setDate] = useState(new Date().getTime());
  const [claimFormData, setClaimFormData] = useState({
    requestDate: localStorage.getItem("claimRequestDate") ? JSON.parse(localStorage.getItem("claimRequestDate")) : new Date().getTime(),
    details: JSON.parse(localStorage.getItem("claimDetailIds")),
  });

  // Handle remove Row
  const removeDetailId = (index) => {
    if (claimFormData?.details.length) {
      let newDetailsArr = [...claimFormData?.details];
      newDetailsArr = newDetailsArr?.filter((el, i) => el !== index);
      localStorage.setItem("claimDetailIds", JSON.stringify(newDetailsArr));
      window.location.reload();
      setClaimFormData({ ...claimFormData, details: newDetailsArr });
    }
  };

  const [createClaimForm, data] = useCreateClaimFormMutation();
  const errors = data?.error?.data ? data?.error?.data.message : null;

  useEffect(() => {
    if (claimFormData?.details?.length > 0) {
      try {
        axios.get(
          `${process.env.REACT_APP_API_URL}/api/claim/detail/list`,
          {
            headers: {
              authorization: userToken ? userToken : "",
              orgid: userOrgId ? userOrgId : "",
            },
            params: {
              ids: claimFormData?.details
            }
          }
        ).then((resp) => {
          setLoading(true);
          if (resp.data) {
            setTblDataList(resp.data?.data);
            setLoading(false);
          } else {
          }
        })
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  const createClaimFormApi = (e) => {
    e.preventDefault();
    createClaimForm(claimFormData);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      localStorage.removeItem("claimDetailIds");
      localStorage.removeItem("claimRequestDate");
      navigate("/user-claims-list");
    }
  }, [data?.isSuccess]);

  const sum = tblDataList
    ?.map((item) => item.amount)
    .reduce((prev, curr) => prev + curr, 0);

  console.log(claimFormData)
  return (
    <div className="users-claims-form flex">
      <div className="wrap wrapWidth flex flex-col">
        {errors !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        <div className="page-hdr flex justify-between items-center">
          <div className="field flex flex-col">
            <div className="lbl mb-2">Request Date</div>
            <div className="date-picker flex aic jc">
              <Datetime
                closeOnSelect={true}
                value={claimFormData.requestDate ? claimFormData.requestDate : new Date().getTime()}
                onChange={(value) => {
                  localStorage.setItem("claimRequestDate", new Date(value).getTime());
                  setClaimFormData({
                    ...claimFormData,
                    requestDate: new Date(value).getTime(),
                  });
                  setDate(new Date(value).getTime());
                }}
                timeFormat={false}
                dateFormat="DD-MM-YYYY"
                className="start-date cleanbtn"
              />
              <div className="ico">
                <CalendarIcon />
              </div>
            </div>
          </div>
          <div className="page-tag s20 b6 pr-6">Claim Form</div>
        </div>

        <>
          <div className="table-blk flex">
            {!loading ? (
              <div className="tbl flex flex-col">
                <div className="row flex aic">
                  <div className="col flex">
                    <div className="col-item">Receipt Date</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Description</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Amount</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">Proof</div>
                  </div>
                  <div className="col flex">
                    <div className="col-item">
                      <Link
                        to={`/add-new-claim`}
                        className="btn cursor-pointer"
                      >
                        New
                      </Link>
                    </div>
                  </div>
                </div>
                {tblDataList?.length ? (
                  tblDataList?.map((item, index) => (
                    <div key={index} className="row flex aic">
                      <div className="col flex">
                        <div className="col-item">
                          {`${moment(item.receiptDate).format("D")}-${moment(
                            item.receiptDate
                          ).format("MM")}-${moment(item.receiptDate).format(
                            "YYYY"
                          )}`}
                        </div>
                      </div>
                      <div className="col flex">
                        <div className="col-item">{item.description}</div>
                      </div>
                      <div className="col flex">
                        <div className="col-item">{thousandAndDecimalFormat(item.amount, true, 2)}</div>
                      </div>
                      <div className="col flex">
                        <div className="col-item underline cursor-pointer" onClick={() => {
                          if (item.proof && item.proof !== "undefined") {
                            window.open(item.proof, "_blank");
                          }
                        }}>
                          {(item.proof && item.proof !== "undefined") ? <a href={item.proof} target="_blank">
                              <EyeIcon />
                            </a> : "Null"}
                        </div>
                      </div>
                      <div className="col flex">
                        <div className="col-item flex aic jc">
                          <Link
                            to={`/edit-user-claim/${item?.uId}`}
                            className="icon cursor-pointer"
                          >
                            <EditIcon />
                          </Link>
                          <div
                            className="icon cursor-pointer"
                            onClick={(e) => removeDetailId(item?._id)}
                            // onClick={(e) => {
                            //   setClaimDetailId({ uId: item.uId });
                            //   setOpen(true);
                            // }}
                          >
                            <DelIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : tblDataList?.length == 0 ? (
                  <div className="flex items-center justify-center h-52 text-base font-medium">
                    No Data Found
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            ) : (
              <Loader />
            )}
          </div>

          <div className="page-footer flex justify-between my-6">
            <div className="amount flex aic ">
              <div className="lbl mr-1">Total Amount:</div>
              <div className="val b7 s20">{thousandAndDecimalFormat(sum, true, 2)} SGD</div>
            </div>
            <div className="actions">
              <div
                className="btn button"
                onClick={(e) => createClaimFormApi(e)}
              >
                Save
              </div>
            </div>
          </div>
        </>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ClaimDelete setOpen={setOpen} data={claimDetailId} />
      </Modal>
    </div>
  );
};

export default NewClaimForm;
