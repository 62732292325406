import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { DropDownIcon } from "../../assets";
import Loader from "../Loader";
import { useGetQuery } from "../../store/services/generalService";
import {
  useFetchCpfAcQuery,
  useUpdateCpfAcMutation,
} from "../../store/services/cpfAccountService";

const CreateNewCpfAc = () => {
  const navigate = useNavigate();
  const { uId } = useParams();
  const { data, isFetching } = useFetchCpfAcQuery(uId);
  

  const { data: options, isLoading } = useGetQuery();
  
  const [employeeTypeList, setEmployeeTypeList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [createCpfAccount, setCreateCpfAccount] = useState({
    accountNo: "",
    submissionNo: "",
    organizationId: "",
    employeeType: "",
    adviseCode: "",
    paymentCode: "",
    serialNo: "",
    sectorType: "",
  });
  

  const handleInput = (e) => {
    setCreateCpfAccount({
      ...createCpfAccount,
      [e.target.name]: e.target.value,
    });
  };
  

  useEffect(() => {
    if (!isLoading) {
      setEmployeeTypeList(options?.data.userStatuses);
      setSectorList(options?.data.sectorList);
    }
  }, [options?.data]);

  useEffect(() => {
    if (!isFetching) {
      setCreateCpfAccount(data?.data);
    }
  }, [data?.data]);


  const [updateCPFAc, response] = useUpdateCpfAcMutation();
  

  const errors = response?.error?.data ? response?.error?.data.message : null;

  const updateCpfBankAccount = (e) => {
    e.preventDefault();
    updateCPFAc({ createCpfAccount, uId });
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/cpf-account");
    }
  }, [response?.isSuccess]);

  const handleChange = (type,e) => {
    const targetField = type === "status" ? "employeeType" : "sectorType";
    setCreateCpfAccount({
      ...createCpfAccount,
      [`${targetField}`] : e.value
    });
  };

  return (
    <form onSubmit={updateCpfBankAccount} className="new-department flex">
      <div className="wrap wrapWidth flex flex-col">
        {!isFetching ? (
          <>
            {errors !== null && (
              <div className="flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
                {errors}
              </div>
            )}
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">CPF Account</div>
                <input
                  id="accountNo"
                  name="accountNo"
                  value={createCpfAccount.accountNo}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="CPF Account"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">CPF Submission No.</div>
                <input
                  id="submissionNo"
                  name="submissionNo"
                  value={createCpfAccount.submissionNo}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="CPF Submission No."
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Organization ID</div>
                <input
                  id="organizationId"
                  name="organizationId"
                  value={createCpfAccount.organizationIdNo}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="0000-0"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Employee Type</div>
                <Select
                  value={employeeTypeList.filter(function(option) {
                    return option.value === createCpfAccount.employeeType;
                  })}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  onChange={event => handleChange("status", event)}
                  className="select"
                  id="employeeType"
                  name="employeeType"
                  placeholder="Select Type of Employee"
                  options={employeeTypeList}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Advise Code</div>
                <input
                  id="adviseCode"
                  name="adviseCode"
                  value={createCpfAccount.adviseCode}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="0000-0"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Payment Code</div>
                <input
                  id="paymentCode"
                  name="paymentCode"
                  value={createCpfAccount.paymentCode}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="00000"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Serial No.</div>
                <input
                  id="serialNo"
                  name="serialNo"
                  value={createCpfAccount.serialNo}
                  onChange={handleInput}
                  type="text"
                  className="txt"
                  placeholder="000000"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Sector Type</div>
                <Select
                  value={sectorList.filter(function(option) {
                    return option.value === createCpfAccount.sectorType;
                  })}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  onChange={event => handleChange("sector", event)}
                  options={sectorList}
                  className="select"
                  id="sectorType"
                  name="sectorType"
                  placeholder="Select Sector Type"
                />
              </div>
            </div>
            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={response.isLoading ? "Loading..." : "Update"}
                className="btn button"
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </form>
  );
};

export default CreateNewCpfAc;
