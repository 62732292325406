import React from "react";

function SettingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path
        fill="#fff"
        d="M27.727 12.293c-2.413 0-3.4-1.707-2.2-3.8a2.529 2.529 0 00-.933-3.454l-2.307-1.32c-1.053-.626-2.413-.253-3.04.8l-.147.254c-1.2 2.093-3.173 2.093-4.386 0l-.147-.254a2.188 2.188 0 00-3.013-.8L9.247 5.04c-1.213.694-1.627 2.254-.933 3.467 1.213 2.08.226 3.787-2.187 3.787a2.54 2.54 0 00-2.533 2.533v2.347a2.54 2.54 0 002.533 2.533c2.413 0 3.4 1.707 2.187 3.8a2.529 2.529 0 00.933 3.453l2.307 1.32c1.053.627 2.413.254 3.04-.8l.146-.253c1.2-2.093 3.174-2.093 4.387 0l.147.253c.626 1.054 1.986 1.427 3.04.8l2.306-1.32a2.532 2.532 0 00.934-3.453c-1.214-2.093-.227-3.8 2.186-3.8a2.54 2.54 0 002.534-2.533v-2.347c-.014-1.387-1.147-2.533-2.547-2.533zm-10.8 8.04a4.342 4.342 0 01-4.333-4.334 4.342 4.342 0 014.333-4.333 4.342 4.342 0 014.333 4.333 4.342 4.342 0 01-4.333 4.334z"
      ></path>
    </svg>
  );
}

export default SettingIcon;
