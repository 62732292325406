import React from "react";

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5.25 5.25A1.75 1.75 0 003.5 7v21A1.75 1.75 0 007 28V7a1.75 1.75 0 00-1.75-1.75zm18.013 16.263a1.75 1.75 0 002.474 2.474l5.25-5.25a1.75 1.75 0 000-2.474l-5.25-5.25a1.749 1.749 0 00-3.008 1.23 1.75 1.75 0 00.534 1.244l2.262 2.263H12.25a1.75 1.75 0 000 3.5h13.276l-2.263 2.263z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LogoutIcon;
