import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import DataTable from 'react-data-table-component';
import {
  SearchIcon,
  EditIcon,
  UserAddIcon,
  DownloadIcon,
  CalendarIcon,
} from "../../assets";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";

import { useGetCpfReportQuery } from "../../store/services/reportService";
import { thousandAndDecimalFormat, exportXlsx } from "../../utils/common";

const CPFReport = () => {
  const [allData , setData] = useState([]);
  const [filteredData , setFilteredData] = useState([]);

  const [fromDate, setFromDate] = useState(new Date(moment().startOf('month').format('MM/DD/YYYY hh:mm')));
  const [toDate, setToDate] = useState(new Date(moment().endOf('month').format('MM/DD/YYYY hh:mm')));

  const { data, isFetching, isLoading } = useGetCpfReportQuery({
    fromDate,
    toDate,
  });

  const handleFromDate = (date) => {
    setFromDate(new Date(moment(date).startOf('day').format('MM/DD/YYYY hh:mm')));
  };

  const handleToDate = (date) => {
    setToDate(new Date(moment(date).endOf('day').format('MM/DD/YYYY hh:mm')));
  };

  const onExport = () => {
    const arr = data?.data?.map((item) => (
      {
        Period: moment(item.payDate).format("DD-MM-YYYY"),
        [`OW/Salary`]: thousandAndDecimalFormat(item.salary, true, 2),
        [`AW/Additional Earning`]: thousandAndDecimalFormat(item.additionalEarning, true, 2),
        TW: thousandAndDecimalFormat(item.totalWage, true, 2),
        CPF: thousandAndDecimalFormat(item.cpf, true, 2),
        MBMF: thousandAndDecimalFormat(item.mbmf, true, 2),
        SINDA: thousandAndDecimalFormat(item.sinda, true, 2),
        CDAC: thousandAndDecimalFormat(item.cdac, true, 2),
        ECF: thousandAndDecimalFormat(item.ecf, true, 2),
      }
    ));

    const colWidth = [ {wch: 15}, {wch: 20}, {wch: 20}, {wch: 20}, {wch: 20}, {wch: 20}, {wch: 20}, {wch: 20}, {wch: 20} ];
    exportXlsx(arr, colWidth, "Sheet1", `CPF Report_${moment(fromDate).format("DD-MM-YYYY")}_${moment(toDate).format("DD-MM-YYYY")}`);
  }

  const searchFieldIs = [
    "additionalEarning",
    "cdac",
    "cpf",
    "ecf",
    "mbmf",
    "payDate",
    "salary",
    "sinda",
    "totalWage"
  ];
  const searchText = (target, value) => {
    return String(target).toLowerCase().indexOf(value) > -1 ? true : false;
  }
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    if (event.target.value !== "") {
      let result = [];
      result = allData.filter((data) => {
        let flag = false;
        for (let i = 0; i < searchFieldIs.length; i++) {
          const targetField = searchFieldIs[i];
          if (data[`${targetField}`]) {
            if (targetField === "payDate") {
              const dateTarget = new Date(data[`${targetField}`]);
              const dateString = String(`${dateTarget.getDate()}-${dateTarget.getMonth()+1}-${dateTarget.getFullYear()}`);
              flag = searchText(dateString, value);
              if (flag) break;
            } else {
              flag = searchText(data[`${targetField}`], value);
              if (flag) break;
            }
          }
        }
        if (flag) return data
      });
      setFilteredData(result);
    } else {
      setFilteredData(allData);
    }
  }

  useEffect(() => {
    if (!isFetching) {
      setData(data?.data);
      setFilteredData(data?.data);
    }
  }, [data?.data]);

  const renderColumns = () => {
    return [
        {
            name: "Period",
            sortable: true,
            cell: row => (
              <div>
                {`${moment(row.payDate).format("D")}-${moment(
                  row.payDate
                ).format("MM")}-${moment(row.payDate).format(
                  "YYYY"
                )}`}
              </div>
            ),
        },
        {
            name: "OW/Salary",
            sortable: true,
            cell: row => (
              <div>
                {thousandAndDecimalFormat(row.salary, true, 2)}
              </div>
            )
        },
        {
            name: "AW/Additional Earning",
            sortable: true,
            cell: row => (
              <div>
                {thousandAndDecimalFormat(row.additionalEarning, true, 2)}
              </div>
            )
        },
        {
            name: "TW",
            sortable: true,
            cell: row => (
              <div>
                {thousandAndDecimalFormat(row.totalWage, true, 2)}
              </div>
            )
        },
        {
            name: "CPF",
            sortable: true,
            cell: row => (
              <div>
                {thousandAndDecimalFormat(row.cpf, true, 2)}
              </div>
            )
        },
        {
            name: "MBMF",
            sortable: true,
            cell: row => (
              <div>
                {thousandAndDecimalFormat(row.mbmf, true, 2)}
              </div>
            )
        },
        {
            name: "SINDA",
            sortable: true,
            cell: row => (
              <div>
                {thousandAndDecimalFormat(row.sinda, true, 2)}
              </div>
            )
        },
        {
            name: "CDAC",
            sortable: true,
            cell: row => (
              <div>
                {thousandAndDecimalFormat(row.cdac, true, 2)}
              </div>
            )
        },
        {
            name: "ECF",
            sortable: true,
            cell: row => (
              <div>
                {thousandAndDecimalFormat(row.ecf, true, 2)}
              </div>
            )
        }
    ];
  };
  return (
    <div className="users-page felx">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-hdr flex">
          <div className="hdr-right flex flex-1 aic">
            <div className="field flex aic mr-3">
              <div className="lbl">From</div>
              <div className="date-picker flex aic jc">
                <Datetime
                  closeOnSelect={true}
                  value={fromDate ? fromDate : new Date()}
                  onChange={(value) => {
                    // setFromDate(new Date(value));
                    handleFromDate(new Date(value));
                  }}
                  timeFormat={false}
                  dateFormat="DD-MM-YYYY"
                  className="start-date cleanbtn"
                />
                <div className="ico">
                  <CalendarIcon />
                </div>
              </div>
            </div>
            <div className="field flex aic ">
              <div className="lbl">To</div>
              <div className="date-picker flex aic jc ">
                <Datetime
                  closeOnSelect={true}
                  value={toDate ? toDate : new Date()}
                  onChange={(value) => {
                    handleToDate(new Date(value));
                    // setToDate(value);
                  }}
                  timeFormat={false}
                  dateFormat="DD-MM-YYYY"
                  className="start-date cleanbtn"
                />
                <div className="ico">
                  <CalendarIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="hdr-righ flex aic justify-end">
            <div className="search-box flex aic">
              <input
                type="text"
                className="txt cleanbtn cursor-pointer"
                placeholder="Search here"
                onChange={(event) =>handleSearch(event)}
              />
              <div className="icon flex aic jc">
                <SearchIcon />
              </div>
            </div>
            <div className="btn-export button flex aic jc" onClick={() => onExport()}>
              Export <DownloadIcon />
            </div>
          </div>
        </div>
        <div className="table-blk flex">
          <div className="tbl flex flex-col">
            {!isLoading || !isFetching ? (
              <>
              <DataTable
                columns={renderColumns()}
                data={filteredData}
                responsive={true}
                pagination={true} />
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <div className="short-words-desc flex">
          <div className="left flex flex-1">
            <div className="info-words">
              <div className="info-item flex aic">
                <div className="short-key">OW</div>
                <div className="key-desc">Ordinary Wages</div>
              </div>
              <div className="info-item flex aic">
                <div className="short-key">MBMF</div>
                <div className="key-desc">Mosque Building and Mendaki Fund</div>
              </div>
              <div className="info-item flex aic">
                <div className="short-key">AW</div>
                <div className="key-desc">Additional Wages</div>
              </div>
              <div className="info-item flex aic">
                <div className="short-key">SINDA</div>
                <div className="key-desc">
                  Singapore Indian Development Association
                </div>
              </div>
              <div className="info-item flex aic">
                <div className="short-key">TW</div>
                <div className="key-desc">Total Wages</div>
              </div>
              <div className="info-item flex aic">
                <div className="short-key">CDAC</div>
                <div className="key-desc">
                  Chinese development assistance council
                </div>
              </div>
              <div className="info-item flex aic">
                <div className="short-key">CPF</div>
                <div className="key-desc">Central Provident Fund</div>
              </div>
              <div className="info-item flex aic">
                <div className="short-key">ECF</div>
                <div className="key-desc">Eurasian Coummnity Fund</div>
              </div>
            </div>
          </div>
          <div className="right flex flex-[0.4]"></div>
        </div>
      </div>
    </div>
  );
};

export default CPFReport;
