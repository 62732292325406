import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import { DropDownIcon, CalendarIcon, AttachmentIcon } from "../../assets";

import Loader from "../../components/Loader";
import {
  useCreateNewClaimDetailMutation,
  useUpdateClaimMutation,
} from "../../store/services/claimsService";

const AddNewClaimForUpdate = () => {
  const { claimFormId } = useSelector((state) => state.globalReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date().getTime());
  const [file, setFile] = useState();

  const [createClaimForm, setCreateClaimForm] = useState({
    receiptDate: new Date(),
    amount: 0,
    description: "",
    proof: "",
  });

  const handleInput = (e) => {
    setCreateClaimForm({ ...createClaimForm, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setCreateClaimForm({ ...createClaimForm, proof: file });
  }, [file]);

  const [saveNewClaimForm, data] = useCreateNewClaimDetailMutation();
  const errors = data?.error?.data ? data?.error?.data.message : null;

  const createNewClaim = (e) => {
    e.preventDefault();
    saveNewClaimForm(createClaimForm);
  };

  const [details, setDetails] = useState(location?.state?.details);
  const [updateClaimForm, data2] = useUpdateClaimMutation();
  const errors2 = data2?.error?.data ? data2?.error?.data.message : null;


  const updateClaimFormApi = () => {
    updateClaimForm({
      claimFormData: { details: details },
      uId: location?.state?._id,
    });
  };

  useEffect(() => {
    if (data?.isSuccess) {
      setLoading(true);
      setDetails((prevState) => ([...(prevState || []), data?.data?.data]));
      setTimeout(() => {
        navigate(`/update-claims-form/${location?.state?._id}`);
        setLoading(false);
      }, 1500);
    }
  }, [data?.isSuccess]);

  useEffect(() => {
    updateClaimFormApi();
  }, [details]);

  return (
    <form onSubmit={createNewClaim} className="add-new-claim flex">
      <div className="wrap wrapWidth flex flex-col">
        {errors !== null && (
          <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
            {errors}
          </div>
        )}
        {!loading || !data?.isLoading ? (
          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Reciept Date</div>
                <div className="date-picker flex aic jc">
                  <Datetime
                    closeOnSelect={true}
                    value={date ? date : new Date().getTime()}
                    onChange={(value) => {
                      setDate(new Date(value).getTime());
                      setCreateClaimForm({
                        ...createClaimForm,
                        receiptDate: new Date(value).getTime(),
                      });
                    }}
                    timeFormat={false}
                    dateFormat="DD-MM-YYYY"
                    className="start-date cleanbtn"
                  />
                  <div className="ico">
                    <CalendarIcon />
                  </div>
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Amount</div>
                <input
                  type="number"
                  name="amount"
                  onChange={handleInput}
                  className="txt"
                  placeholder="Amount"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Proof</div>
                <div className="txt flex aic justify-between">
                  <input
                    type="file"
                    name="proof"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      // setCreateClaimForm({
                      //   ...createClaimForm,
                      //   proof: e.target.files[0],
                      // });
                      setFile(window.URL.createObjectURL(file));
                    }}
                    className="cleanbtn w-full cursor-pointer"
                    placeholder="Proof"
                  />
                  <div className="ico">
                    <AttachmentIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="field flex flex-col pt-6">
              <div className="lbl">Description</div>
              <textarea
                type="text"
                name="description"
                onChange={handleInput}
                className="txt h-[140px]"
                placeholder="Description"
              />
            </div>
            <div className="action flex aic justify-end">
              <Link to="/new-claims-form" className="btn button mr-8">
                Cancel
              </Link>
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Create"}
                className="btn button"
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </form>
  );
};

export default AddNewClaimForUpdate;
