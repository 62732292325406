import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const payrollService = createApi({
  reducerPath: "payroll",
  tagTypes: "proll",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New Payroll
      createPayroll: builder.mutation({
        query: (createPayroll) => {
          return { url: "/api/payroll", method: "POST", body: createPayroll };
        },
        invalidatesTags: ["proll"],
      }),

      //   Add New Users in Payroll detail i.e Payment Summary, Salary Summary and CPF Summary
      addUserInPayroll: builder.mutation({
        query: (addNewUsersPayrollList) => {
          return {
            url: `/api/payroll/${addNewUsersPayrollList.uId}/detail/add`,
            method: "POST",
            body: { users_id: addNewUsersPayrollList.users_id },
          };
        },
        invalidatesTags: ["proll"],
      }),

      //   Delete a Payroll User
      removeUser: builder.mutation({
        query: ({ uId, detail_id }) => {
          return {
            url: `/api/payroll/${uId}/detail/${detail_id}`,
            method: "DELETE",
            body: { users_id: detail_id },
          };
        },
        invalidatesTags: ["proll"],
      }),

      //   Delete a Payroll
      deletePayroll: builder.mutation({
        query: (uId) => {
          return {
            url: `/api/payroll/${uId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["proll"],
      }),

      //   Update the Payroll
      paidPayrollCopy: builder.mutation({
        query: ({ bankInfo, uId }) => {
          return {
            url: `/api/payroll/${uId}/detail/paid`,
            method: "PUT",
            body: bankInfo,
          };
        },
        invalidatesTags: ["proll"],
      }),

      //   Update the Payroll
      updatePayrollProcess: builder.mutation({
        query: (payrollProcessData) => {
          return {
            url: `/api/payroll/${payrollProcessData?.uId}`,
            method: "PUT",
            body: payrollProcessData,
          };
        },
        invalidatesTags: ["proll"],
      }),

      //   Execute the Payroll
      executePayrollUsers: builder.mutation({
        query: ({ uId, detail_id }) => {
          return {
            url: `/api/payroll/${uId}/detail/execute`,
            method: "PUT",
            body: { detail_id: detail_id },
          };
        },
        invalidatesTags: ["proll"],
      }),

      //   Update the Payroll Detail by detail Id
      updatePayrollDetailById: builder.mutation({
        query: ({ uId, detail_id, payProcessData }) => {
          return {
            url: `/api/payroll/${uId}/detail/${detail_id}`,
            method: "PUT",
            body: payProcessData,
          };
        },
        invalidatesTags: ["proll"]
      }),

      //   Get the List of Payroll
      getPayrollProcessList: builder.query({
        query: ({ fromDate, toDate }) => {
          return {
            url: `/api/payroll?fromDate=${fromDate}&toDate=${toDate}`,
            method: "GET",
          };
        },
      }),

      //   Get the Single Payroll
      fetchSinglePayrollDetail: builder.query({
        query: (uId) => {
          return {
            url: `/api/payroll/${uId}`,
            method: "GET",
          };
        },
        providesTags: ["proll"],
      }),

      //   Get the Single Payroll Process detail by detail Id
      fetchSinglePayrollProc: builder.query({
        query: ({ uId, detail_id }) => {
          return {
            url: `/api/payroll/${uId}/detail/${detail_id}`,
            method: "GET",
          };
        },
        providesTags: ["proll"],
      }),

      //   Get the Payslip data
      fetchPayslip: builder.query({
        query: ({ uId, detail_id }) => {
          return {
            url: `/api/payroll/${uId}/payslip/${detail_id}`,
            method: "GET",
          };
        },
        providesTags: ["proll"],
      }),
      //   Get the Generate bulk data
      fetchGenerate: builder.mutation({
        query: ({ uId }) => {
          return {
            url: `/api/export/${uId}/bulk`,
            method: "GET",
          };
        },
        providesTags: ["proll"],
      }),
    };
  },
});

export const {
  useCreatePayrollMutation,
  useAddUserInPayrollMutation,
  useUpdatePayrollProcessMutation,
  useGetPayrollProcessListQuery,
  useRemoveUserMutation,
  useDeletePayrollMutation,
  useFetchSinglePayrollDetailQuery,
  useFetchSinglePayrollProcQuery,
  useFetchPayslipQuery,
  usePaidPayrollCopyMutation,
  useUpdatePayrollDetailByIdMutation,
  useExecutePayrollUsersMutation,
  useFetchGenerateMutation,
} = payrollService;

export default payrollService;
