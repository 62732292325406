import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const corporateService = createApi({
  reducerPath: "corporate",
  tagTypes: "compInfo",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      const orgid = reducers.authReducer?.userOrgId;
      headers.set("authorization", authorization ? authorization : "");
      headers.set("orgid", orgid ? orgid : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      // Get data from API
      get: builder.query({
        query: () => {
          return {
            url: "/api/corporate",
            method: "GET",
          };
        },
        providesTags: ["compInfo"],
      }),

      // Update Info
      updateInfo: builder.mutation({
        query: (data) => {
          return {
            url: `/api/corporate`,
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: ["compInfo"],
      }),
    };
  },
});

export const { useUpdateInfoMutation, useGetQuery } = corporateService;

export default corporateService;
