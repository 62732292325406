import React from "react";

function PayrollIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <g fill="#fff" opacity="0.6">
        <path d="M6.18 21.969v5.25c0 2.9 5.484 5.25 12.25 5.25 6.765 0 12.25-2.35 12.25-5.25v-5.25c0 2.9-5.485 5.25-12.25 5.25-6.766 0-12.25-2.35-12.25-5.25z"></path>
        <path d="M6.18 13.219v5.25c0 2.9 5.484 5.25 12.25 5.25 6.765 0 12.25-2.35 12.25-5.25v-5.25c0 2.9-5.485 5.25-12.25 5.25-6.766 0-12.25-2.35-12.25-5.25z"></path>
        <path d="M30.68 9.719c0 2.9-5.485 5.25-12.25 5.25-6.766 0-12.25-2.35-12.25-5.25s5.484-5.25 12.25-5.25c6.765 0 12.25 2.35 12.25 5.25z"></path>
      </g>
    </svg>
  );
}

export default PayrollIcon;
