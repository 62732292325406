import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modal";
import { EditIcon, DelIcon } from "../../assets";

import ComponentDelete from "../../components/AdminSide/ComponentDelete";
import Loader from "../../components/Loader";
import { useGetComponentListQuery } from "../../store/services/componentService";
import DataTable from "react-data-table-component";

const Components = () => {
  const { data, isLoading, isFetching } = useGetComponentListQuery();

  const [delComponent, setDelComponent] = useState(null);
  const [open, setOpen] = useState(false);

  const renderColumns = () => {
    return [
        {
            name: "Name",
            sortable: true,
            selector: row => row?.name,
        },
        {
            name: "Type",
            sortable: true,
            selector: row => row?.type,
        },
        {
            name: "Status",
            sortable: true,
            cell : row => (
              
              <div>
                {
                  (String(row.status).toLowerCase() === 'active') && <span className="status-tag success">{String(row.status).toUpperCase()}</span>
                }
                {
                  (String(row.status).toLowerCase() === 'inactive') && <span className="status-tag secondary">{String(row.status).toUpperCase()}</span>
                }
              
              </div>
              )
        },
        {
            name: 'Actions',
            button: true,
            cell: row => (
                  <>
                  <Link
                    to={`/update-component/${row.uId}`}
                    className="icon cursor-pointer"
                  >
                    <EditIcon />
                  </Link>
                  <div
                    className="icon cursor-pointer"
                    onClick={(e) => {
                      setOpen(true);
                      setDelComponent({ uId: row.uId, name: row.name });
                    }}
                  >
                    <DelIcon />
                  </div>
                </>
            )
        }
    ];
  };
  return (
    <div className="bank-account-page flex">
      <div className="wrap wrapWidth flex flex-col">
        {!isLoading && !isFetching ? (
          <div className="table-blk flex">
            <div className="tbl flex flex-col">
              <div className="row flex aic">
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item"></div>
                </div>
                <div className="col flex">
                  <div className="col-item">
                    <Link to="/new-component" className="btn button">
                      New
                    </Link>
                  </div>
                </div>
              </div>
                <DataTable
                    columns={renderColumns()}
                    data={data?.data}
                    responsive={true}
                    pagination={true} />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ComponentDelete setOpen={setOpen} data={delComponent} />
      </Modal>
    </div>
  );
};

export default Components;
