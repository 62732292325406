import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import Loader from "../Loader";
import { useGetQuery } from "../../store/services/generalService";
import {
  useFetchBankQuery,
  useUpdateBankAccountMutation,
} from "../../store/services/bankAccountService";

const UpdateBankAc = () => {
  const navigate = useNavigate();
  const { uId } = useParams();
  const { data, isFetching } = useFetchBankQuery(uId);

  const { data: options, isLoading } = useGetQuery();
  const [selectedBankName, setSelectedBankName] = useState("");
  const [selectedBank, setSelectedBank] = useState({
    display: "",
    value: "",
  });
  const [bankList, setBankList] = useState([]);

  const [createBank, setCreateBank] = useState({
    bankOf: "",
    accountName: "",
    accountNo: "",
  });
  const handleInput = (e) => {
    setCreateBank({ ...createBank, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!isLoading) {
      setBankList(options?.data.bankList);
    }
  }, [options?.data]);

  useEffect(() => {
    setCreateBank({ ...createBank, bankOf: selectedBank?.value });
  }, [selectedBank]);

  useEffect(() => {
    if (!isFetching) {
      setCreateBank(data?.data);
      setSelectedBank({
        display: "",
        value: data?.data.bankOf,
      });
    }
  }, [data?.data]);

  useEffect(() => {
    if (!isLoading) {
      setSelectedBankName(
        options?.data?.bankList.filter(
          (el, i) => el?.value === createBank?.bankOf
        )
      );
    }
  }, [createBank?.bankOf]);

  useEffect(() => {
    setSelectedBank({ ...selectedBank, display: selectedBankName[0]?.display });
  }, [selectedBankName[0]?.display]);

  const [updateBankAc, response] = useUpdateBankAccountMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;

  const createBankAccount = (e) => {
    e.preventDefault();
    updateBankAc({ createBank, uId });
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/bank-account");
    }
  }, [response?.isSuccess]);

  return (
    <form onSubmit={createBankAccount} className="new-department flex">
      <div className="wrap wrapWidth flex flex-col">
        {!isFetching ? (
          <>
            {errors !== null && (
              <div className="error-message flex bg-red-100 text-red-700 p-3  mb-2 rounded-md text-sm font-medium">
                {errors}
              </div>
            )}
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Bank</div>
                <Select
                  defaultValue={selectedBank}
                  value={selectedBank}
                  onChange={setSelectedBank}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={bankList}
                  className="select"
                  id="bankOf"
                  name="bankOf"
                  placeholder="Select Bank"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Account Name</div>
                <input
                  id="accountName"
                  name="accountName"
                  type="text"
                  className="txt"
                  placeholder="Account Name"
                  value={createBank.accountName}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Account No.</div>
                <input
                  id="accountNo"
                  name="accountNo"
                  type="text"
                  className="txt"
                  placeholder="00-00000000000000"
                  value={createBank.accountNo}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Create"}
                className="btn button"
              />
              {/* <div className="btn button" onClick={(e) => createBankAccount(e)}>
                {response?.isLoading ? "Loading..." : "Update"}
              </div> */}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </form>
  );
};

export default UpdateBankAc;
